import React, { useState, useEffect } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { CartPlugin } from '@api/local/CartPlugin'
import { ModalsPlugin, GlobalModalTypeEnum } from '@api/local/ModalsPlugin'
import { Heading, Paragraph, Pill, ResponsiveImage, Spacer, Button, IconEnum } from '@atoms/index'
import { useConfig } from '@client/contexts/ConfigProvider'
import { Ellipsis, ProductCardContainer, ProductCardImageContainer, ResponsivePXValue, theme } from '@components/Theme'
import { useEvents } from '@contexts/GTMProvider'
import {
  MealKitDishListFragment, UserMenuFragment, useAddDishToUserMenuMutation, useAddItemToCartMutation, useRemoveDishFromUserMenuMutation,
  useUserCartQuery, useUserDetailsQuery, useGetAppQuery,
} from '@hooks/api'
import { ItemInterfaceNew } from '@lib/GTM'
import { Utilities } from '@lib/Utilities'
import { getCategoryBackground, DiscoveryVitality, SelectOption, Form, SelectInput, convertEnumToNum, useForm, FieldData, HashAnchor } from '@molecules/index'
import { MealkitModal, MealkitModalEnum, ReactivateSubscriptionModal } from '@organisms/index'
import { NumberOfPortionsEnum, ProductAvailabilityEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  ${ProductCardContainer}
`
const ImageContainer = styled.div`
  ${ProductCardImageContainer}
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;  
  flex: 1;

  background-color: ${(props): string => props.theme.colors.whites.pureWhite};

  ${ResponsivePXValue('padding', { mobile: '12px 12px 16px', tablet: '12px 12px 16px', desktop: '16px 16px 14px' })}

  .title {
    ${Ellipsis()};
    margin: 0;
  }
  .sub-title {
    ${Ellipsis()};
  }

  .no-margin {    
    align-items: center;
    height: fit-content;
  }

  .left-icon {
    bottom: 0;  
  }

  .select-input {
    ${ResponsivePXValue('padding-left', '28px')}
  }

  .select-option-container {
    ${ResponsivePXValue('transform', 'translateY(-200px)')} 
  }

  `
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const Overlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: ${(props): string => props.theme.colors.misc.overlay};
`

const LinkContainer = styled.a`
  cursor: pointer;
  text-decoration: none;
`

enum DisplayTypeEnum {
  STANDARD = 'STANDARD',
  PARING = 'PARING',
}

export interface MealKitCompactCardProps {
  mealKit: MealKitDishListFragment
  className?: string
  loading?: boolean
}

interface MealKitCompactCardState {
  displayType: DisplayTypeEnum
  chosenPairing: number
  wineQuantities: number[]
  addToCartInterrupted: boolean
  activeModal: MealkitModalEnum
  displayReactivationModal: boolean
  loading: boolean
  portionSize: NumberOfPortionsEnum
}

const DEFAULT_STATE: MealKitCompactCardState = {
  displayType: DisplayTypeEnum.STANDARD,
  chosenPairing: 0,
  wineQuantities: [],
  addToCartInterrupted: false,
  activeModal: MealkitModalEnum.NONE,
  displayReactivationModal: false,
  loading: false,
  portionSize: NumberOfPortionsEnum.SERVES_2,
}

export const MealKitCompactCard = React.memo(({ mealKit, className }: MealKitCompactCardProps): JSX.Element => {

  const [state, setState] = useState<MealKitCompactCardState>(DEFAULT_STATE)
  const [form] = useForm()
  const config = useConfig()
  const navigate = useNavigate()
  const { addToast } = useToasts()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { data: userCartData } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const [addItemToCart] = useAddItemToCartMutation()
  const [addDishToBox] = useAddDishToUserMenuMutation()
  const [removeItemFromCart] = useRemoveDishFromUserMenuMutation()
  const events = useEvents()
  const hasSelectedPlan = userCartData?.currentUser?.checkoutStatus?.hasSelectedPlan
  const category = mealKit?.mealKitCategories[0]
  const hasLunchCategory = !category?.canSubscribe
  const categoryTitle = hasLunchCategory ? 'Lunch' : category.title
  const background = getCategoryBackground(category.background)
  const buttonText = hasSelectedPlan ? (mealKit.quantityInCart > 0 ? 'ADD ANOTHER' : 'ADD TO ORDER') : 'GET STARTED'
  const buttonColor = mealKit.quantityInCart > 0 ? 'green' : 'black'
  const isDisplayingPairing = state.displayType === DisplayTypeEnum.PARING
  const checkoutStatus = userCartData?.currentUser?.checkoutStatus
  const servingSizeNumber = convertEnumToNum(state.portionSize)

  const defaultPortions = appData.app.defaultPortions ? appData.app.defaultPortions: (NumberOfPortionsEnum.SERVES_2)

  const selectOptions: SelectOption[] = [
    {
      title: '1',
      value: NumberOfPortionsEnum.SERVES_1,
    },
    {
      title: '2',
      value: NumberOfPortionsEnum.SERVES_2,
    },
    {
      title: '3',
      value: NumberOfPortionsEnum.SERVES_3,
    },
    {
      title: '4',
      value: NumberOfPortionsEnum.SERVES_4,
    },

  ]

  useEffect(() => {
    const list: number[] = []
    for (let i = 0; i < mealKit?.activeParings?.length; i++) {
      list.push(1)
    }
    if (mealKit?.activeParings) {
      setState((prevState) => update(prevState, {
        wineQuantities: { $set: list },
      }))
    }
  }, [mealKit?.activeParings])

  const isAvailable = (): boolean => {
    let available = true
    for (let i = 0; i < mealKit.products.length; i++) {
      if (mealKit.products[i].availability === ProductAvailabilityEnum.UNAVAILABLE_GEOGRAPHICALLY) {
        available = false
        break
      }
    }
    return available
  }

  const _handleOnAdd = (): void => {

    if (!hasSelectedPlan) {
      if (config.isBrowser()) {
        window.sessionStorage.setItem('mealKitCategoryId', mealKit.mealKitCategories[0].id)
      }
      navigate('/meal-kit/plans')
    } else if (checkoutStatus?.hasPausedSubscription) {
      // show reactivation modal
      _switchReactivateSubscriptionModal(true)

    } else {
      const noDefaultAddress = !userDetailsData?.currentUser?.hasDefaultAddress

      setState((prevState) => update(prevState, {
        addToCartInterrupted: { $set: noDefaultAddress || !isAvailable() },
      }))

      if (noDefaultAddress) {
        ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.ADD_LOCATION)
      } else if (!isAvailable()) {
        ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.DELIVERY_UNAVAILABLE)
      } else if (isDisplayingPairing) {
        _handleAddToCart()
      } else {
        _handleShowAddMealKit()
      }
    }
  }

  useEffect(() => {
    if (state.addToCartInterrupted) {
      _handleOnAdd()
    }
  }, [userDetailsData?.currentUser?.addresses, isAvailable()])

  const _handleShowAddMealKit = (): void => {
    if (userCartData?.currentUser?.checkoutStatus?.hasSelectedPortionSize) {
      _handleAddDishToBox()
    } else {
      setState((prevState) => update(prevState, {
        activeModal: { $set: MealkitModalEnum.SERVING_SIZE },
      }))
    }
  }

  const _handleServingSizeSelected = () => {
    setState((prevState) => update(prevState, {
      activeModal: { $set: MealkitModalEnum.NONE },
    }))
    _handleAddDishToBox()
  }

  const _handleAddDishToBox = async (): Promise<void> => {
    _setLoading(true)

    const portionSize = state.portionSize

    try {
      await addDishToBox({
        variables: {
          input: {
            dishId: mealKit?.id,
            portionSize,
          },
        },
      })

      const logData = {
        itemName: mealKit?.name,
        itemId: mealKit?.products.find(product => product.portionSize as unknown as NumberOfPortionsEnum === portionSize)?.id,
        itemGroupId: mealKit?.id,
        price: mealKit?.products.find(product => product.portionSize as unknown as NumberOfPortionsEnum === portionSize)?.price,
        itemBrand: 'UCOOK',
        itemCategory: mealKit?.mealKitCategories?.map((cat) => cat.title)?.join(', '),
        itemVariant: mealKit?.mealKitCategories?.map((cat) => cat.title)?.join(', '),
        itemListName: 'Meal Kit',
        quantity: 1,
        itemImage: mealKit?.coverImage?.location,
        itemStockCount: 1,
        itemChef: mealKit?.chef?.name,
        isMealkit: 'yes',
        itemServingSize: servingSizeNumber,
      }
      const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew

      events.hasAddedToCart(snakedData)

      addToast('Item successfully added to your order. Hit \'save\' in your cart before you go, or this dish won’t be saved.', {
        appearance: 'warning',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    _setLoading(false)
  }

  const _handleAddToCart = async (): Promise<void> => {
    _setLoading(true)
    const wine = (mealKit?.activeParings || [])[state.chosenPairing]?.wine
    const quantity = 1 // state.wineQuantities[state.chosenPairing]
    try {
      await addItemToCart({
        variables: { productId: wine?.id, quantity },
      })

      const logData = {
        itemName: wine?.name,
        itemId: wine?.id,
        itemGroupId: wine?.id,
        price: wine?.price,
        itemBrand: wine?.vineyard?.name,
        itemCategory: 'Wines',
        itemVariant: wine?.wineCategory?.title,
        itemListName: 'Meal Kit Wine Pairing',
        quantity,
        itemImage: wine?.coverImage?.location,
        itemStockCount: wine?.stockCount > 1 ? 1 : 0,
        itemChef: 'UCOOK',
        isMealkit: 'no',
        itemServingSize: 1,
      }

      const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew

      events.hasAddedToCart(snakedData)

      addToast(`Successfully added (${quantity}) ${wine?.name} to cart.`, {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    _setLoading(false)
  }

  const _setLoading = (loading: boolean) => {
    setState((prevState) => update(prevState, {
      loading: { $set: loading },
    }))
  }

  const _switchReactivateSubscriptionModal = (display: boolean): void => {
    setState((prevState) => update(prevState, {
      displayReactivationModal: { $set: display },
    }))
  }
  const _onReactivateSubscription = () => {
    _handleAddDishToBox()
    CartPlugin.shared().open()
    _switchReactivateSubscriptionModal(false)
  }

  const pdpLink = `/meal-kit/${mealKit?.slug}`
  const _handleLinkClicked = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    navigate(pdpLink)
  }

  const _handleOnCenterClick = async (): Promise<void> => {
    if (mealKit.quantityInCart === 0) {
      _handleOnAdd()
    }
  }

  const getDishId = (id: string, cartDishesArray: UserMenuFragment): string => {
    for (let i = 0; i < cartDishesArray?.dishes?.length; i++) {
      if (cartDishesArray?.dishes[i].dish?.id === id) {
        return cartDishesArray?.dishes[i].id
      }
    }
  }
  const _handleOnRemove = async (): Promise<void> => {
    _setLoading(true)
    const product = mealKit

    const cartData = userCartData?.currentUser?.activeMenu
    // TODO: add to state
    const cartDishId = getDishId(mealKit.id, cartData)

    try {

      if (mealKit.quantityInCart > 0) {
        await removeItemFromCart({
          variables: {
            userMenuDishId: cartDishId,
          },
        })
      }
      const portionSize = state.portionSize

      const logData = {
        itemName: mealKit?.name,
        itemId: mealKit?.products.find(product => product.portionSize as unknown as NumberOfPortionsEnum === portionSize)?.id,
        price: mealKit?.products.find(product => product.portionSize as unknown as NumberOfPortionsEnum === portionSize)?.price,
        itemBrand: 'UCOOK',
        itemCategory: mealKit?.mealKitCategories?.map((cat) => cat.id)?.join(', '),
        itemVariant: mealKit?.mealKitCategories?.map((cat) => cat.title)?.join(', '),
        itemListName: 'MealKit',
        quantity: 1,
        itemImage: mealKit?.coverImage?.location,
        itemStockCount: 1,
        itemChef: mealKit?.chef?.name,
        isMealkit: 'yes',
        itemServingSize: servingSizeNumber,
      }

      const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew
      events.hasRemovedFromCart(snakedData)

      addToast('Item successfully removed from cart', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    _setLoading(false)

  }

  const _handlePortionSizeChange = (changedFields: FieldData[]) => {
    changedFields.forEach((field) => {
      (field.name as string[]).forEach((name) => {
        if (name === 'numberOfPortions') {
          setState((prevState) => update(prevState, {
            portionSize: { $set: field.value },
          }))
        }
      })
    })
  }
  const numberDefaultPortions = convertEnumToNum(defaultPortions)

  return (
    <Container className={className}>
      <HashAnchor selectorId={mealKit.slug} />
      <HashAnchor selectorId={Utilities.convertToKebabCase(categoryTitle)} />
      <MealkitModal activeModal={state.activeModal} onSubmit={_handleServingSizeSelected} onClose={() => { setState({ ...state, activeModal: MealkitModalEnum.NONE }) }} />
      <ReactivateSubscriptionModal
        open={state.displayReactivationModal}
        onReactivation={_onReactivateSubscription}
        onClose={() => { _switchReactivateSubscriptionModal(false) }} />
      <If condition={mealKit.discoveryHealthyDiningItem}>
        <DiscoveryVitality />
      </If>
      <LinkContainer href={pdpLink} onClick={_handleLinkClicked}>
        <ImageContainer>
          <ResponsiveImage image={mealKit.coverImage} />
        </ImageContainer>
      </LinkContainer>
      <ContentContainer>
        <LinkContainer href={pdpLink} onClick={_handleLinkClicked}>
          <TextContainer>
            <Pill title={categoryTitle} backgroundColor={background} />
            <Spacer universal='12px' />
            <Heading className='title' variant='h5'> {mealKit.name} </Heading>
            <Spacer mobile='4px' desktop='8px' />
            <Paragraph className='sub-title' variant='p1'> {mealKit.subTitle}</Paragraph>
            <Spacer mobile='4px' desktop='8px' />
            <Paragraph bold variant='p2' color={theme.colors.whites.silver}> EAT WITHIN {mealKit.cookWithin} DAYS</Paragraph>
            <Spacer mobile='8px' desktop='12px' />
          </TextContainer>
        </LinkContainer>
        <Form className='no-margin' form={form} onFieldsChange={_handlePortionSizeChange}>
          <SelectInput
            name='numberOfPortions'
            value={defaultPortions}
            icon={IconEnum.PERSON}
            readOnly={true}
            placeholder={String(numberDefaultPortions)}
            itemsToDisplay={4}
            options={selectOptions}
            rules={[{ required: true }]}
            showLabel={false}
            className='no-margin'
          />
        </Form>
        <Spacer mobile='8px' desktop='12px' />
        <ButtonContainer>
          <Button
            loading={state.loading}
            color={buttonColor}
            fullWidth title={buttonText}
            amount={mealKit.quantityInCart}
            onClick={_handleOnCenterClick}
            onLeftIconClick={_handleOnRemove}
            onRightIconClick={_handleOnAdd}
          />
        </ButtonContainer>
      </ContentContainer>
      <If condition={isDisplayingPairing}>
        <Overlay />
      </If>
    </Container>
  )
})

MealKitCompactCard.displayName = 'MealKitCompactCard'
