import React, { useEffect, useState } from 'react'

import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client'

import update from 'react-addons-update'
import styled from 'styled-components'

import { CheckoutPlugin } from '@api/local'
import { ContentBlock } from '@client/components/molecules/stores/ContentBlock'
import { ResponsivePXValue } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useEvents } from '@contexts/GTMProvider'
import { UserCartQuery, UserDetailsQuery, useUserCartQuery, useUserDetailsQuery } from '@hooks/api'
import { CheckoutContainer, CheckoutDeliveryDaySelect, CheckoutOrderSummary, CheckoutPromoCodes } from '@organisms/checkout'
import { UserAddressList } from '@organisms/user'
import { CheckoutTypeEnum, CityDeliveryDaysEnum } from '@uctypes/api/globalTypes'

const ContentContainer = styled.div`
  width: 100%;

  .seperator {
    ${ResponsivePXValue('margin', { mobile: '20px 0', tablet: '25px 0', desktop: '30px 0' })}
  }
`

export interface CheckoutDeliveryProps {
  checkoutType: CheckoutTypeEnum
}

interface CheckoutDeliveryState {
  error: string | null
  deliveryDay: CityDeliveryDaysEnum
}

const DEFAULT_STATE: CheckoutDeliveryState = {
  error: null,
  deliveryDay: null,
}

export function CheckoutDelivery({ checkoutType }: CheckoutDeliveryProps): JSX.Element {

  const [state, setState] = useState<CheckoutDeliveryState>({ ...DEFAULT_STATE })
  const config = useConfig()
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>
  const { data: userCartData, loading: userCartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const discounts = userCartData?.currentUser?.activeCart?.reductions
  const hasUserEnteredAddressFromModal = config.isBrowser() && window.sessionStorage.getItem('hasUserEnteredAddressFromModal') === 'true'
  const event = useEvents()
  let discountTitles = ''
  let discountValue = 0
  for (let i = 0; i < discounts.length; i++) {
    discountTitles = discountTitles +discounts[i].title
    discountValue = discountValue + discounts[i].value
  }
  const _handleContinue = async (): Promise<void> => {

    if (checkoutType === CheckoutTypeEnum.SUBSCRIPTION) {
      await CheckoutPlugin.shared().checkout(client)
      event.hasAddedShippingMethod('Delivery', 'Plan Delivery', userCartData?.currentUser?.activeCart?.cartItems, userCartData?.currentUser?.activeCart?.grandTotal, discountTitles, discountValue, userDetailsData?.currentUser?.id)
    } else {
      const defaultAddressId = userDetailsData?.currentUser?.addresses?.find((address) => address.isDefault).id
      event.hasAddedShippingMethod('Delivery', 'Plan Delivery', userCartData?.currentUser?.activeCart?.cartItems, userCartData?.currentUser?.activeCart?.grandTotal, discountTitles, discountValue, userDetailsData?.currentUser?.id)
      await CheckoutPlugin.shared().setOnDemandUserAddress(client, defaultAddressId)
    }

  }

  const _handleValidateDeliveryDay = (userData: UserDetailsQuery, cartData: UserCartQuery): boolean => {
    const defaultAddress = userData?.currentUser?.addresses?.find((address) => address.isDefault)
    const deliveryChoice = cartData?.currentUser?.activeMenu?.subscription?.deliveryOption
    for (const deliveryDay of defaultAddress?.location?.city?.deliveryDays) {
      if ((deliveryDay as string) === (deliveryChoice as string)) {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    let error: string | null = null
    if (!userCartLoading && !userDetailsLoading) {
      if (!userCartData?.currentUser?.checkoutStatus?.hasSetDeliveryAddress) {
        error = 'Select an address to continue'
      } else if (!userCartData?.currentUser?.checkoutStatus?.hasSetDeliveryAddressDetails) {
        error = 'Select a valid address to continue'
      } else if (checkoutType === CheckoutTypeEnum.SUBSCRIPTION && (!userCartData?.currentUser?.checkoutStatus?.hasSelectedDeliveryDay || !_handleValidateDeliveryDay(userDetailsData, userCartData))) {
        error = 'Select a delivery day to continue'
      }
      setState((prevState) => update(prevState, {
        error: { $set: error },
      }))
    }
  }, [userCartData, userDetailsData])

  const loading = userCartLoading || userDetailsLoading
  const $sidebar = (
    <>
      <CheckoutOrderSummary
        loading={loading}
        error={state.error}
        type={checkoutType}
        onCheckout={_handleContinue} />
      <CheckoutPromoCodes
        loading={loading}
        disabled={userCartLoading || userDetailsLoading} />
    </>
  )

  const showDeliverySelect = userDetailsData?.currentUser?.hasDefaultAddress && checkoutType === CheckoutTypeEnum.SUBSCRIPTION
  const displayConfirmation = hasUserEnteredAddressFromModal && checkoutType === CheckoutTypeEnum.ON_DEMAND

  return (
    <>
      <CheckoutContainer sidebar={$sidebar}>
        <ContentBlock
          title='Delivery Address'>
          <ContentContainer>
            <UserAddressList displayConfirmation={displayConfirmation}/>
          </ContentContainer>
        </ContentBlock>
        <If condition={showDeliverySelect}>
          <CheckoutDeliveryDaySelect />
        </If>
      </CheckoutContainer>
    </>
  )

}
