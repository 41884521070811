import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { UserPlugin } from '@api/local'
import { Heading, IconEnum, Paragraph, Spacer, Button } from '@client/components/atoms'
import { CartNotificationBanner } from '@client/components/molecules'
import { ResponsivePXValue, theme, ZeroSpace } from '@client/components/Theme'
import { PageNotificationFragment, useGetOnePageNotificationGroupQuery } from '@hooks/api'

import { CartTabs } from '../drawers'

const HeaderContainer = styled.div`
  background-color: ${theme.colors.whites.alabaster};
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const Row = styled.div`
  ${ResponsivePXValue('padding', '16px')}
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Column = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .cart-header {
    ${ZeroSpace}
    line-height: 1;
  }

  .cart-subtitle {
    ${ZeroSpace}
    font-weight: 700;
    ${ResponsivePXValue('font-size', '10px')}
    line-height: 1;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  .action-button {
    ${ZeroSpace}
    border-radius: 0;
    text-transform: none;
  }
`

export interface CartHeaderProps {
  tab?: CartTabs
  deliveryDate: string
  billingDate: string
  showTabBar: boolean
  identifier?: string
  onTabChange: (tab: CartTabs) => void
  onClose: () => void
}

interface CartHeaderState {
  loading: boolean
  currentNotifications: PageNotificationFragment[]
}

const DEFAULT_STATE: CartHeaderState = {
  loading: false,
  currentNotifications: [] as PageNotificationFragment[],
}

export function CartHeader({ tab = CartTabs.MEAL_KIT, deliveryDate, billingDate, showTabBar, identifier = 'FLYOUT_CART', onTabChange, onClose }: CartHeaderProps): JSX.Element {

  const [state, setState] = useState<CartHeaderState>({ ...DEFAULT_STATE })
  const { data: notificationData, loading: notificationLoading } = useGetOnePageNotificationGroupQuery({ variables: { identifier } })

  useEffect(() => {
    if (!notificationLoading) {
      _setNotifications()
    }
  }, [notificationLoading])

  const _setNotifications = (): void => {
    if (notificationData?.pageNotificationGroup?.currentNotifications?.length > 0) {
      const currentNotifications = notificationData?.pageNotificationGroup?.currentNotifications?.filter((value) => _notificationValid(value.id))
      setState((prevState) => update(prevState, {
        currentNotifications: { $set: currentNotifications },
      }))
    }
  }

  const _handleOnTabChange = (chosenTab: CartTabs): void => {
    onTabChange?.(chosenTab)
  }

  const _handleOnClose = (): void => {
    onClose?.()
  }

  const _handleOnDismissNotification = (id: string): void => {
    _setLoading(true)
    // TODO:
    UserPlugin.shared().addDismissedNotification(id)

    _setNotifications()
    _setLoading(false)
  }

  const _notificationValid = (id: string): boolean => {
    // TODO:
    return !UserPlugin.shared().notificationIsDismissed(id)
  }

  const _renderDate = (date: Date): string => {
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
  }

  const _setLoading = (loading: boolean): void => {
    setState((prevState) => update(prevState, {
      loading: { $set: loading },
    }))
  }

  const loading = notificationLoading || state.loading

  return (
    <HeaderContainer>
      <Row>
        <Choose>
          <When condition={showTabBar || tab === CartTabs.MEAL_KIT}>
            <Column>
              <Heading variant='h6' className='cart-header'>Meal Kit Plan</Heading>
              <Spacer universal='16px' />
              <Paragraph variant='p2' className='cart-subtitle'>{`Billing date: ${_renderDate(new Date(billingDate))}`}</Paragraph>
              <Spacer universal='12px' />
              <Paragraph variant='p2' className='cart-subtitle'>{`Delivery date: ${_renderDate(new Date(deliveryDate))}`}</Paragraph>
            </Column>
          </When>
          <Otherwise>
            <Column>
              <Heading variant='h6' className='cart-header'>On Demand order</Heading>
              <Spacer universal='16px' />
              <Paragraph variant='p2' className='cart-subtitle'>Payment on checkout</Paragraph>
            </Column>
          </Otherwise>
        </Choose>
        <Button
          color='orange'
          icon={IconEnum.CLOSE_OUTLINE}
          iconColor={theme.colors.greys.liteCodGrey}
          size='small'
          onClick={_handleOnClose} />
      </Row>
      <If condition={showTabBar}>
        <ButtonsContainer>
          <Button
            className='action-button'
            fullWidth
            title='Meal Kit'
            color={tab === CartTabs.MEAL_KIT ? 'orange' : 'grey'}
            onClick={() => _handleOnTabChange(CartTabs.MEAL_KIT)} />
          <Button
            className='action-button'
            fullWidth
            title='Add ons'
            color={tab === CartTabs.ADD_ONS ? 'orange' : 'grey'}
            onClick={() => _handleOnTabChange(CartTabs.ADD_ONS)} />
        </ButtonsContainer>
      </If>
      <If condition={!loading && state.currentNotifications.length > 0}>
        <CartNotificationBanner
          notification={state.currentNotifications[0]}
          dismissLoading={loading}
          onClose={() => _handleOnDismissNotification(state.currentNotifications[0].id)}
          onLinkClicked={_handleOnClose} />
      </If>
    </HeaderContainer>
  )

}
