import { NavItemFragment, SubNavItemFragment } from '@hooks/api'

export const defaultNavigation: NavItemFragment[] = [{
  id: 'MEAL_KITS',
  title: 'Meal Kits',
  pillTitle: '',
  active: false,
  open: false,
  isExternalNavigation: false,
  items: [{
    id: 'MEAL_KIT_MENU',
    title: 'Meal Kit Menu',
    href: '/meal-kit',
    active: false,
    exactPath: false,
    path: '/meal-kit(?!/suppliers*)(/*)?',
    __typename: 'SubNavItem',
  }, {
    id: 'PLANS_AND_PRICING',
    title: 'Plans & Pricing',
    href: '/meal-kit/plans',
    active: false,
    exactPath: true,
    path: '/meal-kit/plans',
    __typename: 'SubNavItem',
  }, {
    id: 'OUR_SUPPLIERS',
    title: 'Our Suppliers',
    href: '/meal-kit/suppliers',
    active: false,
    exactPath: false,
    path: '/meal-kit/suppliers(/*)?',
    __typename: 'SubNavItem',
  }],
  __typename: 'MainNavContainer',
}, {
  id: 'FROZEN',
  title: 'Frozen',
  pillTitle: '',
  active: false,
  open: false,
  isExternalNavigation: false,
  items: [{
    id: 'SHOP_FROZEN',
    title: 'Shop Frozen',
    href: '/frozen',
    active: false,
    exactPath: false,
    path: '/frozen(/*)?',
    __typename: 'SubNavItem',
  }, {
    id: 'CRAFT_MEALS',
    title: 'Craft Meals',
    href: '/frozen/craft-meal',
    active: false,
    exactPath: false,
    path: '/frozen/craft-meal(/*)?',
    __typename: 'SubNavItem',
  }, {
    id: 'CRAFT_PIZZA',
    title: 'Craft Pizza',
    href: '/frozen/craft-pizza',
    active: false,
    exactPath: false,
    path: '/frozen/craft-pizza(/*)?',
    __typename: 'SubNavItem',
  }, {
    id: 'CRAFT_DESSERT',
    title: 'Craft Dessert',
    href: '/frozen/craft-dessert',
    active: false,
    exactPath: false,
    path: '/frozen/craft-dessert(/*)?',
    __typename: 'SubNavItem',
  }],
  __typename: 'MainNavContainer',
}, {
  id: 'SHOP_WINE',
  title: 'Wine',
  pillTitle: '',
  active: false,
  open: false,
  isExternalNavigation: false,
  items: [{
    id: 'WINE_SELECTION',
    title: 'Shop Wine',
    href: '/wine',
    active: false,
    exactPath: false,
    path: '/wine(/*)?',
    __typename: 'SubNavItem',
  }, {
    id: 'FEATURED_WINE_FARMS',
    title: 'Featured wine farms',
    href: '/cms/featured-farms',
    active: false,
    exactPath: true,
    path: '/cms/featured-farms',
    __typename: 'SubNavItem',
  }],
  __typename: 'MainNavContainer',
}, {
  id: 'SHOP_MARKET',
  title: 'Market',
  pillTitle: '',
  href: '/market',
  active: false,
  exactPath: true,
  path: '/market',
  isExternalNavigation: false,
  __typename: 'MainNavItem',
}, {
  id: 'WEEKEND_BOXES',
  title: 'Weekend Boxes',
  pillTitle: '',
  href: '/market/store/weekend-boxes',
  active: false,
  exactPath: true,
  isExternalNavigation: false,
  path: '/market/store/weekend-boxes',
  __typename: 'MainNavItem',
}, {
  id: 'ABOUT',
  title: 'About UCook',
  pillTitle: '',
  active: false,
  open: false,
  isExternalNavigation: false,
  items: [{
    id: 'ABOUT_MEALKIT',
    title: 'Meal Kits',
    href: '/cms/about-meal-kits',
    active: false,
    exactPath: true,
    path: '/cms/about-meal-kits',
    __typename: 'SubNavItem',
  }, {
    id: 'ABOUT_CRAFT_MEALS',
    title: 'Craft Meals',
    href: '/cms/about-craft-meals',
    active: false,
    exactPath: true,
    path: '/cms/about-craft-meals',
    __typename: 'SubNavItem',
  }, {
    id: 'ABOUT_WINE',
    title: 'Wine',
    href: '/cms/about-wine',
    active: false,
    exactPath: true,
    path: '/cms/about-wine',
    __typename: 'SubNavItem',
  }, {
    id: 'ABOUT_UCOOK',
    title: 'About UCook',
    href: '/cms/about-ucook',
    active: false,
    exactPath: true,
    path: '/cms/about-ucook',
    __typename: 'SubNavItem',
  }],
  __typename: 'MainNavContainer',
},
{
  id: 'PARTNER_WITH_US',
  title: 'Partner With Us',
  pillTitle: '',
  href: 'https://ucook.co.za/cms/partnerships',
  active: false,
  exactPath: true,
  isExternalNavigation: true,
  path: 'https://ucook.co.za/cms/partnerships',
  __typename: 'MainNavItem',
}, {
  id: 'GIFTS',
  title: 'Gifts',
  pillTitle: '',
  href: '/cms/gifts',
  active: false,
  exactPath: true,
  isExternalNavigation: false,
  path: '/cms/gifts',
  __typename: 'MainNavItem',
}, {
  id: 'DEALS',
  title: 'Deals',
  pillTitle: '',
  href: '/cms/deals',
  active: false,
  exactPath: true,
  isExternalNavigation: false,
  path: '/cms/deals',
  __typename: 'MainNavItem',
},
//  {
//   id: 'FESTIVE_BOXES',
//   title: 'Festive Boxes',
//   pillTitle: '',
//   href: '/market/store/festive-boxes',
//   active: false,
//   exactPath: true,
//   isExternalNavigation: false,
//   path: '/market/store/festive-boxes',
//   __typename: 'MainNavItem',
// },
//  {
//   id: 'FATHERS_DAY_BOX',
//   title: 'Father\'s Day Box',
//   pillTitle: '',
//   href: '/market/father-s-day-bundle',
//   active: false,
//   exactPath: true,
//   isExternalNavigation: false,
//   path: '/market/father-s-day-bundle',
//   __typename: 'MainNavItem',
// },
// , {
//   id: 'MOTHERS_DAY_BOX',
//   title: 'Mothers Day Box',
//   pillTitle: '',
//   href: '/market/mother-s-day-bundle',
//   active: false,
//   exactPath: true,
//   isExternalNavigation: false,
//   path: '/market/mother-s-day-bundle',
//   __typename: 'MainNavItem',
// },
// ,
{
  id: 'EASTER_BOXES',
  title: 'Easter Boxes',
  pillTitle: 'Limited Time Only',
  href: '/cms/easter-boxes',
  active: false,
  exactPath: true,
  isExternalNavigation: false,
  path: '/cms/easter-boxes',
  __typename: 'MainNavItem',
},
// {
//   id: 'BRAAI_TITLE',
//   title: 'New Year\'s Box',
//   pillTitle: 'Limited Time Only',
//   active: false,
//   isExternalNavigation: false,
//   open: false,
//   items: [{
//     id: 'BRAAI_ONE',
//     title: 'New Year’s Roast Chicken',
//     href: '/market/new-years-classic-roast-chicken',
//     active: false,
//     exactPath: false,
//     path: '/market/new-years-classic-roast-chicken',
//     __typename: 'SubNavItem',
//   }, {
//     id: 'BRAAI_TWO',
//     title: 'New Year’s Hot-Smoked Trout',
//     href: '/market/new-years-hot-smoked-trout',
//     active: false,
//     exactPath: false,
//     path: '/market/new-years-hot-smoked-trout',
//     __typename: 'SubNavItem',
//   }, {
//     id: 'BRAAI_THREE',
//     title: 'New Year’s Crispy Pork Belly',
//     href: '/market/new-years-crispy-pork-belly',
//     active: false,
//     exactPath: false,
//     path: '/market/new-years-crispy-pork-belly',
//     __typename: 'SubNavItem',
//   }, {
//     id: 'BRAAI_FOUR',
//     title: 'New Year’s Chermoula-roasted',
//     href: '/market/new-years-chermoula-roasted-cauliflower',
//     active: false,
//     exactPath: false,
//     path: '/market/new-years-chermoula-roasted-cauliflower',
//     __typename: 'SubNavItem',
//   }, {
//     id: 'BRAAI_FIVE',
//     title: 'New Year’s Beef Sirloin Roast',
//     href: '/market/new-years-beef-sirloin-roast',
//     active: false,
//     exactPath: false,
//     path: '/market/new-years-beef-sirloin-roast',
//     __typename: 'SubNavItem',
//   }],
//   __typename: 'MainNavContainer',
// },
]

export const applicationMainNavigation: NavItemFragment[] = [{
  id: 'HELP',
  title: 'Help',
  pillTitle: '',
  active: false,
  open: false,
  isExternalNavigation: false,
  items: [
    {
      id: 'GOOD',
      title: 'UCOOK FOR GOOD ',
      href: '/good',
      active: false,
      exactPath: true,
      path: '/good',
      __typename: 'SubNavItem',
    },
    {
      id: 'PRIVACY',
      title: 'PRIVACY POLICY',
      href: '/privacy-policy',
      active: false,
      exactPath: true,
      path: '/privacy-policy',
      __typename: 'SubNavItem',
    }, {
      id: 'REFERRAL',
      title: 'REFERRAL TERMS',
      href: '/referral-policy',
      active: false,
      exactPath: true,
      path: '/privacy-policy',
      __typename: 'SubNavItem',
    }, {
      id: 'TERMS',
      title: 'TERMS AND CONDITIONS',
      href: '/terms',
      active: false,
      exactPath: true,
      path: '/terms',
      __typename: 'SubNavItem',
    }, {
      id: 'CAREERS',
      title: 'CAREERS',
      href: 'https://suppersocietypty.bamboohr.com/jobs/',
      active: false,
      exactPath: false,
      path: 'https://suppersocietypty.bamboohr.com/jobs/',
      __typename: 'SubNavItem',
    }, {
      id: 'FAQS',
      title: 'FAQ’S',
      href: 'https://support.ucook.co.za/hc/en-us',
      active: false,
      exactPath: false,
      path: 'https://support.ucook.co.za/hc/en-us',
      __typename: 'SubNavItem',
    }, {
      id: 'RECYCLING',
      title: 'RECYCLING',
      href: '/recycling',
      active: false,
      exactPath: true,
      path: '/recycling',
      __typename: 'SubNavItem',
    }, {
      id: 'CONTACT',
      title: 'CONTACT',
      href: 'https://support.ucook.co.za/hc/en-us/requests/new',
      active: false,
      exactPath: false,
      path: 'https://support.ucook.co.za/hc/en-us/requests/new',
      __typename: 'SubNavItem',
    }],
  __typename: 'MainNavContainer',
}]

export const defaultUserSubNav: SubNavItemFragment[] = [{
  id: 'PLAN',
  title: 'Meal Kit Plan',
  href: '/me/subscriptions',
  active: false,
  exactPath: true,
  path: '/me/subscriptions',
  __typename: 'SubNavItem',
}, {
  id: 'ORDERS',
  title: 'My Orders',
  href: '/me/orders',
  active: false,
  exactPath: true,
  path: '/me/orders',
  __typename: 'SubNavItem',
}, {
  id: 'ACCOUNT',
  title: 'My Profile',
  href: '/me/account-settings',
  active: false,
  exactPath: true,
  path: '/me/account-settings',
  __typename: 'SubNavItem',
}, {
  id: 'LOG_OUT',
  title: 'Log Out',
  href: null,
  active: false,
  exactPath: true,
  path: null,
  __typename: 'SubNavItem',
}]
