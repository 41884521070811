import React from 'react'

export function useLoadingData<T>(
  args: {
    data: T[] | undefined // Explicitly expect an array or undefined
    loading?: boolean
    defaultData?: T[] | null // Default data should be an array
  },
): T[] { // Always return an array
  const { data, loading = false, defaultData = [] } = args // Default to empty array
  const [state, setState] = React.useState<T[]>(defaultData || []) // Initialize with array

  React.useEffect(() => {
    if (loading) return

    // If data is undefined or null, use defaultData or empty array
    const newData = data ?? defaultData ?? []

    if (Array.isArray(newData) && Array.isArray(state)) {
      const isEqual = newData.length === state.length && newData.every((item, i) => item === state[i])
      if (!isEqual) {
        setState(newData)
      }
    } else {
      setState(newData) // This will always be an array
    }
  }, [data, loading, defaultData])

  return state // Guaranteed to be an array
}
