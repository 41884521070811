import React, { useState, useEffect } from 'react'

import valid from 'card-validator'
import { FieldData } from 'rc-field-form/lib/interface'
import update from 'react-addons-update'
import { useParams } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { Heading, Icon, IconEnum, SmallLoader, Button, Paragraph, Link } from '@atoms/index'
import { useConfig } from '@client/contexts/ConfigProvider'
import { ResponsivePXValue } from '@components/Theme'
import { SubscribeToPaymentChangeDocument, useCheckPaymentStatusLazyQuery, useGetPaymentByTokenQuery, useTakePaymentMutation } from '@hooks/api'
import { Form, TextInput, SelectInput, useForm } from '@molecules/inputs'
import { TransactionStatusEnum } from '@uctypes/api/globalTypes'

interface CreditCardDetails {
  nameOnCard: string
  cardNumber: string
  expiryMonth: string
  expiryYear: string
  cvv: string
}

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('width', { mobile: '280px', tablet: '600px', desktop: '500px' })}
  ${ResponsivePXValue('margin', { mobile: '0 0 100px', tablet: '0 0 100px' })}

  .button {
    ${ResponsivePXValue('margin', '24px 0')}
  }
`

const SideBySide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  ${ResponsivePXValue('gap', { desktop: '16px', tablet: '12px' })}

  .input {
    flex-grow: 1;
    ${ResponsivePXValue('width', { mobile: '100%' })}
  }
`

const LoadingContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('padding', '50px')}
  ${ResponsivePXValue('width', { mobile: '100vw', tablet: '100vw', desktop: '600px' })}

  .modal-loading-title {
    ${ResponsivePXValue('margin', '10px')}
  }
`

const LoaderBox = styled.div`
  ${ResponsivePXValue('width', '32px')}
  ${ResponsivePXValue('height', '32px')}
`

const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('height', '32px')}
`

const IframeContainer = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  ${ResponsivePXValue('height', { mobile: '400px', tablet: '400px', desktop: '400px' })}
`

enum DisplayStepEnum {
  FORM = 'FORM',
  THREE_D_SECURE = 'THREE_D_SECURE',
  CHECK = 'CHECK',
  ERROR = 'ERROR',
  DONE = 'DONE',
  FAILED = 'FAILED',
}
interface MakePaymentState {
  displayStep: DisplayStepEnum
  cardType: string
  cvvLabel: string
  monthOptions: { title: string, value: string }[]
  yearOptions: { title: string, value: string }[]
  error: string
}

const DEFAULT_STATE: MakePaymentState = {
  displayStep: DisplayStepEnum.FORM,
  cardType: '',
  cvvLabel: 'CVV',
  monthOptions: [] as { title: string, value: string }[],
  yearOptions: [] as { title: string, value: string }[],
  error: '',
}

export function MakePayment(): JSX.Element {

  const config = useConfig()
  const { token } = useParams<{ token: string }>()
  const [state, setState] = useState<MakePaymentState>({ ...DEFAULT_STATE })

  const { data: paymentData, loading: paymentLoading, error: paymentError } = useGetPaymentByTokenQuery({
    variables: { token },
    context: {
      headers: {
        'Cache-Control': 'no-cache',
      },
    },
  })
  const [takePayment, { loading: takePaymentLoading }] = useTakePaymentMutation()
  const [check, { data: statusData, subscribeToMore }] = useCheckPaymentStatusLazyQuery({
    context: {
      headers: {
        'Cache-Control': 'no-cache',
      },
    },
  })
  const theme = useTheme()
  const [form] = useForm()

  const _handleSubmit = async (data: CreditCardDetails): Promise<void> => {

    const details: CreditCardDetails = {
      nameOnCard: data.nameOnCard as string,
      cardNumber: data.cardNumber.replace(/\s/g, '') as string,
      expiryMonth: data.expiryMonth as string,
      expiryYear: data.expiryYear as string,
      cvv: data.cvv as string,
    }

    try {
      const response = await takePayment({
        variables: {
          id: paymentData?.payment?.id,
          input: {
            ...details,
          },
        },
      })
      if (response.data?.payment?.requires3DSecure) {
        setState((prevState) => update(prevState, {
          displayStep: { $set: DisplayStepEnum.THREE_D_SECURE },
        }))
      } else {
        setState((prevState) => update(prevState, {
          displayStep: { $set: DisplayStepEnum.CHECK },
        }))
      }
    } catch (e) {
      setState((prevState) => update(prevState, {
        displayStep: { $set: DisplayStepEnum.ERROR },
        error: { $set: e.message },
      }))
    }
  }

  const _handleChange = (changedFields: FieldData[]) => {
    changedFields.forEach((field) => {
      (field.name as string[]).forEach((name) => {
        if (name === 'cardNumber') {
          let cardType = state.cardType
          const cardNum = field.value.replace(/\s/g, '')
          cardType = valid?.number(cardNum)?.card?.type
          const cvvLabel = cardType === 'american-express' ? 'CID' : 'CVV'
          setState((prevState) => ({
            ...prevState,
            cardType,
            cvvLabel,
          }))
        }
      })
    })
  }

  const _handleSuccess = async (): Promise<void> => {
    setState((prevState) => update(prevState, {
      displayStep: { $set: DisplayStepEnum.DONE },
    }))
  }

  const _handleFailed = async (): Promise<void> => {
    setState((prevState) => update(prevState, {
      displayStep: { $set: DisplayStepEnum.FAILED },
    }))
  }

  const _handlePending = (): () => void => {
    return subscribeToMore({
      document: SubscribeToPaymentChangeDocument,
      variables: { id: paymentData.payment.id },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        const newStatus = subscriptionData.data.status
        return Object.assign({}, prev, {
          status: newStatus,
        })
      },
    })
  }

  useEffect(() => {
    const monthOptions: { title: string, value: string }[] = []
    const yearOptions: { title: string, value: string }[] = []
    for (let m = 1; m < 13; m++) {
      monthOptions.push({ title: `${m}`.padStart(2, '0'), value: `${m}`.padStart(2, '0') })
    }
    const d = new Date()
    const year = d.getFullYear()
    for (let y = year; y < year + 10; y++) {
      yearOptions.push({ title: `${y}`, value: `${y}` })
    }
    setState((prevState) => ({ ...prevState, monthOptions, yearOptions }))
  }, [])

  useEffect(() => {
    if (state.displayStep === DisplayStepEnum.CHECK && paymentData?.payment) {
      check({ variables: { id: paymentData.payment.id } })
    }
  }, [state.displayStep, paymentData])

  useEffect(() => {
    const subscription: () => void | null = null
    if (statusData?.status?.status) {
      if (statusData?.status?.status === TransactionStatusEnum.FAILED) {
        _handleFailed()
      } else if (statusData?.status?.status === TransactionStatusEnum.SUCCESS) {
        _handleSuccess()
      } else {
        plan = _handlePending()
      }
    }
    return () => subscription?.()
  }, [statusData?.status?.status])

  useEffect(() => {
    if (paymentData?.payment?.status) {
      if (paymentData?.payment?.status === TransactionStatusEnum.SUCCESS) {
        _handleSuccess()
      }
    }
  }, [paymentData?.payment?.status])

  useEffect(() => {
    const _handler = async (event: MessageEvent): Promise<void> => {
      if (config.isBrowser() && event.origin.startsWith(window.location.origin) && event.data.id) {
        setState((prevState) => update(prevState, {
          displayStep: { $set: DisplayStepEnum.CHECK },
        }))
      }
    }
    if (config.isBrowser()) {
      window.addEventListener('message', _handler)
      return () => window.removeEventListener('message', _handler)
    }
  }, [])

  useEffect(() => {
    if (paymentError) {
      setState((prevState) => update(prevState, {
        displayStep: { $set: DisplayStepEnum.ERROR },
        error: { $set: paymentError.message },
      }))
    }
  }, [paymentError])

  const loading = paymentLoading || takePaymentLoading

  return (
    <Container>
      <Choose>
        <When condition={loading}>
          <LoadingContainer>
            <LoaderBox>
              <SmallLoader color={theme.colors.oranges.coral} />
            </LoaderBox>
            <Heading variant='h4' className='modal-loading-title'>Fetching payment information</Heading>
          </LoadingContainer>
        </When>
        <When condition={state.displayStep === DisplayStepEnum.ERROR}>
          <LoadingContainer>
            <IconBox>
              <LoaderBox>
                <Icon color={theme.colors.misc.error} icon={IconEnum.CLOSE_OUTLINE} />
              </LoaderBox>
              <Heading variant='h4' className='modal-loading-title'>Failure</Heading>
            </IconBox>
            <Paragraph className='modal-loading-title' align='center'>{state.error}</Paragraph>
          </LoadingContainer>
        </When>
        <When condition={state.displayStep === DisplayStepEnum.FORM && !!paymentData}>
          <FormContainer>
            <Heading>
              {`Hi ${paymentData.payment.userDetails.name}`}
            </Heading>
            <Paragraph>
              Please complete the fields below to make a payment for:
            </Paragraph>
            <Heading variant='h3'>
              {`R${paymentData.payment.dueAmount.toFixed(2)}`}
            </Heading>
            <Form form={form} onFinish={_handleSubmit} onFieldsChange={_handleChange} style={{ width: '100%' }}>
              <Paragraph
                className='title'
                variant='p2'
                align='center'
                color={theme.colors.greys.darkCodGrey}>
                {'Enter your card details'}
              </Paragraph>
              <TextInput
                name='nameOnCard'
                label='Name on card'
                placeholder='Enter name'
                rules={[{ required: true, message: 'Please input a name' }]} />
              <TextInput
                name='cardNumber'
                label='Card number'
                variant='creditCard'
                placeholder='Enter card number'
                rules={[{ required: true, message: 'Please input a card number' }]} />
              <SideBySide>
                <SelectInput
                  className='input'
                  name='expiryMonth'
                  label='Month'
                  placeholder='Select month'
                  options={state.monthOptions}
                  rules={[{ required: true, message: 'Please select a month' }]} />
                <SelectInput
                  className='input'
                  name='expiryYear'
                  label='Year'
                  placeholder='Select year'
                  options={state.yearOptions}
                  rules={[{ required: true, message: 'Please select a year' }]} />
              </SideBySide>
              <TextInput
                name='cvv'
                label={state.cvvLabel}
                placeholder='e.g. 123'
                rules={[{ required: true, message: 'Please input a CVV' }]} />
              <Button className='button' title='Submit' onClick={() => form.submit()} />
            </Form>
          </FormContainer>
        </When>
        <When condition={state.displayStep === DisplayStepEnum.THREE_D_SECURE && !!paymentData}>
          <IframeContainer>
            <iframe
              src={`/payments/${paymentData.payment.id}`}
              sandbox='allow-forms allow-scripts allow-same-origin'
              width='100%'
              height='100%' />
          </IframeContainer>
        </When>
        <When condition={state.displayStep === DisplayStepEnum.CHECK}>
          <LoadingContainer>
            <LoaderBox>
              <SmallLoader color={theme.colors.oranges.coral} />
            </LoaderBox>
            <Heading variant='h4' className='modal-loading-title'>Awaiting payment status</Heading>
          </LoadingContainer>
        </When>
        <When condition={state.displayStep === DisplayStepEnum.DONE}>
          <LoadingContainer>
            <IconBox>
              <LoaderBox>
                <Icon color={theme.colors.greens.chelseaCucumber} icon={IconEnum.CHECKMARK} />
              </LoaderBox>
              <Heading variant='h4' className='modal-loading-title'>Success!</Heading>
            </IconBox>
            <Paragraph className='modal-loading-title' align='center'>Your payment was successful, thank you!</Paragraph>
            <Link href='/'>Home</Link>
          </LoadingContainer>
        </When>
        <When condition={state.displayStep === DisplayStepEnum.FAILED}>
          <LoadingContainer>
            <IconBox>
              <LoaderBox>
                <Icon color={theme.colors.misc.error} icon={IconEnum.CLOSE_OUTLINE} />
              </LoaderBox>
              <Heading variant='h4' className='modal-loading-title'>Failure</Heading>
            </IconBox>
            <Paragraph className='modal-loading-title' align='center'>Your payment has failed, Get in touch with Customer Support on 021 447 4424 or email us at support@ucook.co.za</Paragraph>
            <Link
              onClick={() => setState((prevState) => update(prevState, { displayStep: { $set: DisplayStepEnum.FORM } }))}> Try Again </Link>
          </LoadingContainer>
        </When>
      </Choose>
    </Container>
  )
}
