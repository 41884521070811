import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/EmailPreferences')

const EmailPreferences = loadable(() => import(/* webpackChunkName: "email-preferences-page" */'@pages/EmailPreferences'), {
  resolveComponent: (components) => components.EmailUserPreferencesList,
})

export function EmailPreferencesAsync(): JSX.Element {

  return <EmailPreferences fallback={<div>Loading...</div>} />

}
