import React from 'react'

import styled, { CSS } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Link } from '@atoms/typography'
import { Button, IconEnum } from '@components/index'
import { ResponsivePXValue, theme } from '@components/Theme'
import { useGetAppQuery } from '@hooks/api'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

const PaginationContainer = styled.div<{ $isMobile?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${ResponsivePXValue('gap', '16px')}
  ${ResponsivePXValue('padding', '20px')}

  ${(props): CSS => {
    if (props.$isMobile) {
      return `
          max-width: 90vw;
          flex-wrap: wrap;
        `
    }
  }}
`

export interface GridPaginationProps {
  currentPage: number
  totalCount: number
  itemsPerPage: number
  onPageChange: (page: number) => void
}

export const GridPagination = React.memo(
  function GridPagination({ currentPage, totalCount, itemsPerPage, onPageChange }: GridPaginationProps): React.ReactElement {

    const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
    const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE
    const totalPages: number = Math.ceil(totalCount / itemsPerPage)

    const handlePageChange = (page: number) => {
      if (page >= 1 && page <= totalPages && page !== currentPage) {
        onPageChange(page)
      }
    }

    const activeColor = theme.colors.oranges.coral
    const inactiveTextColor = theme.colors.greys.boulder
    const inactiveIconColor = theme.colors.misc.opaque

    const pages = [...Array(totalPages).keys()]
    let page: number

    return (
      <PaginationContainer $isMobile={isMobile}>
        <Button
          icon={IconEnum.CHEVRON_BACK_OUTLINE}
          iconColor={currentPage === 1 ? inactiveIconColor : activeColor}
          disabled={currentPage === 1}
          color='transparent'
          onClick={() => handlePageChange(currentPage - 1)} />
        <For each='page' of={pages}>
          <Link
            key={`${page}-link`}
            color={page + 1 === currentPage ? activeColor : inactiveTextColor}
            onClick={() => handlePageChange(page + 1)}>
            {page + 1}
          </Link>
        </For>
        <Button
          icon={IconEnum.CHEVRON_FORWARD_OUTLINE}
          iconColor={currentPage === totalPages ? inactiveIconColor : activeColor}
          disabled={currentPage === totalPages}
          color='transparent'
          onClick={() => handlePageChange(currentPage + 1)} />
      </PaginationContainer>
    )
  },
)
