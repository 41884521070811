import React from 'react'

import qs from 'qs'
import styled from 'styled-components'

import { Spacer } from '@client/components/atoms'
import { Aisles, MealKitCompactCard } from '@client/components/molecules'
import { AislesBaseContainer } from '@client/components/Theme'
import { MealKitDishListFragment, PageDataSectionFragment } from '@hooks/api'

const AislesContainer = styled.div`
  ${AislesBaseContainer}
`

interface PageDataFilteredMealKitsProps {
  pageSection: PageDataSectionFragment
}

export function PageDataFilteredMealKits({ pageSection }: PageDataFilteredMealKitsProps): JSX.Element {

  const selectedFilters = { categories: pageSection?.mealKitCategories?.map(mealKitCategory => mealKitCategory.id), tags: pageSection?.mealKitTags?.map(mealKitTag => mealKitTag.id) }
  const newQueryString = qs.stringify({ filters: selectedFilters })
  const viewAllUrl = pageSection?.viewAllUrl || `/meal-kit?${newQueryString}`

  let mealkit: MealKitDishListFragment

  return (
    <AislesContainer>
      <Aisles title={pageSection?.title} viewAllUrl={viewAllUrl} displayViewAllButton={pageSection?.displayViewAllButton} description={pageSection?.description} className='aisles-filtered-data'>
        <For each='mealkit' of={pageSection?.mealKitDishes || []}>
          <MealKitCompactCard
            className='product-card'
            key={mealkit.id}
            mealKit={mealkit} />
        </For>
      </Aisles>
      <Spacer universal='16px' />
    </AislesContainer>
  )

}
