import React from 'react'

import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Heading, Spacer } from '@atoms/index'
import { useConfig } from '@client/contexts/ConfigProvider'
import { ResponsivePXValue } from '@components/Theme'
import { useUserDetailsQuery, useGetAppQuery, useMyShopFrozenAilsesQuery } from '@hooks/api'
import { ProductRangeEnum, DeviceTypeEnum } from '@uctypes/api/globalTypes'
import { SearchEngineOptimization } from '@utility/SearchEngineOptimization'

import { NavLink, NavLinks } from '../molecules'
import { FrozenAislesCarousel } from '../organisms'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;

  ${ResponsivePXValue('width', { mobile: 'CALC(100% - 32px)', tablet: 'CALC(100% - 32px)', desktop: '1136px' }, { desktop: true })}
`
const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const frozenUrls = {
  allUrl: '/frozen',
  craftMealUrl: '/frozen/craft-meal',
  craftPizzaUrl: '/frozen/craft-pizza',
  craftDessertUrl: '/frozen/craft-dessert',
  boughtBeforeUrl: '/frozen/bought-before',
}

export const getFrozenNavLinks = ({
  allActive = false,
  craftMealActive = false,
  craftPizzaActive = false,
  craftDessertActive = false,
  additionalLinks = null,
} = {}): NavLink[] => {
  const navLinks = [{
    title: 'All',
    url: frozenUrls.allUrl,
    isActive: allActive,
  }, {
    title: 'Craft Meal',
    url: frozenUrls.craftMealUrl,
    isActive: craftMealActive,
  },
  {
    title: 'Craft Pizza',
    url: frozenUrls.craftPizzaUrl,
    isActive: craftPizzaActive,
  },
  {
    title: 'Craft Dessert',
    url: frozenUrls.craftDessertUrl,
    isActive: craftDessertActive,
  }]
  if (additionalLinks) {
    navLinks.splice(1, 0, ...additionalLinks)
  }
  return navLinks
}

export function FrozenAisles(): JSX.Element {

  let navLinks = getFrozenNavLinks({ allActive: true })

  const config = useConfig()
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })

  const { data: myShopAisleData } = useMyShopFrozenAilsesQuery({
    variables: {
      limit: 20,
      userId: userDetailsData?.currentUser?.id,
      input: {
        productType: ProductRangeEnum.FROZEN_MEAL,
      },
    },
  })

  const myShopFrozenList = myShopAisleData?.myShopAisle?.list || []
  const hasMyShopItems = myShopFrozenList?.length > 0

  if (hasMyShopItems) {
    const boughtBeforeLink = [{
      title: 'Bought Before',
      url: frozenUrls.boughtBeforeUrl,
      isActive: false,
    }]
    navLinks = getFrozenNavLinks({
      allActive: true,
      additionalLinks: boughtBeforeLink,
    })
  }
  // may move to carousel now

  const itemsOrdered = []
  for (let i = 0; i < myShopFrozenList?.length; i++) {
    itemsOrdered.push(myShopFrozenList[i].group)
  }

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isDesktop = appData.app.deviceType === DeviceTypeEnum.DESKTOP || appData.app.deviceType === DeviceTypeEnum.ULTRA
  const isMiniCard = !isDesktop
  // may move to carousel now

  return (
    <Container>
      <SearchEngineOptimization />
      <Spacer mobile='16px' desktop='32px' />
      <HeadingContainer>
        <Heading variant='h1'>Shop Frozen</Heading>
      </HeadingContainer>
      <NavLinks navLinks={navLinks} outline />
      <Spacer mobile='16px' desktop='32px' />
      <FrozenAislesCarousel isMiniCard={isMiniCard} />
    </Container>
  )
}
