import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import styled, { CSS } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Rule, Button, Paragraph, Loader } from '@client/components/atoms'
import { DashedTable, Modal } from '@client/components/molecules'
import { MealKitOrderList } from '@client/components/molecules/user/MealKitOrderList'
import { FinalRatingPage } from '@client/components/organisms/user/FinalRatingPage'
import { ModalScrollFade, ResponsivePXValue, theme } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { ProductFragment, useUserDetailsQuery, OrderItemFragment, OrderFragment, OrdersQuery, OrdersQueryDocument, useAddOrderItemRatingMutation, useAddOrderRatingMutation, useUpdateOrderItemRatingMutation, useUpdateOrderRatingMutation, useGetAppQuery } from '@hooks/api'
import { ProductRangeEnum } from '@uctypes/api/globalTypes'

const Container = styled.div<{$isNativeApplication:boolean}>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${ResponsivePXValue('padding', { mobile: '0px 16px 12px', tablet: '0px 16px 12px', desktop: '0px 16px 12px' })}
  ${ResponsivePXValue('width', { mobile: '288px', tablet: '360px', desktop: '576px' })}
  ${ResponsivePXValue('min-height', { mobile: '450px', tablet: '450px' })}
`
const ScrollContainer = styled.div`
  height: fit-content;
  ${ResponsivePXValue('max-height', { mobile: '100%', tablet: '100%', desktop: '460px' })}
  overflow-y: auto;
  --mask-height: 20px;
  padding-bottom: 20px;
  ${ModalScrollFade}

`
const ButtonsContainer = styled.div`
  ${ResponsivePXValue('width', { mobile: '288px', tablet: '360px', desktop: '164px' })}
  ${ResponsivePXValue('height', { mobile: '30px', tablet: '30px', desktop: '40px' })}
  ${ResponsivePXValue('padding-top', { mobile: '12px', tablet: '12px', desktop: '16px' })}
  justify-content: center;
  margin: 0 auto;
`
const LoaderContainer = styled.div`
  opacity: 0.5;
  z-index: 10;
  height: 100%;
  width: 100%;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
`
const ErrorBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(251, 108, 89, 0.1);
  ${(props): CSS => {
    return ResponsivePXValue('border', `1px solid ${props.theme.colors.misc.error}`)
  }}
  ${ResponsivePXValue('border-radius', '4px')}
  ${ResponsivePXValue('padding', '10px 0')}
  ${ResponsivePXValue('margin-top', '16px')}

  .text {
    margin: 0;
    padding: 0;
  }
`
export interface MealKitRatingModalProps {
  open: boolean
  ratingOrder: OrdersQuery['orders']['list'][0] | undefined
  ratingPast?: number
  orderItemId?: string
  onClose: () => void
  onObject?: (updateRatingsData: UpdatedRatingsData) => void
}

interface OrderRatingData {
  rating: number
  ratingComments: string[]
  otherInfo?: string[]
}

interface UpdatedRatingsData {
  orderID: string
  rating: number
  mealKitId: string
}

interface MealKitListState {
  product?: ProductFragment
  disabled: boolean
  productType: ProductRangeEnum
  ratingId?: string
  otherInfo?: string[]
  selectedOptions: string[]
  orderRatings: { [k: string]: OrderRatingData }
  updatedRatings: { [k: string]: UpdatedRatingsData }
  showFinalPage: boolean
  openModal: boolean
  pillErrorsNumber: number
  otherErrorsNumber: number
  hasLoopedAll?: boolean
  showPillError: boolean
  showOtherError: boolean
  submitting: boolean
}

const DEFAULT_STATE: MealKitListState = {
  disabled: true,
  productType: ProductRangeEnum.MEAL_KIT,
  otherInfo: [],
  selectedOptions: [],
  orderRatings: {},
  updatedRatings: {},
  showFinalPage: false,
  openModal: false,
  pillErrorsNumber: 0,
  otherErrorsNumber: 0,
  showPillError: false,
  showOtherError: false,
  submitting: false,
}

export function MealKitRatingModal({ open, ratingOrder, orderItemId, ratingPast, onClose }: MealKitRatingModalProps): JSX.Element {

  const config = useConfig()
  const [state, setState] = useState<MealKitListState>({ ...DEFAULT_STATE })
  const navigate = useNavigate()
  const [addRating] = useAddOrderItemRatingMutation()
  const [updateRating] = useUpdateOrderItemRatingMutation()
  const [addOrderRating] = useAddOrderRatingMutation()
  const [updateOrderRating] = useUpdateOrderRatingMutation()
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const isMealKit = (orderItem: OrderItemFragment): boolean => {
    return orderItem?.product?.__typename === 'MealKit' && orderItem?.product?.name !== 'Fixed Plan Fee'
  }
  const mostRecentMealKits = ratingOrder?.orderItems?.filter(isMealKit) || []
  const oldOrderComments = ratingOrder?.orderRating?.feedback

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isNativeApplication = appData.app.isNativeApplication

  useEffect(() => {
    for (let i = 0; i < mostRecentMealKits?.length; i++) {
      const orderRatingData = state.orderRatings

      if (mostRecentMealKits[i]?.orderItemRating) {
        const ratedMealKitId = mostRecentMealKits[i]?.id
        const selectedPills = mostRecentMealKits[i]?.orderItemRating?.selectedOptions
        const starRating = mostRecentMealKits[i]?.orderItemRating?.value
        const otherInfoFilled = mostRecentMealKits[i]?.orderItemRating?.feedback

        orderRatingData[ratedMealKitId] = {
          rating: starRating,
          ratingComments: [...selectedPills],
          otherInfo: [otherInfoFilled],
        }
        setState((prevState) => update(prevState, {
          orderRatings: { $set: orderRatingData },
        }))
      }
    }

  }, [])

  useEffect(() => {

    if (state.hasLoopedAll) {
      if (state.pillErrorsNumber === 0 && state.otherErrorsNumber === 0) {
        setState((prevState) => update(prevState, {
          showFinalPage: { $set: true },
        }))
      }
    }
  }, [state.hasLoopedAll])

  useEffect(() => {
    const orderRatingData = state.orderRatings
    if (orderItemId && ratingPast) {
      orderRatingData[orderItemId] = {
        rating: ratingPast,
        ratingComments: [],
        otherInfo: [],
      }
    }
    setState((prevState) => update(prevState, {
      orderRatings: { $set: orderRatingData },
    }))
  }, [])

  const dynamicTitle = (): string => {
    let title = 'Rate your recent meal kits'
    if (state.showFinalPage) {
      title = ''
    }
    return title
  }

  const _handleOptionClicked = (option: string, mealKitId: string): void => {

    const orderRatingData = state.orderRatings

    if (state.orderRatings[mealKitId]?.ratingComments.includes(option)) {
      const selectedOptions = state.selectedOptions.filter((selectedOption) => { return (selectedOption !== option) })
      setState(update(state, { selectedOptions: { $set: selectedOptions } }))
      // this is for removing
      orderRatingData[mealKitId].ratingComments.splice(orderRatingData[mealKitId].ratingComments.indexOf(option), 1)
      setState((prevState) => update(prevState, {
        orderRatings: { $set: orderRatingData },
      }))
    } else {
      const selectedOptions = [...state.selectedOptions, option]
      setState(update(state, { selectedOptions: { $set: selectedOptions } }))
      // this is for adding
      orderRatingData[mealKitId]?.ratingComments.push(option)
      setState((prevState) => update(prevState, {
        orderRatings: { $set: orderRatingData },
      }))
    }
  }
  const _handleOtherClicked = (otherInfo: string, mealKitId: string): void => {
    const orderRatingData = state.orderRatings
    orderRatingData[mealKitId]?.otherInfo.pop()
    orderRatingData[mealKitId]?.otherInfo.push(otherInfo)
    setState((prevState) => update(prevState, {
      orderRatings: { $set: orderRatingData },
    }))
  }

  const navigateToPDP = (addOnItem: OrderItemFragment): void => {
    if (addOnItem.product.__typename === 'MealKit') {
      navigate(`/meal-kit/dishes/${addOnItem.product.group.slug}`)
    }
  }

  const _handleSubmit = async () => {
    setState((prevState) => update(prevState, {
      hasLoopedAll: { $set: false },
      showPillError: { $set: false },
      showOtherError: { $set: false },
      submitting: { $set: true },
    }))
    const refetchQueries = [{
      query: OrdersQueryDocument,
      variables: {
        filters: {
          users: [userDetailsData?.currentUser.id],
          status: ['COMPLETE', 'PROCESSING'],
        },
        limit: 10,
      },
    }]
    let listPosition = 0
    const pillErrors = 0
    let otherErrors = 0
    let counter = 0
    while (listPosition !== Object.keys(state.orderRatings).length) {
      // listPosition not the same as meal id that has the rating so ill have another mini loop to check if value is set and then run that every time till loop is done?

      while (state.orderRatings[mostRecentMealKits[counter]?.id]?.rating === undefined) {
        counter = counter + 1
      }

      const mealKitID = mostRecentMealKits[counter]?.id
      const type = mostRecentMealKits[counter]?.product?.productRange

      let error = ''
      if (type === ProductRangeEnum.MEAL_KIT) {
        let oldRatingId = null
        oldRatingId = mostRecentMealKits[counter]?.orderItemRating?.id
        if (oldRatingId) {
          try {
            if (state.orderRatings[mealKitID].ratingComments.includes('Other') && state.orderRatings[mealKitID].otherInfo.length === 0) {
              error = 'Please fill in other info'
              throw error
            }
            await updateRating({
              variables: {
                id: oldRatingId,
                input: {
                  feedback: state.orderRatings[mealKitID].otherInfo[0],
                  selectedOptions: state.orderRatings[mealKitID].ratingComments,
                  value: state.orderRatings[mealKitID].rating,
                },
              },
              refetchQueries,
              awaitRefetchQueries: true,
            })
          } catch (err) {
            if (error === 'Please fill in other info') {
              otherErrors = otherErrors + 1
              setState((prevState) => update(prevState, {
                showOtherError: { $set: true },
              }))
            }
          }
        } else {
          try {
            if (state.orderRatings[mealKitID].ratingComments.includes('Other') && state.orderRatings[mealKitID].otherInfo.length === 0) {
              error = 'Please fill in other info'
              throw error
            }
            await addRating({
              variables: {
                input: {
                  user: userDetailsData.currentUser.id,
                  feedback: state.orderRatings[mealKitID].otherInfo[0],
                  orderItem: mealKitID,
                  selectedOptions: state.orderRatings[mealKitID].ratingComments,
                  value: state.orderRatings[mealKitID].rating,
                },
              },
              refetchQueries,
              awaitRefetchQueries: true,
            })
          } catch (err) {
            if (error === 'Please fill in other info') {
              otherErrors = otherErrors + 1
              setState((prevState) => update(prevState, {
                showOtherError: { $set: true },
              }))
            }
          }
        }
        listPosition++
        counter++
      }
    }
    setState((prevState) => update(prevState, {
      hasLoopedAll: { $set: true },
      pillErrorsNumber: { $set: pillErrors },
      otherErrorsNumber: { $set: otherErrors },
      submitting: { $set: false },
    }))
  }

  const _handleMoreSubmit = async (moreInfo?: string) => {
    setState((prevState) => update(prevState, {
      submitting: { $set: true },
    }))
    const refetchQueries = [{
      query: OrdersQueryDocument,
      variables: {
        filters: {
          users: [userDetailsData?.currentUser.id],
          status: ['COMPLETE', 'PROCESSING'],
        },
        limit: 10,
      },
    }]
    const orderRatingOld = ratingOrder?.orderRating?.id
    if (orderRatingOld) {
      await updateOrderRating({
        variables: {
          id: orderRatingOld,
          input: {
            order: ratingOrder.id,
            user: userDetailsData.currentUser.id,
            feedback: moreInfo,
            value: 0, // not needed
          },
        },
        refetchQueries,
        awaitRefetchQueries: true,
      })
    } else {
      await addOrderRating({
        variables: {
          input: {
            order: ratingOrder.id,
            user: userDetailsData.currentUser.id,
            feedback: moreInfo,
            value: 0, // not needed
          },
        },
        refetchQueries,
        awaitRefetchQueries: true,
      })
    }
    for (const key in state.orderRatings) {
      delete state.orderRatings[key]
    }
    setState((prevState) => update(prevState, {
      submitting: { $set: false },
    }))
    onClose()

  }

  const _handleRatingClick = (rating: number, cartItem: OrderItemFragment, order: OrderFragment) => {
    let ratingId: string | null
    if (cartItem.product.productRange === ProductRangeEnum.MEAL_KIT) {
      const orderRatingData = state.orderRatings

      orderRatingData[cartItem.id] = {
        rating,
        ratingComments: orderRatingData[cartItem.id]?.ratingComments ? orderRatingData[cartItem.id]?.ratingComments : [],
        otherInfo: orderRatingData[cartItem.id]?.otherInfo ? orderRatingData[cartItem.id]?.otherInfo : [],
      }

      const updateRatingsData = state.updatedRatings

      updateRatingsData[cartItem.id] = {
        orderID: order.id,
        rating,
        mealKitId: cartItem.id,
      }
      setState((prevState) => update(prevState, {
        orderRatings: { $set: orderRatingData },
        updatedRatings: { $set: updateRatingsData },
      }))

    }
    setState((prevState) => update(prevState, {
      product: { $set: cartItem.product },
      productType: { $set: cartItem.product.productRange },
      orderId: { $set: order.id },
      ratingId: { $set: ratingId },
      disabled: { $set: false },
    }))
  }
  const _handleClose = () => {
    for (const key in state.orderRatings) {
      delete state.orderRatings[key]
    }
    onClose()
  }

  let mealKit: OrderItemFragment
  let index: number
  const loading = state.submitting

  return (
    <Choose>
      <When condition={mostRecentMealKits?.length <= 0}>
        <></>
      </When>
      <Otherwise>
        <Modal title={dynamicTitle()} open={open} onClose={_handleClose} allowScroll={true} allowBackgroundClose={true} showCloseButton>
          <If condition={loading}>
            <LoaderContainer>
              <Loader noShadow={true} />
            </LoaderContainer>
          </If>
          <If condition={!state.showFinalPage}>
            <Container $isNativeApplication={isNativeApplication}>
              <ScrollContainer>
                <DashedTable seperatorVariant='none'>
                  <If condition={!!mostRecentMealKits}>
                    <For each='mealKit' of={mostRecentMealKits} index='index'>
                      <div key={index}>
                        <MealKitOrderList
                          prevInput={state.orderRatings[mealKit.id]?.otherInfo[0]}
                          orderItem={mealKit}
                          rating={mealKit?.orderItemRating?.value}
                          selectedOptions={state.orderRatings[mealKit.id]?.ratingComments}
                          newRating={state.orderRatings[mealKit.id]?.rating}
                          onNavigate={() => navigateToPDP(mealKit)}
                          onRate={(rating) => _handleRatingClick(rating, mealKit, ratingOrder)}
                          onOtherClicked={(otherInfo) => _handleOtherClicked(otherInfo, mealKit.id)}
                          onSelectedOptionClicked={(option) => _handleOptionClicked(option, mealKit.id)} />
                        <Rule color='slate' />
                      </div>
                    </For>
                  </If>
                </DashedTable>
              </ScrollContainer>
              <If condition={state.showPillError}>
                <ErrorBlock>
                  <Paragraph
                    className='text'
                    variant='p2'
                    bold={false}
                    color={theme.colors.misc.error}>
                    Please select at least 1 feedback item for each star rating
                  </Paragraph>
                </ErrorBlock>
              </If>
              <If condition={state.showOtherError}>
                <ErrorBlock>
                  <Paragraph
                    className='text'
                    variant='p2'
                    bold={false}
                    color={theme.colors.misc.error}>
                    Please add additional information for other
                  </Paragraph>
                </ErrorBlock>
              </If>

              <If condition={Object.keys(state.orderRatings).length === 0}>
                <ButtonsContainer>
                  <Button fullWidth={true} color='grey' title='SEND FEEDBACK' />
                </ButtonsContainer>
              </If>
              <If condition={Object.keys(state.orderRatings).length !== 0}>
                <ButtonsContainer>
                  <Button fullWidth={true} variant='primary' color='black' title='SEND FEEDBACK' onClick={_handleSubmit} />
                </ButtonsContainer>
              </If>
            </Container>
          </If>
          <If condition={state.showFinalPage}>
            <FinalRatingPage
              oldOrderComments={oldOrderComments}
              onSubmit={(moreInfo) => _handleMoreSubmit(moreInfo)}
              onClose={_handleClose} />
          </If>
        </Modal>
      </Otherwise>
    </Choose>
  )
}
