import React, { Fragment } from 'react'

import { ContentCard, ContentCardHeaderEnum } from '@molecules/index'
import { UserCardList } from '@organisms/index'
import { SearchEngineOptimization } from '@utility/SearchEngineOptimization'

const seo = {
  name: 'User Dashboard',
  title: 'User Dashboard',
  meta: [{
    name: 'robots',
    content: 'noindex,nofollow',
  }],
}

export function Payment(): JSX.Element {

  const title = 'PAYMENT DETAILS'
  const description = 'Your Meal Kit plan is renewed every Wednesday at 9AM. If your billing details have changed you can update them below.'

  return (
    <Fragment>
      <SearchEngineOptimization seo={seo} />
      <ContentCard title={title} description={description} headerType={ContentCardHeaderEnum.STANDARD}>
        <UserCardList />
      </ContentCard>
    </Fragment>
  )

}
