import React, { useEffect, useState } from 'react'

import { format } from 'date-fns'
import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Heading, Rule, Spacer, Loader } from '@atoms/index'
import { MealKitRatingModal } from '@client/components/molecules/user/MealKitRatingModal'
import { scrollToSelectorTopOfElement } from '@client/components/utility'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useOnScreen } from '@client/hooks/UseOnScreen'
import { OrderItemTitleStyle, ResponsivePXValue, ZeroSpace } from '@components/Theme'
import { ProductFragment, useGetAppQuery, useUserDetailsQuery, OrderItemFragment, OrderFragment, useOrdersQuery, ProductOrderFragment, MealKitOrderFragment } from '@hooks/api'
import { Accordion, Tab, OrderSubscriptionItemPast, OrderAddOnItemPast, OrderBreakdown, SectionLoading } from '@molecules/index'
import { ProductRangeEnum, DeviceTypeEnum, OrderStatusEnum, OrderOriginEnum, OrderOrderEnum, OrderDirectionEnum } from '@uctypes/api/globalTypes'

import { ProductRatingModal } from './ProductRatingModal'

const MainContainer = styled.div`
  .mobileOddTab{
    background: ${(props): string => props.theme.colors.whites.pureWhite};
    border-bottom: ${(props): string => `1px solid ${props.theme.colors.whites.desertStorm}`}
  }

  .mobileEvenTab{
    background: ${(props): string => props.theme.colors.whites.alabaster};
  }

  .accordion {
    border-radius: 0;
  }

  .order-item-title{
    ${OrderItemTitleStyle}
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('padding', { mobile: '0 16px', tablet: ' 0 24px', desktop: '0 32px' })};
`

const HeaderContainer = styled.div`
  .heading {
    ${ZeroSpace}
    line-height: 1;
  }
`

const Span = styled.span`
  color: ${(props): string => props.theme.colors.oranges.coral};
  cursor: pointer;
`
const MoreContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('width', { mobile: 'CALC(100$ - 64px)', desktop: 'CALC(100% - 64px)' })}
 
`
interface TabsState {
  order: OrderFragment | undefined
  activeTab: string
  product?: ProductFragment
  rating: number
  ratingOpen: boolean
  mkRatingOpen: boolean
  productType: ProductRangeEnum
  orderId: string
  ratingId?: string
  orderItemId: string
  skip: number
  limit: number
  fetchMoreLoading: boolean
  loaderNumber: number
  fetchedAmount: number
}

const DEFAULT_STATE: TabsState = {
  order: {} as OrderFragment,
  activeTab: '',
  ratingOpen: false,
  mkRatingOpen: false,
  rating: 0,
  productType: ProductRangeEnum.MEAL_KIT,
  orderId: '',
  orderItemId: '',
  skip: 0,
  limit: 20,
  fetchMoreLoading: false,
  loaderNumber: 0,
  fetchedAmount: 0,
}

export function PastOrderList(): JSX.Element {

  const config = useConfig()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const [state, setState] = useState<TabsState>({ ...DEFAULT_STATE })
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE || appData.app.deviceType === DeviceTypeEnum.TABLET

  const { data: ordersData, loading: ordersLoading, fetchMore } = useOrdersQuery({
    context: { key: 'uniqueKeyForChildQuery' },
    ssr: config.fetchSSRQuery() || userDetailsLoading,
    variables: {
      filters: {
        users: [userDetailsData?.currentUser?.id],
        status: [OrderStatusEnum.COMPLETE, OrderStatusEnum.PROCESSING],
        origin: [OrderOriginEnum.NODE],
      },
      order: {
        field: OrderOrderEnum.DISPATCH_DATE,
        direction: OrderDirectionEnum.DESC,
      },
      skip: state.skip,
      limit: state.limit,
    },
  })
  const loading = ordersLoading || userDetailsLoading
  const hasMore = ordersData?.orders?.count > state.skip + state.limit

  const loadedListLength = ordersData?.orders?.list?.length
  const _handleLink = (href: string) => {
    navigate(href)
  }

  const _handleFetchMore = (): void => {
    if (loadedListLength === state.skip + state.limit) {
      setState((prevState) => update(prevState, {
        skip: { $set: prevState.skip + prevState.limit },
        fetchedAmount: { $set: prevState.fetchedAmount + 1 },
      }))
    }

  }
  const performFetchMore = async (): Promise<void> => {
    fetchMore({
      variables: {
        skip: state.skip,
      },
    })
  }
  const isMealKit = (orderItem: OrderItemFragment): boolean => {
    return orderItem?.product?.__typename === 'MealKit'&& orderItem?.product?.name !== 'Fixed Plan Fee'
  }

  const isMealKitPlan = (orderItem: OrderItemFragment): boolean => {
    return orderItem?.product?.__typename === 'MealKitPlan'
  }

  const isAddOn = (orderItem: OrderItemFragment): boolean => {
    const addOnTypes = ['FrozenMeal', 'Wine', 'MarketProduct']
    return addOnTypes.includes(orderItem?.product?.__typename)
  }

  const downloadFile = (product: ProductOrderFragment & MealKitOrderFragment): void => {
    const href = product.recipeCard.location
    const a = document.createElement('a')
    a.style.display = 'none'
    document.body.appendChild(a)
    a.href = href
    a.download = 'file.pdf'
    a.target = '_blank'
    a.click()
    if (config.isBrowser()) {
      window.URL.revokeObjectURL(a.href)
    }
    document.body.removeChild(a)
  }

  // TODO make this state based and not static based

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mealKits: any = []

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const addOns: any = []

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mkPrice: any = []
  const pastOrders = ordersData?.orders?.list

  for (const i in pastOrders) {
    const order = pastOrders[i]
    mealKits[order.id] = order?.orderItems?.filter(isMealKit)
    addOns[order.id] = order?.orderItems?.filter(isAddOn)
    mkPrice[order.id] = order?.orderItems?.filter(isMealKitPlan)
  }

  const handleRatingClick = (rating: number, cartItem: OrderItemFragment, order: OrderFragment) => {

    let ratingId: string | null

    if (cartItem.product.productRange === ProductRangeEnum.FROZEN_MEAL) {
      ratingId = cartItem?.orderItemRating?.id
    } else if (cartItem.product.productRange === ProductRangeEnum.WINE) {
      ratingId = cartItem?.orderItemRating?.id
    } else if (cartItem.product.productRange === ProductRangeEnum.MARKET_PRODUCT) {
      ratingId = cartItem?.orderItemRating?.id
    }
    if (cartItem.product.productRange === ProductRangeEnum.MEAL_KIT) {

      setState((prevState) => update(prevState, {
        rating: { $set: rating },
        mkRatingOpen: { $set: true },
        product: { $set: cartItem.product },
        productType: { $set: cartItem.product.productRange },
        orderId: { $set: order.id },
        ratingId: { $set: ratingId },
        order: { $set: order },
        orderItemId: { $set: cartItem.id },
      }))
    } else {
      setState((prevState) => update(prevState, {
        rating: { $set: rating },
        ratingOpen: { $set: true },
        product: { $set: cartItem.product },
        productType: { $set: cartItem.product.productRange },
        orderId: { $set: order.id },
        ratingId: { $set: ratingId },
        order: { $set: order },
        orderItemId: { $set: cartItem.id },
      }))

    }

  }

  const navigateToPDP = (addOnItem: OrderItemFragment): void => {
    if (addOnItem.product.__typename === 'Wine') {
      navigate(`/wine/${addOnItem.product.slug}`)
    } else if (addOnItem.product.__typename === 'FrozenMeal') {
      navigate(`/frozen/craft-meal/${addOnItem.product.group.slug}`)
    } else if (addOnItem.product.__typename === 'MealKit') {
      navigate(`/meal-kit/${addOnItem.product.group.slug}`)
    } else if (addOnItem.product.__typename === 'MarketProduct') {
      navigate(`/market/${addOnItem.product.slug}`)
    }
  }

  const getDeliveryFee = (order: OrderFragment): string => {
    const deliveryFee = order.additions.find(addition => { return addition.id === 'DELIVERY' })
    if (deliveryFee) {
      return deliveryFee?.value === 0 ? 'Free' : 'R ' + deliveryFee?.value.toFixed(2)
    } else {
      return 'R 0.00'
    }
  }

  const getSubscriptionsTotal = (order: OrderFragment): string => {
    const subFee = order?.orderItems.filter(cartItem => cartItem.product.__typename === 'MealKitSubscriptionFee')
    if (!!subFee && subFee[0]?.price>0) {
      const cartItems = order?.orderItems
      const mealKitProduct = (cartItems || []).filter(cartItem => cartItem.product.__typename === 'MealKitSubscriptionFee' || cartItem.product.__typename === 'MealKit')
      let mealKitCost = 0
      for (let i = 0; i < mealKitProduct.length; i++) {
        mealKitCost = mealKitProduct[i]?.price + mealKitCost
      }
      return 'R ' + mealKitCost.toFixed(2)
    } else {
      if (mkPrice[order.id].length === 0 || !mkPrice[order.id][0].price) {
        return 'R 0.00'
      } else {
        return 'R ' + mkPrice[order.id][0].price
      }
    }

  }

  const getDiscount = (order: OrderFragment): string => {
    let value = 0
    for (let i = 0; i < order.reductions.length; i++) {
      value += order.reductions[i].value
    }
    return 'R ' + value.toFixed(2)
  }

  const getUserPoints = (order: OrderFragment): string => {
    return order.assignedUserPoints.toFixed(2)
  }

  const getAddOnsTotal = (order: OrderFragment): string => {
    let value = 0
    const orderAddons = addOns[order.id]
    for (let i = 0; i < orderAddons.length; i++) {
      value += orderAddons[i].price
    }
    return 'R ' + value.toFixed(2)
  }

  let orderItem: OrderItemFragment
  let addOnItem: OrderItemFragment
  let loopIndex: string
  let order: OrderFragment
  let tabIndex: number

  const tabChange = (activeTab: string): void => {

    setState(prevState => ({ ...prevState, activeTab }))

    let scrollTimer

    if (activeTab.length > 0) {

      scrollTimer = setTimeout(() => {
        scrollToSelectorTopOfElement(`#id-${activeTab}`, isMobile)
      }, 300)

    } else {
      if (scrollTimer) {
        clearTimeout(scrollTimer)
      }
    }
  }

  const firstId = pastOrders ? pastOrders[0]?.id : null
  const isDoneLoading = state.skip >= ordersData?.orders?.count

  useEffect(() => {
    setState(prevState => ({ ...prevState, activeTab: firstId }))
  }, [firstId])

  useEffect(() => {
    performFetchMore()
  }, [state.skip])

  const divRefs = useOnScreen(_handleFetchMore)

  return (
    <MainContainer>
      <Choose>
        <When condition={loading}>
          <SectionLoading />
        </When>
        <When condition={!loading && ordersData?.orders?.count === 0}>
          <Container>
            <Spacer universal='24px' />
            <HeaderContainer>
              <Heading className='heading' align='center' variant='h6'>
                No orders, let&apos;s get cooking.<Span onClick={() => _handleLink('/meal-kit')}> See this week&apos;s menu</Span>
              </Heading>
            </HeaderContainer>
            <Spacer universal='24px' />
          </Container>
        </When>
        <When condition={!!ordersData && ordersData?.orders?.count !== 0}>
          <If condition={state.ratingOpen}>
            <ProductRatingModal
              open={state.ratingOpen}
              orderId={state.orderId}
              rating={state.rating}
              product={state.product}
              orderItemId={state.orderItemId}
              productType={state.productType}
              onSuccess={() => { setState((prevState) => ({ ...DEFAULT_STATE, activeTab: prevState.activeTab })) }}
              onCancel={() => { setState((prevState) => ({ ...DEFAULT_STATE, activeTab: prevState.activeTab })) }}
              ratingId={state.ratingId} />
          </If>
          <If condition={state.mkRatingOpen}>
            <MealKitRatingModal
              open={state.mkRatingOpen}
              ratingOrder={state.order}
              ratingPast={state.rating}
              onClose={() => { setState((prevState) => ({ ...DEFAULT_STATE, activeTab: prevState.activeTab })) }}
              orderItemId={state.orderItemId} />
          </If>

          <Accordion
            activeTab={state.activeTab}
            backgroundColor={theme.colors.whites.pureWhite}
            onTabChange={tabChange}
            className='accordion'>
            <For each='order' of={pastOrders} index='tabIndex'>
              <Tab
                tab={order.id}
                noBorder
                className={tabIndex % 2 === 1 ? 'mobileEvenTab' : 'mobileOddTab'}
                invertTheme
                title={format(new Date(order.createdAt), 'dd MMM yyyy')}
                key={order.id}>
                <Container>
                  <If condition={mealKits[order.id].length !== 0}>
                    <HeaderContainer>
                      <Heading className='heading' align='left' variant='h6'>Meal Kit Plan</Heading>
                    </HeaderContainer>
                    <Spacer universal='24px' />
                    <For each='orderItem' index='loopIndex' of={mealKits[order.id]}>
                      <OrderSubscriptionItemPast
                        key={orderItem.id}
                        cartItem={orderItem}
                        rating={orderItem?.orderItemRating?.value}
                        onNavigate={() => navigateToPDP(orderItem)}
                        onDownload={() => downloadFile(orderItem.product as ProductOrderFragment & MealKitOrderFragment)}
                        onRate={(rating) => handleRatingClick(rating, orderItem, order)} />
                    </For>
                    <Spacer universal='16px' />
                    <Rule color='slate' />
                  </If>
                  <If condition={addOns[order.id].length !== 0}>
                    <Spacer universal='24px' />
                    <HeaderContainer>
                      <Heading className='heading' align='left' variant='h6'>{mealKits[order.id].length !== 0 ? 'Add-ons' : 'On Demand'}</Heading>
                    </HeaderContainer>
                    <Spacer universal='24px' />
                    <For each='addOnItem' index='loopIndex' of={addOns[order.id]}>
                      <OrderAddOnItemPast
                        key={loopIndex + order.id}
                        addOnItem={addOnItem}
                        rating={addOnItem?.orderItemRating?.value}
                        onNavigate={() => navigateToPDP(addOnItem)}
                        onRate={(rating) => handleRatingClick(rating, addOnItem, order)} />
                    </For>
                    <Spacer universal='16px' />
                    <Rule color='slate' />
                  </If>
                  <OrderBreakdown
                    subscription={getSubscriptionsTotal(order)}
                    addOn={getAddOnsTotal(order)}
                    delivery={getDeliveryFee(order)}
                    discount={getDiscount(order)}
                    userPoints={getUserPoints(order)}
                    total={'R ' + order.grandTotalAmount.toFixed(2)} />
                </Container>
              </Tab>
            </For>
          </Accordion>
        </When>
        <Otherwise>
          <SectionLoading />
        </Otherwise>
      </Choose>
      <If condition={!isDoneLoading && hasMore && !ordersLoading}>
        <MoreContainer ref={divRefs}>
          <Loader noShadow={true}></Loader>
        </MoreContainer>
      </If>
    </MainContainer>
  )

}
