import React, { useEffect, useState, useRef } from 'react'

import { format, getDay, previousWednesday } from 'date-fns'
import DatePicker from 'react-datepicker'
import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { ModalsPlugin, GlobalModalTypeEnum, MODALS_DEFAULT_STATE } from '@api/local/ModalsPlugin'
import { Button, IconEnum, Link, Paragraph, Spacer } from '@client/components'
import { CheckBox, CheckBoxOption, Form, Meta, Modal, NamePath, Notification, SectionLoading, useForm } from '@client/components/molecules'
import { ModalActionContainer, ModalContainerStyle, ResponsivePXValue, ResponsiveProperty, theme } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SiteHelper } from '@client/lib/SiteHelper'
import { useUserCartQuery, useGetModalsQuery, usePauseSubscription, useGetAppQuery } from '@hooks/api'

const Container = styled.div<{$isNativeApplication:boolean}>`
  ${ModalContainerStyle}
  
  .pause-input-wrapper {
    margin-top: 0;
  }

  .item-container {
    justify-content: flex-start;
  }

  .item-label{
    ${ResponsivePXValue('margin-left', { mobile: '12px', tablet: '12px', desktop: '16px' })}
  }

  .pause-indefinite {
    .item-container {
      align-items: center;
    }
  }

   .react-datepicker__header {
      border-bottom: none;
      background-color : ${(props): string => props.theme.colors.whites.pureWhite};
    }

    .react-datepicker__day-names {
       ${ResponsivePXValue('margin-top', '8px')}
       ${ResponsivePXValue('margin-bottom', '-16px')}
    }

    .react-datepicker__navigation-icon::before {
      font-family: gordita;
      font-weight: 500;
      border-color: ${(props): string => props.theme.colors.greys.liteCodGrey};
    }
    
    .react-datepicker__current-month {
      font-family: gordita;
      font-weight: 500;
      color: ${(props): string => props.theme.colors.greys.liteCodGrey};
    }

    .react-datepicker__day--keyboard-selected {
      background-color: ${(props): string => props.theme.colors.whites.pureWhite};
    }

  .react-datepicker-wrapper {
      width: fit-content;

      .react-datepicker__input-container {
        width: fit-content;

        input {
          border-radius: 4px;
          border: 1px solid ${(props): string => props.theme.colors.whites.silver};
          ${ResponsivePXValue('padding', '8px')}
        }
     }

    .react-datepicker__calendar-icon {
      ${ResponsivePXValue('left', { desktop: '-30px' })}
      ${ResponsivePXValue('right', { mobile: '-30px' })}
    }

    .react-datepicker__close-icon::after{
      background-color : ${(props): string => props.theme.colors.greys.mineshaft};
    }
  }
`

const Calendar = styled.div`
  display: flex;
  ${ResponsiveProperty('align-items', { mobile: 'flex-start', tablet: 'flex-start', desktop: 'center' })}
  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
`
const ActionContainer = styled.div`
  ${ModalActionContainer()};
`

interface UpcomingMenusObject {
  billingDate: string
  id: string
  deliveryDate: string
  openingDate: string
  openingDateYear: string
}

export interface InternalFieldData extends Meta {
  value: string
}
/**
* Used by `setFields` config
*/
export interface FieldData extends Partial<Omit<InternalFieldData, 'name'>> {
  name: NamePath
}

export interface PauseSubscriptionModalProps {
  onClose?: (subscriptionPaused?: boolean) => void
}

interface PauseSubscriptionModalState {
  loading: boolean
  enableConfirm: boolean
  deliveryDate: Date | null
  minDate: Date
  dateFormattedMenus: UpcomingMenusObject[]
  error: string
}

const DEFAULT_STATE: PauseSubscriptionModalState = {
  loading: false,
  enableConfirm: false,
  deliveryDate: null,
  minDate: new Date(),
  dateFormattedMenus: [],
  error: '',
}

export function PauseSubscriptionModal({ onClose }: PauseSubscriptionModalProps): JSX.Element {

  const config = useConfig()
  const [form] = useForm()
  const [state, setState] = useState<PauseSubscriptionModalState>({ ...DEFAULT_STATE })
  const [pauseSubscription] = usePauseSubscription()
  const { data: userCartData, loading: userCartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const { data = { modals: { ...MODALS_DEFAULT_STATE } } } = useGetModalsQuery()
  const toggleSubscriptionReasons = data?.modals?.toggle
  const blurDatePickerRef = useRef(null)
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isNativeApplication = appData.app.isNativeApplication

  const _handleClose = (subscriptionPaused?: boolean): void => {
    onClose?.(subscriptionPaused)
    ModalsPlugin.shared().toggleGlobalModal(false, GlobalModalTypeEnum.PAUSE_SUBSCRIPTION)

    if (toggleSubscriptionReasons) {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.PAUSE_SUBSCRIPTION_REASON)
    }
  }

  const _handleModalClose = (): void => {
    onClose?.()
    ModalsPlugin.shared().toggleGlobalModal(false, GlobalModalTypeEnum.PAUSE_SUBSCRIPTION)
  }

  const _handlePauseSubscription = async () => {

    setState((prevState) => ({ ...prevState, loading: true }))

    const untilDate = previousWednesday(previousWednesday(state.deliveryDate))
    untilDate.setHours(10)

    const sendData = {
      id: userCartData?.currentUser?.activeMenu?.subscription?.id,
      ...(state.deliveryDate && { untilDate }),
    }

    await pauseSubscription({
      variables: sendData,
      refetchQueries: SiteHelper.getUserRefetchQueries(),
      awaitRefetchQueries: true,
    })

    setState((prevState) => ({ ...prevState, loading: false }))
    _handleClose(true)
  }

  const _handleChange = (changedFields: FieldData[]) => {
    changedFields.forEach((field) => {
      (field.name as string[]).forEach((name) => {
        const pauseIndefinitelySelected = name === 'pauseIndefinitely' && field.value.length > 0
        const enableConfirm = (pauseIndefinitelySelected && state.deliveryDate === null) || state.deliveryDate !== null

        setState((prevState) => ({
          ...prevState,
          enableConfirm,
          ...(pauseIndefinitelySelected && { deliveryDate: null }),
        }))

      })
    })
  }

  const _handleDatePickerChange = (date: Date) => {
    setState((prevState) => ({ ...prevState, deliveryDate: date, enableConfirm: !!date }))
  }

  const isDeliveryDay = (date: Date) => {
    const day = date.getDay()
    return day === 0 || day === 1
  }

  const _handleBlurInput = () => {
    blurDatePickerRef.current.setBlur()
  }

  const pauseIndefinitelyOptions: CheckBoxOption[] = [{
    value: 'pauseIndefinitely',
    title: (
      <>
        <Paragraph variant='p2'>I want to pause indefinitely</Paragraph>
        <Paragraph variant='p3'>(Reactivate your plan whenever you are ready)</Paragraph>
      </>),
  },
  ]

  useEffect(() => {
    if (data?.modals?.pauseSubscription) {
      form.setFieldsValue({ pauseIndefinitely: null })
      setState((prevState) => ({ ...prevState, deliveryDate: null }))
    }
  }, [data?.modals?.pauseSubscription])

  const getMinimumDate = (presentDay: number, presentTime: number) => {
    const today = new Date()
    const nextSaturday = getNextSaturday(today)

    if (presentDay >= 3 && (presentDay > 3 || presentTime >= 9)) {
      return presentDay === 6 ? nextSaturday : getNextSaturday(nextSaturday)
    }

    return nextSaturday
  }

  const getNextSaturday = (date: Date) => {
    const day = date.getDay()
    const offset = day === 0 ? 7 : 7 - day

    date.setDate(date.getDate() + offset)

    return date
  }

  const presentDay = getDay(new Date())
  const presentTime = new Date().getHours()

  useEffect(() => {
    const minimumDate = getMinimumDate(presentDay, presentTime)
    setState((prevState) => ({ ...prevState, minDate: minimumDate }))
  }, [])

  const formattedDeliveryDate = state.deliveryDate ? format(state.deliveryDate, 'EEEE dd MMM yyyy') : ''
  const nextBillingDate = state.deliveryDate ? format(previousWednesday(state.deliveryDate), 'EEEE dd MMM yyyy') : ''

  return (
    <Modal
      title='Pause your subscription'
      open={data?.modals?.pauseSubscription}
      allowBackgroundClose={false}
      contentOverflow='initial'
      modalOverflow='initial'
      fullscreen={false}
      onClose={_handleModalClose} >
      <Container $isNativeApplication={isNativeApplication}>
        <Choose>
          <When condition={userCartLoading}>
            <SectionLoading />
          </When>
          <Otherwise>
            <Form form={form} onFieldsChange={_handleChange}>
              <Calendar>
                <Paragraph variant='p1' bold>Select your next delivery date:</Paragraph>
                <Spacer desktop='32px' variant='horizontal' />
                <Spacer mobile='16px' />
                <DatePicker
                  showIcon
                  popperPlacement='top'
                  ref={blurDatePickerRef}
                  isClearable
                  shouldCloseOnSelect={true}
                  placeholderText='yyyy/mm/dd'
                  dateFormat='yyyy/MM/dd'
                  minDate={state.minDate}
                  formatWeekDay={(nameOfDay: string) => (nameOfDay as unknown as string).substr(0, 1)}
                  selected={state.deliveryDate}
                  filterDate={isDeliveryDay}
                  onFocus={_handleBlurInput}
                  onChange={_handleDatePickerChange}
                />
              </Calendar>
              <Spacer universal='16px' />
              <If condition={!!state.deliveryDate}>
                <Notification
                  text={`Next billing date: ${nextBillingDate}`}
                  backgroundColor={theme.colors.misc.lightGreen}
                  textColor={theme.colors.greens.fruitSalad}
                  icon={IconEnum.CHECKMARK_CIRCLE_OUTLINE} />
                <Spacer universal='16px' />
                <Notification
                  text={`Next delivery date: ${formattedDeliveryDate}`}
                  backgroundColor={theme.colors.misc.lightGreen}
                  textColor={theme.colors.greens.fruitSalad}
                  icon={IconEnum.CHECKMARK_CIRCLE_OUTLINE} />
                <Spacer universal='16px' />
              </If>
              <CheckBox
                className='pause-indefinite'
                name='pauseIndefinitely'
                showLabel={false}
                showOptional={false}
                options={pauseIndefinitelyOptions} />
              <Spacer universal='16px' />
              <ActionContainer>
                <Button
                  loading={state.loading}
                  disabled={state.loading || !state.enableConfirm}
                  color='black'
                  fullWidth
                  title='CONFIRM'
                  onClick={_handlePauseSubscription} />
                <Spacer mobile='8px' desktop='16px' />
                <Link variant='l2' decoration='underline' onClick={_handleModalClose}> Cancel </Link>
              </ActionContainer>
            </Form>
          </Otherwise>
        </Choose>
      </Container>
    </Modal>
  )
}
