import React from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { Heading, Rule, Spacer, Button } from '@atoms/index'
import { useConfig } from '@client/contexts/ConfigProvider'
import { DashedButtonContainer, OrderItemTitleStyle, ResponsivePXValue, ZeroSpace } from '@components/Theme'
import { useUserCartQuery, CartItemFragment, UserMenuDishFragment } from '@hooks/api'
import { OrderAddOnItemCurrent, OrderBreakdown, OrderSubscriptionItemCurrent, SectionLoading } from '@molecules/index'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('padding', { mobile: '0 16px', tablet: ' 0 24px', desktop: '0 32px' })};

  .dashed-button {
    ${DashedButtonContainer()}
    width: auto;
  }

  .order-item-title{
    ${OrderItemTitleStyle}
  }
`

const HeaderContainer = styled.div`
  .heading {
    ${ZeroSpace}
    line-height: 1;
  }
`

export function CurrentOrderList(): JSX.Element {

  const config = useConfig()
  const { data: userCartData, loading: userCartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const navigate = useNavigate()
  const menu = userCartData?.currentUser?.activeMenu
  const activeCart = userCartData?.currentUser?.activeCart
  const cartItems = activeCart?.cartItems ? [...activeCart?.cartItems] : []

  cartItems.sort((a, b) => {
    if (a.isFromSubscription === b.isFromSubscription) {
      return a.createdAt > b.createdAt ? 1 : -1
    }
    return a.isFromSubscription > b.isFromSubscription ? 1 : -1
  })
    .filter((item) => {
      return item.isDisplayed && !item.isFromSubscription
    })

  const mealKitProduct = cartItems.filter((cartItem) => cartItem.product.__typename === 'MealKit')
  const addOnItems = cartItems.filter((cartItem) => !cartItem.isFromSubscription)
  const dishQuantityCount = menu?.dishes ? menu?.dishes?.length : 0

  const determineAddDishText = (): string => {
    switch (dishQuantityCount) {
      case 0:
        return 'ADD A DISH'
      case 1:
        return 'ADD A SECOND DISH'
      case 2:
        return 'ADD A THIRD DISH'
      case 3:
        return 'ADD A FOURTH DISH'
      default:
        return 'ADD A DISH'
    }
  }

  const getDeliveryFee = (): string => {
    const deliveryFee = userCartData?.currentUser?.activeCart.additions.find(addition => { return addition.id === 'DELIVERY' })
    if (deliveryFee) {
      return deliveryFee?.value === 0 ? 'Free' : 'R ' + deliveryFee?.value.toFixed(2)
    } else {
      return 'R 0.00'
    }
  }

  const getSubscriptionsTotal = (): string => {

    const mealKitProduct = (cartItems || []).filter(cartItem => cartItem.isFromSubscription && cartItem.isDisplayed)

    let mealKitCost = 0

    for (let i = 0; i < mealKitProduct.length; i++) {
      mealKitCost = mealKitProduct[i]?.price + mealKitCost
    }
    return 'R ' + mealKitCost.toFixed(2)
  }

  const getDiscount = (): string => {
    let value = 0
    for (let i = 0; i < userCartData?.currentUser?.activeCart.reductions.length; i++) {
      value += userCartData.currentUser.activeCart.reductions[i].value
    }
    return 'R ' + value.toFixed(2)
  }

  const getUserPoints = (): string => {
    return userCartData.currentUser.activeCart.assignedUserPoints.toFixed(2)
  }

  const getAddOnsTotal = (): string => {
    let value = 0
    for (let i = 0; i < addOnItems.length; i++) {
      value += addOnItems[i].price
    }
    return 'R ' + value.toFixed(2)
  }

  const navigateToPDP = (addOnItem: CartItemFragment): void => {
    if (addOnItem.product.__typename === 'Wine') {
      navigate(`/wine/${addOnItem.product.slug}`)
    } else if (addOnItem.product.__typename === 'FrozenMeal') {
      navigate(`/frozen/craft-meal/${addOnItem.product.group.slug}`)
    } else if (addOnItem.product.__typename === 'MealKit') {
      navigate(`/meal-kit/${addOnItem.product.group.slug}`)
    } else if (addOnItem.product.__typename === 'MarketProduct') {
      navigate(`/market/${addOnItem.product.slug}`)
    }
  }

  const navigateToDishPDP = (dish: UserMenuDishFragment): void => {
    navigate(`/meal-kit/${dish?.dish?.slug}`)
  }

  const _handleAddDishItem = (): void => {
    navigate('/meal-kit/')
  }

  const _handleAddCraftMealItem = (): void => {
    navigate('/frozen/craft-meal/')
  }

  const loading = userCartLoading
  let dishItem: UserMenuDishFragment
  let addOnItem: CartItemFragment
  let cartIndex: string
  let addOnIndex: string
  const addDishText = determineAddDishText()
  const currentOrders = [...menu?.dishes || []].reverse()

  return (
    <Choose>
      <When condition={loading}>
        <SectionLoading />
      </When>
      <When condition={mealKitProduct.length === 0 && addOnItems.length === 0}>
        <Container>
          <Spacer universal='16px' />
          <Button className='dashed-button' color='transparent' title='SHOP MEAL KIT' onClick={_handleAddDishItem} />
          <Spacer universal='16px' />
          <Button className='dashed-button' color='transparent' title='SHOP CRAFT MEALS' onClick={_handleAddCraftMealItem} />
          <Spacer universal='16px' />
        </Container>
      </When>
      <Otherwise>
        <Container>
          <If condition={menu?.dishes.length !== 0}>
            <HeaderContainer>
              <Heading className='heading' align='left' variant='h6'>Meal Kit Plan</Heading>
            </HeaderContainer>
            <Spacer universal='24px' />
            <For each='dishItem' index='cartIndex' of={currentOrders}>
              <OrderSubscriptionItemCurrent
                key={cartIndex}
                dish={dishItem}
                onNavigate={() => navigateToDishPDP(dishItem)}
                portionSize={menu?.numberOfPortions} />
            </For>
            <If condition={menu?.dishes.length < 4}>
              <Spacer universal='16px' />
              <Button className='dashed-button' color='transparent' title={addDishText} onClick={_handleAddDishItem} />
              <Spacer universal='8px' />
            </If>
            <Spacer universal='16px' />
            <Rule color='slate' />
          </If>
          <If condition={addOnItems.length > 0}>
            <Spacer universal='24px' />
            <HeaderContainer>
              <Heading className='heading' align='left' variant='h6'>{activeCart.totalSubscriptionItems === 0 ? 'On Demand' : 'Add-ons'}</Heading>
            </HeaderContainer>
            <Spacer universal='24px' />
            <For each='addOnItem' index='addOnIndex' of={addOnItems || []}>
              <OrderAddOnItemCurrent
                key={addOnIndex}
                addOnItem={addOnItem}
                onNavigate={() => navigateToPDP(addOnItem)} />
            </For>
            <Spacer universal='16px' />
            <Rule color='slate' />
          </If>
          <OrderBreakdown
            subscription={getSubscriptionsTotal()}
            addOn={getAddOnsTotal()}
            delivery={getDeliveryFee()}
            discount={getDiscount()}
            userPoints={getUserPoints()}
            total={'R ' + userCartData?.currentUser?.activeCart?.grandTotal.toFixed(2)} />
        </Container>
      </Otherwise>
    </Choose>
  )

}
