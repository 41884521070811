import React from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { CartPlugin } from '@api/local/CartPlugin'
import { ModalsPlugin, GlobalModalTypeEnum } from '@api/local/ModalsPlugin'
import { Button, Link, Paragraph } from '@client/components/atoms'
import { SectionLoading } from '@client/components/molecules'
import { DashedButtonContainer, ResponsivePXValue, theme } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import {
  useUserCartQuery, UserMenuDishFragment, RegisteredUserDetailsFragment, UserDetailsFragment,
  UserSubscriptionFragment, useUserDetailsQuery,
} from '@hooks/api'
import { ProductOrderTypeEnum, UserSubscriptionStatusEnum } from '@uctypes/api/globalTypes'

import { MealKitCartItem } from './MealKitCartItem'

const Container = styled.div`
  ${ResponsivePXValue('padding', '8px 0px')}
  max-width: 100%;

  .add-list-button {
    ${ResponsivePXValue('font-size', '10px')}
    border-width: 1px;
    ${ResponsivePXValue('margin', '8px 16px')}
    text-transform: uppercase;
  }

  .link {
    color: ${(props): string => props.theme.colors.slates.bitter};
  }

  .dashed-button {
    ${DashedButtonContainer(theme.colors.oranges.coral, theme.colors.oranges.burntSienna, 'rgba(250, 130, 70, 0.05)')}
    ${ResponsivePXValue('margin', '8px 16px')}
    width: auto;
  }
`

export function CartMealKit(): JSX.Element {

  const config = useConfig()
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const { data: cartData, loading: cartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const menu = cartData?.currentUser?.activeMenu
  const navigate = useNavigate()
  const loading = userDetailsLoading || cartLoading

  const registeredUser = userDetailsData?.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment
  const subscription: UserSubscriptionFragment = registeredUser?.subscriptions?.find((subscription) => subscription.id === cartData?.currentUser?.activeMenu?.subscription?.id)
  const isActiveSubscription = subscription?.status === UserSubscriptionStatusEnum.ACTIVE

  const getCartMarketProducts = () => {
    return cartData?.currentUser?.activeCart?.cartItems?.filter((item) => item.product?.__typename === 'MarketProduct' && item.product?.warehouses?.some((warehouse) => warehouse.orderType === ProductOrderTypeEnum.SUBSCRIPTION)) || []
  }

  const cartMarketProducts = getCartMarketProducts()

  let dishItem: UserMenuDishFragment

  const _openPauseModal = () => {
    if (isActiveSubscription) {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.PAUSE_SUBSCRIPTION, null, true)
    } else {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.PAUSE_SUBSCRIPTION)
    }
  }

  const openActionModal = () => {
    if (cartMarketProducts.length > 0) {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.NOTIFY_MARKET_PRODUCTS)
    } else {
      _openPauseModal()
    }
  }

  const _generateButtonText = (length: number): string => {
    switch (length) {
      case 0:
        return 'Add dishes to customize this week’s order'
      case 1:
        return 'Add 1 more dish to complete your order'
      default:
        return 'Add another dish'
    }
  }

  const _handleOnAddNewItem = (): void => {
    navigate('/meal-kit')
    CartPlugin.shared().close()
  }

  const _handlePauseSubscription = (): void => {
    openActionModal()
    CartPlugin.shared().close()
  }
  const mealKitCart = [...menu?.dishes || []].reverse()

  const dishLength = menu.dishes.length

  return (
    <Container>
      <Choose>
        <When condition={loading}>
          <SectionLoading loaderSize='24px' />
        </When>
        <Otherwise>
          <For each='dishItem' of={mealKitCart}>
            <MealKitCartItem key={dishItem.id} dish={dishItem} />
          </For>
          <If condition={menu?.dishes.length < 4}>
            <Button className='dashed-button' color='transparent' title={_generateButtonText(dishLength)} onClick={_handleOnAddNewItem} />
            <If condition={dishLength === 0}>
              <Paragraph
                variant='p2'
                color={theme.colors.slates.bitter}
                align='center'
                bold={false}>
                OR
              </Paragraph>
              <Paragraph
                variant='p2'
                color={theme.colors.slates.bitter}
                align='center'
                bold={false}>
                click <Link onClick={_handlePauseSubscription} variant='l2' className='link' bold>here</Link> to pause your subscription
              </Paragraph>
            </If>
          </If>
        </Otherwise>
      </Choose>
    </Container>
  )

}
