import React, { useState } from 'react'

import update from 'react-addons-update'
import styled, { CSS } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Heading, Icon, IconSizeEnum, IconEnum, Spacer } from '@atoms/index'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useUserDetailsQuery, useGetAppQuery, useMyShopFrozenItemsQuery } from '@hooks/api'
import { CraftMealCard, NavLinks, StoreStickyButton } from '@molecules/index'
import { ProductGrid } from '@organisms/stores/ProductGrid'
import { DeviceTypeEnum, ProductRangeEnum } from '@uctypes/api/globalTypes'
import { SearchEngineOptimization } from '@utility/SearchEngineOptimization'

import { ResponsivePXValue, ResponsiveProperty, LoadEffect } from '../Theme'
import { DeviceContainer } from '../utility'

import { getFrozenNavLinks } from './FrozenAisles'

const seo = {
  name: 'UCOOK Craft Meal Bought Before',
  title: 'Buy Craft Meal Online in South Africa | UCOOK Craft Meal',
  meta: [{
    name: 'description',
    content: 'Our markets are sourced from SA\'s best farms, and specially paired with each Meal Kit recipe so that you can enjoy a special dining experience at home.',
  }, {
    name: 'keywords',
    content: 'Buy Market Online,Market Online,Order Market Online',
  }, {
    name: 'robots',
    content: 'index,follow',
  }],
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  ${ResponsivePXValue('padding-top', { mobile: '16px', desktop: '32px' })}
  ${ResponsivePXValue('width', { mobile: '100%', desktop: '1136px' }, { desktop: true })}


  .button-container {
    margin: 0;
  }
`
const TopContainer = styled.div`
  display: flex;
  flex-direction: column;

 ${ResponsivePXValue('padding', { mobile: '0 16px', tablet: '0 16px' })}
`

const HeadingContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  ${ResponsiveProperty('justify-content', { mobile: 'space-between', tablet: 'flex-start', desktop: 'flex-start' })}
  ${ResponsivePXValue('height', { mobile: '44px', desktop: '64px' })}
`
const Grid = styled.div <{ $isNativeApplication: boolean, $isLoading: boolean }>`
  display: flex;
  gap: 16px;

  ${(props): CSS => props.$isLoading ? LoadEffect : undefined};

  ${ResponsiveProperty('flex-direction', { mobile: 'column', desktop: 'row' })}

  .craft-meal-filter { 
    ${ResponsivePXValue('padding', { mobile: '8px 0', tablet: '0 16px' })}
    ${ResponsivePXValue('width', { desktop: '272px' })}

    .filter-pill-container {
       ${ResponsivePXValue('left', { mobile: '36px' })}
    }
  }
`

const DishContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 16px;
  height: fit-content;
  ${ResponsivePXValue('justify-content', { mobile: 'center' })}

  .craft-meal-card {
    margin: 0;
  }
`

const IconContainer = styled.div`
  display: block;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

interface FrozenMyShopStoreState {
  displayEducationalBanner: boolean
}

const DEFAULT_STATE: FrozenMyShopStoreState = {
  displayEducationalBanner: false,
}

export function CraftMealMyShopStore(): JSX.Element {

  const [state, setState] = useState<FrozenMyShopStoreState>({ ...DEFAULT_STATE })
  const config = useConfig()
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isNativeApplication = appData.app.isNativeApplication
  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE

  const _handleBannerToggle = (): void => {
    setState((prevState) => update(prevState, {
      displayEducationalBanner: { $set: !prevState.displayEducationalBanner },
    }))
  }

  const boughtBeforeLink = [{
    title: 'Bought Before',
    url: '/frozen/bought-before',
    isActive: true,
  }]
  const navLinks = getFrozenNavLinks({
    additionalLinks: boughtBeforeLink,
  })

  const renderGridContent = () => {

    const productGridQuery = useMyShopFrozenItemsQuery
    const productsPerPage = isMobile ? 8 : 20

    const productGridVariables = {
      userId: userDetailsData?.currentUser?.id,
      input: {
        productType: ProductRangeEnum.FROZEN_MEAL,
      },
    }

    return (
      <ProductGrid
        productsPerPage={productsPerPage}
        queryVariables={productGridVariables}
        useProductQuery={productGridQuery}
        productType={ProductRangeEnum.FROZEN_MEAL}
        productCard={CraftMealCard as unknown as React.ReactElement}
        isBoughtBefore={true}
      />
    )
  }

  return (
    <Container>
      <SearchEngineOptimization seo={seo} />
      <StoreStickyButton className='button-container' isMobile={isMobile} />
      <TopContainer>
        <HeadingContainer>
          <Heading variant='h1'>Bought Before</Heading>
          <Spacer desktop='8px' variant='horizontal' />
          <IconContainer onClick={_handleBannerToggle}>
            <Icon icon={IconEnum.INFORMATION_CIRCLE} size={IconSizeEnum.SMALL} />
          </IconContainer>
        </HeadingContainer>
        <DeviceContainer $desktop>
          <NavLinks navLinks={navLinks} outline />
        </DeviceContainer>
        <Spacer desktop='24px' />
      </TopContainer>
      <Grid $isNativeApplication={isNativeApplication} $isLoading={false}>
        <Spacer mobile='4px' />
        <DishContainer id='productGrid'>
          {renderGridContent()}
        </DishContainer>
      </Grid>
      <Spacer universal='24px' />
    </Container>
  )
}
