import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import styled, { useTheme } from 'styled-components'

import { CartPlugin } from '@api/local/CartPlugin'
import { ModalsPlugin, GlobalModalTypeEnum } from '@api/local/ModalsPlugin'
import { Button, Heading, Paragraph, ResponsiveImage, Spacer } from '@atoms/index'
import { useConfig } from '@client/contexts/ConfigProvider'
import { ProductCardContainer, ProductCardContentContainer, ProductCardImageContainer } from '@components/Theme'
import { useEvents } from '@contexts/GTMProvider'
import {
  MarketProductListFragment, useAddItemToCartMutation, useChangeCartItemQuantityMutation, useRemoveItemFromCartMutation,
  useUserCartQuery, useUserDetailsQuery,
} from '@hooks/api'
import { ItemInterfaceNew } from '@lib/GTM'
import { Utilities } from '@lib/Utilities'
import { LowStockContainer, MarketMessageModal, ReactivateSubscriptionModal } from '@organisms/index'
import { ProductOrderTypeEnum, ProductAvailabilityEnum, ProductStockStatusEnum, Product } from '@uctypes/api/globalTypes'
import { useOutOfStockNotifier } from '@utility/index'

import { FloatingUpSell } from './FloatingUpSell'

const Container = styled.div`
  ${ProductCardContainer}
`

const ImageContainer = styled.div`
  ${ProductCardImageContainer}
`

const LinkContainer = styled.a`
  cursor: pointer;
  text-decoration: none;
`

const HeadingContainer = styled.div`
  height: 3em;
`

const ContentContainer = styled.div`
  ${ProductCardContentContainer}
`

export enum MarketProductModalEnum {
  SIGNUP = 'SIGNUP',
  UN_PAUSE = 'UN_PAUSE',
  MARKET = 'MARKET',
  NONE = 'NONE',
}

export interface MarketProductCardProps {
  marketProduct: MarketProductListFragment
  className?: string
  loading?: boolean
}

interface MarketProductCardState {
  activeModal: MarketProductModalEnum
  loading: boolean
  buttonTitle: string

  notAvailable: boolean

}

const DEFAULT_STATE: MarketProductCardState = {
  activeModal: MarketProductModalEnum.NONE,
  loading: false,
  buttonTitle: '',
  notAvailable: false,
}

export const _handleWeekendBoxDeliveryText = (): string => {

  const currentDate = new Date()
  const currentDayOfWeek = currentDate.getDay()
  const currentHour = currentDate.getHours()
  const daysUntilFriday = 5 - currentDayOfWeek
  let daysUntilNextNextFriday
  if (currentDayOfWeek === 1 || currentDayOfWeek === 2 || (currentDayOfWeek === 3 && currentHour < 9)) {
    daysUntilNextNextFriday = daysUntilFriday
  } else {
    daysUntilNextNextFriday = daysUntilFriday + 7
  }

  const nextNextFriday = new Date(currentDate)
  nextNextFriday.setDate(currentDate.getDate() + daysUntilNextNextFriday)
  const inputDateString = `${nextNextFriday.toDateString()}`
  const inputDate = new Date(inputDateString)
  const formattedDate = inputDate.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
  })
  return `${formattedDate}`
}

export const MarketProductCard = React.memo(({ marketProduct, loading: externalLoading, className }: MarketProductCardProps): JSX.Element => {

  const [state, setState] = useState<MarketProductCardState>(DEFAULT_STATE)
  const config = useConfig()
  const { addToast } = useToasts()
  const { data: userCartData, loading: cartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const [addItemToCart, { loading: attToCartLoading }] = useAddItemToCartMutation()
  const theme = useTheme()
  const navigate = useNavigate()
  const loading = externalLoading || cartLoading || attToCartLoading || state.loading
  const [changeCartItemQuantity] = useChangeCartItemQuantityMutation()
  const events = useEvents()
  const isWeekendBox = marketProduct.isWeekendBox
  const isOutOfStockItem = useOutOfStockNotifier()
  const checkoutStatus = userCartData?.currentUser?.checkoutStatus
  const [removeItemFromCart] = useRemoveItemFromCartMutation()
  const alwaysAvailable = marketProduct?.warehouses?.some((warehouse) => warehouse.orderType === ProductOrderTypeEnum.BOTH || warehouse.orderType === ProductOrderTypeEnum.ON_DEMAND)
  const isLoggedIn = userCartData?.currentUser?.checkoutStatus?.hasAccount
  const hasPausedSubscription = userCartData?.currentUser?.checkoutStatus?.hasPausedSubscription
  const hasDefaultAddress = userDetailsData?.currentUser?.hasDefaultAddress
  const productIsUnavailable = marketProduct.availability !== ProductAvailabilityEnum.AVAILABLE
  const marketProductIsOutOfStock = marketProduct.stockStatus === ProductStockStatusEnum.OUT_OF_STOCK

  const _handleAddProductToBox = async (): Promise<void> => {
    try {

      if (!alwaysAvailable && isOutOfStockItem(marketProduct as unknown as Product, marketProduct.quantityInCart, checkoutStatus.subscriptionIsSaved)) {
        return
      }

      setState((prevState) => update(prevState, { loading: { $set: true } }))

      await addItemToCart({
        variables: { productId: marketProduct.id, quantity: 1 },
      })

      const logData = {
        itemName: marketProduct?.name,
        itemId: marketProduct?.id,
        price: marketProduct?.price,
        itemBrand: 'UCOOK',
        itemCategory: marketProduct?.marketProductCategories?.map((cat) => cat.id)?.join(', '),
        itemVariant: marketProduct?.marketProductCategories?.map((cat) => cat.title)?.join(', '),
        itemListName: 'Market Product',
        quantity: 1,
        itemImage: marketProduct?.coverImage?.location,
        itemStockCount: marketProduct?.stockCount > 1 ? 1 : 0,
        itemChef: 'UCOOK',
        isMealkit: 'no',
        itemServingSize: 1,
      }

      const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew
      events.hasAddedToCart(snakedData)

      addToast('Item successfully added to cart', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    } finally {
      setState((prevState) => update(prevState, { loading: { $set: false } }))
    }
  }

  const _closeMarketModal = (): void => {
    setState((prevState) => update(prevState, { activeModal: { $set: MarketProductModalEnum.NONE } }))
  }
  const _handleToggleReactivateSubscriptionModal = (display: boolean): void => {
    const activeModal = display ? MarketProductModalEnum.UN_PAUSE : MarketProductModalEnum.NONE
    setState((prevState) => update(prevState, { activeModal: { $set: activeModal } }))
  }

  const _handleReactivateSubscription = () => {
    _handleAddProductToBox()
    CartPlugin.shared().open()
    _handleToggleReactivateSubscriptionModal(false)
  }
  const _handleOnRemove = async (): Promise<void> => {

    setState((prevState) => update(prevState, { loading: { $set: true } }))

    try {
      if (marketProduct.quantityInCart === 1) {

        await removeItemFromCart({
          variables: {
            productId: marketProduct.id,
          },
        })
        setState((prevState) => update(prevState, { currentAmountInCart: { $set: 0 } }))

      } else {
        await changeCartItemQuantity({
          variables: {
            productId: marketProduct.id,
            quantity: marketProduct.quantityInCart - 1,
          },
        })
      }

      const logData = {
        itemName: marketProduct?.name,
        itemId: marketProduct?.id,
        itemGroupId: marketProduct?.id,
        price: marketProduct?.price,
        itemBrand: 'UCOOK',
        itemCategory: marketProduct?.marketProductCategories?.map((cat) => cat.id)?.join(', '),
        itemVariant: marketProduct?.marketProductCategories?.map((cat) => cat.title)?.join(', '),
        itemListName: marketProduct?.subCategory?.title,
        quantity: 1,
        itemImage: marketProduct?.coverImage?.location,
        itemStockCount: marketProduct?.stockCount > 1 ? 1 : 0,
        itemChef: 'UCOOK',
        isMealkit: 'no',
        itemServingSize: 1,
      }

      const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew
      events.hasRemovedFromCart(snakedData)
      setState((prevState) => update(prevState, { loading: { $set: false } }))

      addToast('Item successfully removed from cart', {
        appearance: 'success',
        autoDismiss: true,
      })

    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }

  }

  const _handleOnCenterClick = async (): Promise<void> => {
    if (marketProduct.quantityInCart === 0) {
      _handleAddToCart()
    }
  }

  const pdpLink = `/market/${marketProduct?.slug}`
  const _handleLinkClicked = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    navigate(pdpLink)
  }

  const _handleAddToCart = async (): Promise<void> => {
    if (!isLoggedIn) {
    // When user is not logged in
      return alwaysAvailable
        ? _handleAddProductToBox()
        : setState((prevState) =>
          update(prevState, { activeModal: { $set: MarketProductModalEnum.MARKET } }),
        )
    }

    // When user is logged in
    if (alwaysAvailable) {
      return _handleAddProductToBox()
    }

    if (hasPausedSubscription) {
      return _handleToggleReactivateSubscriptionModal(true)
    }

    if (!hasDefaultAddress) {
      return ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.ADD_LOCATION)
    }

    if (productIsUnavailable) {
      return addToast('Product is unavailable', {
        appearance: 'error',
        autoDismiss: true,
      })
    }

    _handleAddProductToBox()
  }

  const _getButtonTitle = (): { buttonTitle: string, notAvailable: boolean } => {
    let buttonTitle = ''
    let notAvailable = false

    if (!isLoggedIn) {
      buttonTitle = alwaysAvailable ? 'ADD TO CART' : 'GET STARTED'
    } else {
      if (productIsUnavailable && !alwaysAvailable) {
        buttonTitle = 'GET STARTED'
      } else if (productIsUnavailable) {
        buttonTitle = 'NOT AVAILABLE'
        notAvailable = true
      } else if (marketProductIsOutOfStock) {
        buttonTitle = 'OUT OF STOCK'
        notAvailable = true
      } else {
        buttonTitle = alwaysAvailable ? 'ADD TO CART' : 'ADD TO ORDER'
      }
    }

    return { buttonTitle, notAvailable }
  }

  const _setButtonTitle = (): void => {
    const { buttonTitle, notAvailable } = _getButtonTitle()
    setState((prevState) => update(prevState, {
      buttonTitle: { $set: buttonTitle },
      notAvailable: { $set: notAvailable },
    }))
  }

  // const _handleAddToCart = async (): Promise<void> => {

  //   if (!isLoggedIn) {
  //     // When user is not logged in
  //     if (alwaysAvailable) {
  //       _handleAddProductToBox()
  //     } else {
  //       setState((prevState) => update(prevState, { activeModal: { $set: MarketProductModalEnum.MARKET } }))
  //     }
  //   } else {
  //     // When user is logged in
  //     if (alwaysAvailable) {
  //       _handleAddProductToBox()
  //     } else if (hasPausedSubscription) {
  //       _handleToggleReactivateSubscriptionModal(true)
  //     } else {
  //       if (!hasDefaultAddress) {
  //         ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.ADD_LOCATION)
  //       } else {
  //         if (productIsUnavailable) {
  //           addToast('Product is unavailable', {
  //             appearance: 'error',
  //             autoDismiss: true,
  //           })
  //         } else {
  //           _handleAddProductToBox()
  //         }
  //       }

  //     }
  //   }
  // }

  // let notAvailable = false

  // const setButtonTitle = () => {

  //   let buttonTitle

  //   if (!isLoggedIn) {
  //     if (alwaysAvailable) {
  //       buttonTitle = 'ADD TO CART'
  //     } else {
  //       buttonTitle = 'GET STARTED'
  //     }
  //   } else {
  //     if (productIsUnavailable && !alwaysAvailable) {
  //       buttonTitle = 'GET STARTED'
  //     } else if (productIsUnavailable) {
  //       buttonTitle = 'NOT AVAILABLE'
  //       notAvailable = true
  //     } else if (marketProductIsOutOfStock) {
  //       buttonTitle = 'OUT OF STOCK'
  //       notAvailable = true
  //     } else {
  //       buttonTitle = alwaysAvailable ? 'ADD TO CART' : 'ADD TO ORDER'
  //     }
  //   }

  //   setState((prevState) => update(prevState, {
  //     buttonTitle: { $set: buttonTitle },
  //     notAvailable: { $set: notAvailable },
  //   }))
  // }

  useEffect(() => {
    _setButtonTitle()
  }, [userCartData])

  return (
    <Container className={className}>
      <MarketMessageModal
        open={state.activeModal === MarketProductModalEnum.MARKET}
        title='Only available with Meal Kits'
        description={'Purchase market products with your Meal Kit subscription. Sign up to get everything you need to cook dinner, delivered to your door. To get started, check out this week\'s menu or, have a look at our plans page.'}
        primaryActionTitle='SEE THIS WEEKS MENU'
        primaryActionLink='/meal-kit'
        secondaryActionTitle='SEE PLANS & PRICING'
        secondaryActionLink='/meal-kit/plans'
        onClose={_closeMarketModal}
      />
      <ReactivateSubscriptionModal
        open={state.activeModal === MarketProductModalEnum.UN_PAUSE}
        onReactivation={_handleReactivateSubscription}
        onClose={() => { _handleToggleReactivateSubscriptionModal(false) }} />
      <If condition={marketProduct?.upSellText?.length > 0}>
        <FloatingUpSell text={marketProduct.upSellText} />
      </If>
      <If condition={isWeekendBox}>
        <Choose>
          <When condition={marketProduct?.upSellText?.length > 0}>
            <FloatingUpSell text={marketProduct.upSellText} />
          </When>
          <Otherwise>
            <FloatingUpSell text={'Delivered ' + _handleWeekendBoxDeliveryText()} />
          </Otherwise>
        </Choose>
      </If>
      <LinkContainer onClick={_handleLinkClicked}>
        <ImageContainer>
          <ResponsiveImage image={marketProduct.coverImage} />
        </ImageContainer>
      </LinkContainer>
      <ContentContainer className='fluid-grow'>
        <HeadingContainer>
          <Heading variant='h6' className='card-sub-title'>
            {marketProduct.name}
          </Heading>
        </HeadingContainer>

        <Paragraph bold variant='p2' color={theme.colors.whites.silver} className='card-title'>
          {marketProduct?.marketProductCategories?.map((cat) => cat.title).join(', ')}
        </Paragraph>
        <Spacer />
        <LowStockContainer product={marketProduct}>
          <Heading variant='h5' className='price'>
            R{marketProduct.price}
          </Heading>
        </LowStockContainer>
        <Spacer className='fluid-grow' universal='16px' />
        <Button
          className='add-button'
          title={state.buttonTitle}
          color='black'
          fullWidth
          loading={loading}
          disabled={loading || state.notAvailable}
          amount={marketProduct.quantityInCart}
          onClick={_handleOnCenterClick}
          onLeftIconClick={_handleOnRemove}
          onRightIconClick={_handleAddToCart} />
      </ContentContainer>
    </Container>
  )
})

MarketProductCard.displayName = 'MarketProductCard'
