import { DocumentNode, FieldPolicy, gql, makeVar } from '@apollo/client'

import update from 'react-addons-update'
import { v4 } from 'uuid'

import { theme } from '@components/Theme'
import { AppFragment, GlobalAppContextFragment, LocalTrackingDataFragment } from '@hooks/api'
import { ConfigPlugin } from '@lib/Config'
import { DeviceTypeEnum, NumberOfPortionsEnum } from '@uctypes/api/globalTypes'

export const APP_DEFAULT_STATE: AppFragment = {
  id: v4(),
  deviceType: DeviceTypeEnum.MOBILE,
  __typename: 'App',
  isNativeApplication: false,
  urlDiscount: null,
  hasInitiallySetTrackingData: false,
  isOneSignalLoggedIn: false,
  defaultPortions: NumberOfPortionsEnum.SERVES_2,
  globalAppContext: {
    __typename: 'GlobalAppContext',
    userId: '',
    hasPurchasedMarketProducts: false,
    hasPurchasedFrozenMeals: false,
  },
  trackingData: {
    __typename: 'LocalTrackingData',
    googleClickId: '',
    admarulaUTMTracker: '',
    facebookClickId: '',
    tikTokClickId: '',
    instagramClickId: '',
    referrerUrl: '',
    source: '',
    facebookBrowserId: '',
    googleSessionId: 0,
  },
}

const _data = makeVar<AppFragment>({ ...APP_DEFAULT_STATE })

export class AppPlugin implements ConfigPlugin {

  static instance: AppPlugin

  static shared(): AppPlugin {
    if (!this.instance) {
      this.instance = new AppPlugin()
    }
    return this.instance
  }

  setDeviceType(deviceType: DeviceTypeEnum): void {
    _data(update(_data(), {
      deviceType: {
        $set: deviceType,
      },
    }))
  }

  getDeviceType(): DeviceTypeEnum {
    return _data().deviceType
  }

  isMobile(): boolean {
    return _data().deviceType === DeviceTypeEnum.MOBILE
  }

  setIsNativeApplication(isNativeApplication: boolean): void {
    _data(update(_data(), {
      isNativeApplication: {
        $set: isNativeApplication,
      },
    }))
  }

  setUrlDiscount(urlDiscount: string | null): void {
    _data(update(_data(), {
      urlDiscount: {
        $set: urlDiscount,
      },
    }))
  }

  setGlobalAppContext(globalAppContext: GlobalAppContextFragment): void {
    _data(update(_data(), {
      globalAppContext: {
        $set: globalAppContext,
      },
    }))
  }

  setTrackingData(trackingData: LocalTrackingDataFragment): void {
    _data(update(_data(), {
      trackingData: {
        $set: trackingData,
      },
    }))
  }

  setHasInitiallySetTrackingData(hasInitiallySetTrackingData: boolean): void {
    _data(update(_data(), {
      hasInitiallySetTrackingData: {
        $set: hasInitiallySetTrackingData,
      },
    }))
  }

  setIsOneSignalLoggedIn(isOneSignalLoggedIn: boolean): void {
    _data(update(_data(), {
      isOneSignalLoggedIn: {
        $set: isOneSignalLoggedIn,
      },
    }))
  }

  setDefaultPortions(defaultPortions: NumberOfPortionsEnum): void {
    _data(update(_data(), {
      defaultPortions: {
        $set: defaultPortions,
      },
    }))
  }

  async configure(): Promise<void> {
    let deviceType = DeviceTypeEnum.MOBILE
    if (theme.isUltra()) {
      deviceType = DeviceTypeEnum.ULTRA
    } else if (theme.isDesktop()) {
      deviceType = DeviceTypeEnum.DESKTOP
    } else if (theme.isTablet()) {
      deviceType = DeviceTypeEnum.TABLET
    }
    this.setDeviceType(deviceType)
  }

  fieldPolicies = (): { [k: string]: FieldPolicy } => ({
    app: {
      read(): AppFragment {
        const data = _data()
        return data as AppFragment
      },
    },
  })

  types = (): DocumentNode => gql`
    scalar JSONObject
    enum DeviceTypeEnum {
      "Mobile"
      MOBILE
      "Tablet"
      TABLET
      "Desktop"
      DESKTOP
      "Ultra"
      ULTRA
    }
    enum NumberOfPortionsEnum {
      "SERVES_1" 
      SERVES_1
      "SERVES_2"
      SERVES_2
      "SERVES_3" 
      SERVES_3
      "SERVES_4"
      SERVES_4
    }

    type GlobalAppContext {
      userId: ID
      hasPurchasedMarketProducts: Boolean
      hasPurchasedFrozenMeals: Boolean
    }

    type LocalTrackingData {
      googleSessionId: Int
      googleClickId: String
      facebookBrowserId: String
      facebookClickId: String
      instagramClickId: String
      tikTokClickId: String
      admarulaUTMTracker: String
      referrerUrl: String
      source: String
    }

    type App {
      id: ID!
      deviceType: DeviceTypeEnum!
      isNativeApplication: Boolean!
      urlDiscount: String
      globalAppContext: GlobalAppContext 
      trackingData: LocalTrackingData
      hasInitiallySetTrackingData: Boolean
      isOneSignalLoggedIn: Boolean
      defaultPortions: NumberOfPortionsEnum!
    } 
  `

  extensions = (): DocumentNode => gql`
    extend type Query {
      app: App!
    }
  `

  queries = (): DocumentNode => gql`

    
    fragment GlobalAppContextFragment on GlobalAppContext {
      userId
      hasPurchasedMarketProducts
      hasPurchasedFrozenMeals
    }

    fragment LocalTrackingDataFragment on LocalTrackingData {
      googleClickId
      admarulaUTMTracker
      facebookClickId
      tikTokClickId
      instagramClickId
      referrerUrl
      source
      facebookBrowserId
      googleSessionId
    }
    fragment AppFragment on App {
      id
      deviceType
      isNativeApplication
      urlDiscount
      globalAppContext {
        ...GlobalAppContextFragment
      }
      trackingData {
        ...LocalTrackingDataFragment
      }
      hasInitiallySetTrackingData
      isOneSignalLoggedIn
      defaultPortions
    }
    query GetApp {
      app @client {
        ... AppFragment
      }
    }
  `

}
