import React from 'react'

import styled from 'styled-components'

import { Spacer } from '@client/components/atoms'
import { Aisles, MarketProductCard } from '@client/components/molecules'
import { AislesBaseContainer } from '@client/components/Theme'
import { MarketProductListFragment, PageDataSectionFragment } from '@hooks/api'

const AislesContainer = styled.div`
  ${AislesBaseContainer}
`

interface PageDataFilteredMarketProductProps {
  pageSection: PageDataSectionFragment
}

export function PageDataFilteredMarketProduct({ pageSection }: PageDataFilteredMarketProductProps): JSX.Element {

  const viewAllUrl = pageSection?.viewAllUrl || `/market/store/${pageSection?.marketProductCategories[0]?.slug}`

  let marketProduct: MarketProductListFragment

  return (
    <AislesContainer>
      <Aisles title={pageSection?.title} viewAllUrl={viewAllUrl} displayViewAllButton={pageSection?.displayViewAllButton} description={pageSection?.description} className='aisles-filtered-data'>
        <For each='marketProduct' of={pageSection?.marketProducts || []}>
          <MarketProductCard
            className='product-card'
            key={marketProduct.id}
            marketProduct={marketProduct} />
        </For>
      </Aisles>
      <Spacer universal='16px' />
    </AislesContainer>
  )

}
