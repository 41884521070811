import { subWeeks, startOfWeek, endOfWeek, format } from 'date-fns'
import Cookies from 'js-cookie'

import { useConfig } from '@client/contexts/ConfigProvider'
import { OrderDirectionEnum, OrderOrderEnum, OrderOriginEnum, OrderStatusEnum, OrderTypeEnum } from '@uctypes/api/globalTypes'

import { OrderItemFragment, OrdersQuery, useOrdersQuery } from './api'

interface ReturnType {
  mealKitRatingModalOpen: boolean
  mealKitRatingOrder: OrdersQuery['orders']['list'][0] | null
}

export const useMealKitRatingsLogic = (userId: string, isRegistered: boolean): ReturnType => {
  const config = useConfig()
  const isRatingsDismissed = Cookies.get('isDismissed')

  const getTargetWeekRange = (currentDate: Date) => {
    const now = currentDate || new Date()
    const targetDate = subWeeks(now, 2)
    return {
      fromDate: format(startOfWeek(targetDate, { weekStartsOn: 1 }), 'yyyy-MM-dd'), // e.g., "2025-03-03"
      toDate: format(endOfWeek(targetDate, { weekStartsOn: 1 }), 'yyyy-MM-dd'), // e.g., "2025-03-09"
    }
  }

  const getLast28DaysRange = (currentDate: Date) => {
    const now = currentDate || new Date()
    return {
      fromDate: format(subWeeks(now, 4), 'yyyy-MM-dd'),
      toDate: format(now, 'yyyy-MM-dd'),
    }
  }

  const { data: ordersData } = useOrdersQuery({
    skip: (!userId || !!isRatingsDismissed || !isRegistered),
    ssr: config.fetchSSRQuery(),
    variables: {
      filters: {
        users: [userId],
        status: [OrderStatusEnum.COMPLETE, OrderStatusEnum.PROCESSING],
        origin: [OrderOriginEnum.NODE],
        orderTypes: [OrderTypeEnum.SUBSCRIPTION],
        dispatchDateRange: getLast28DaysRange(new Date()), // Fetch last 28 days
      },
      order: {
        field: OrderOrderEnum.DISPATCH_DATE,
        direction: OrderDirectionEnum.DESC,
      },
      skip: 0,
      limit: 5,
    },
  })

  if (ordersData?.orders?.list.length > 0) {
    const targetRange = getTargetWeekRange(new Date())
    const targetStart = new Date(targetRange.fromDate).getTime()
    const targetEnd = new Date(targetRange.toDate).getTime()
    const twoWeeksAgoEnd = new Date(targetRange.toDate).getTime()

    let targetOrder = null
    let fallbackOrder = null

    for (const order of ordersData.orders.list) {
      const dispatchDate = new Date(order.dispatchDate).getTime()
      const todaysDate = new Date().getTime()
      const dayDiff = (todaysDate - dispatchDate) / (1000 * 60 * 60 * 24)

      if (dispatchDate >= targetStart && dispatchDate <= targetEnd) {
        targetOrder = order
        break
      }
      if (dispatchDate <= twoWeeksAgoEnd && (!fallbackOrder || dispatchDate > new Date(fallbackOrder.dispatchDate).getTime())) {
        fallbackOrder = order
      }
    }

    const selectedOrder = targetOrder || fallbackOrder
    if (!selectedOrder) {
      return {
        mealKitRatingModalOpen: false,
        mealKitRatingOrder: null,
      }
    }

    const isMealKit = (orderItem: OrderItemFragment): boolean => {
      return orderItem?.product?.__typename === 'MealKit' && orderItem?.product?.name !== 'Fixed Subscription Fee'
    }
    const deliveryDay = selectedOrder.dispatchDate.slice(0, 10)
    const dispatchDate = new Date(deliveryDay).getTime()
    const todaysDate = new Date().getTime()
    const dayDiff = (todaysDate - dispatchDate) / (1000 * 60 * 60 * 24)
    const isSub = selectedOrder.orderType
    const amountPaid = selectedOrder.amountPaid
    const orderItemsMealKits = selectedOrder.orderItems?.filter(isMealKit) || []
    let hasRatedAllMK = true
    for (const item of orderItemsMealKits) {
      if (!item.orderItemRating) {
        hasRatedAllMK = false
        break
      }
    }

    if (
      isSub === 'SUBSCRIPTION' &&
      !isRatingsDismissed &&
      dayDiff > 4 &&
      dayDiff < 28 &&
      amountPaid !== 0 &&
      !hasRatedAllMK &&
      orderItemsMealKits.length > 0
    ) {
      return {
        mealKitRatingModalOpen: true,
        mealKitRatingOrder: selectedOrder,
      }
    }
  }

  return {
    mealKitRatingModalOpen: false,
    mealKitRatingOrder: null,
  }
}
