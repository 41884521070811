import React, { useEffect } from 'react'

import { useNavigate } from 'react-router'
import styled, { CSS } from 'styled-components'

import { IconEnum } from '@atoms/index'
import { useConfig } from '@client/contexts/ConfigProvider'
import { Button, CraftDessertMealDishDetails, CraftDessertMealDishDetailsProps, CraftDessertYouMightAlsoLike, MoreAboutDessertMeal, MoreAboutKidsMealProps } from '@components/index'
import { PaddedSectionContainer, ResponsivePXValue, SidePagePadding, theme } from '@components/Theme'
import { useEvents } from '@contexts/GTMProvider'
import { useUserDetailsQuery, FrozenInstructionFragment, FrozenMealDishDetailsFragment, FrozenMealListFragment } from '@hooks/api'
import { ItemInterfaceNew } from '@lib/GTM'
import { Utilities } from '@lib/Utilities'
import { TextListItem, TextListProps } from '@molecules/index'
import { FrozenPortionSizeEnum } from '@uctypes/api/globalTypes'

export interface NewGA4Product extends GA4Product {
  item_image?: string
  item_stock_count?: number
  item_chef?: string
  is_mealkit?: string
}
const BackButtonContainer = styled.div`
 display: flex;
 ${ResponsivePXValue('margin-bottom', { mobile: '24px', tablet: '28px', desktop: '36px' })}
 ${SidePagePadding}
`
const GhostButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  ${ResponsivePXValue('padding-bottom', { mobile: '16px' })}
`

const SectionContainer = styled.div<{ $backgroundColor?: string, $customBackground?: string, $padded: boolean }>`
  ${(props): CSS => PaddedSectionContainer(props.$backgroundColor, props.$customBackground, props.$padded)};
`

export interface CraftDessertPDP {
  dish: FrozenMealDishDetailsFragment
  loading: boolean
}

export function CraftDessertPDP({ dish, loading }: CraftDessertPDP): JSX.Element {

  const navigate = useNavigate()
  const events = useEvents()
  const config = useConfig()

  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })

  const productList = dish?.products
  const finalAllergens = `${dish?.allergens.map((allergen) => { return allergen.title }).join(', ')}. Packed in a facility that handles all major food allergens and gluten, including Milk, Eggs, Fish,
                          Shellfish, Tree Nuts, Peanuts, Wheat, Soybeans.`
  const price = dish?.products?.find((product) => { return product.frozenPortionSize === FrozenPortionSizeEnum.SERVES_ONE })?.price
  const stock = dish?.products?.find((product) => { return product.frozenPortionSize === FrozenPortionSizeEnum.SERVES_ONE })?.stockCount
  const dishMealsFilter = (meal: FrozenMealListFragment): boolean => dish?.products?.some(product => product.id === meal.id)
  const dishMealsData: (FrozenMealListFragment)[] = productList?.filter(dishMealsFilter)
  const servingMeal = dishMealsData?.find((meal) => { return meal.id === dish?.products?.find((product) => { return product.frozenPortionSize === FrozenPortionSizeEnum.SERVES_ONE })?.id })

  useEffect(() => {
    if (!loading) {
      const logArr = [] as ItemInterfaceNew[]

      const logData = {
        itemName: dish?.name,
        itemId: dish?.id,
        price,
        itemBrand: 'UCOOK',
        itemCategory: 'Craft Dessert Meals',
        itemVariant: dish?.frozenCategories.map((cat) => cat.title).join(', '),
        itemListName: 'Craft Dessert Meals',
        itemImage: dish?.coverImage?.location,
        itemStockCount: stock> 1 ? 1 : 0,
        itemChef: dish?.chef?.name,
        isMealkit: 'no',
      }
      const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew

      logArr.push(snakedData)

      events.hasViewedMultipleProducts('Craft Dessert', logArr, userDetailsData?.currentUser?.id)
      events.hasSelectedMultipleItems('Craft Dessert', logArr, userDetailsData?.currentUser?.id)
    }
  }, [loading])

  const dishDetailsData: CraftDessertMealDishDetailsProps = {
    isDessert: true,
    dish,
    allergens: finalAllergens,
  }

  const getCookingInstructions = (meal: FrozenMealListFragment): TextListItem[] => {
    return meal?.frozenInstructions?.map((instruction: FrozenInstructionFragment) => ({
      title: instruction.title,
      subTitle: instruction?.description || null,
      image: instruction?.image || null,
    }))
  }

  const heatingInstructions: TextListProps = {
    items: getCookingInstructions(servingMeal),
  }

  const moreAboutKidsMealData: MoreAboutKidsMealProps = {
    isDessert: true,
    chef: {
      name: dish?.chef?.name,
      about: dish?.story,
      image: dish?.coverImage,
    },
    ingredients: {
      ingredients: dish?.ingredients,
      nutritionalInfo: {
        nutrition: dish?.nutrition,
      },
      allergens: finalAllergens,
    },
    ...(servingMeal?.frozenInstructions?.length > 0 && { heatingInstructions }),
  }

  const _handleBackClicked = (): void => {
    navigate(-1)
  }

  return (
    <>
      <SectionContainer $padded={false}>
        <BackButtonContainer>
          <Button
            title='BACK'
            variant='secondary'
            displayBorder={false}
            color='grey'
            leftIcon={IconEnum.CHEVRON_BACK_OUTLINE}
            iconColor={theme.colors.oranges.coral}
            loading={loading}
            onClick={_handleBackClicked} />
        </BackButtonContainer>
        <CraftDessertMealDishDetails {...dishDetailsData} />
      </SectionContainer>
      <SectionContainer $backgroundColor='grey' $padded>
        <MoreAboutDessertMeal {...moreAboutKidsMealData} />
      </SectionContainer>
      <SectionContainer $padded>
        <CraftDessertYouMightAlsoLike dish={dish} />
        <GhostButtonContainer>
          <Button variant='secondary' title='BACK TO THE MENU' color='grey' onClick={_handleBackClicked} />
        </GhostButtonContainer>
      </SectionContainer>
    </>
  )

}
