import React, { useEffect, useState } from 'react'

import Cookies from 'js-cookie'
import qs from 'qs'
import update from 'react-addons-update'
import { useLocation, useNavigate, useParams } from 'react-router'
import styled, { CSS } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Heading, Icon, IconSizeEnum, IconEnum, Spacer } from '@atoms/index'
import { useConfig } from '@client/contexts/ConfigProvider'
import {
  useUserDetailsQuery, useGetAllWineCategoriesQuery, useGetAppQuery, useGetAllWinesQuery,
  ProductAggregationSectionFragment,
} from '@hooks/api'
import {
  EducationalBanner, Filter, FilterSectionProps, NavLink, NavLinks, SelectedFilters,
  StoreStickyButton, WineDiscount,
} from '@molecules/index'
import { ProductGrid } from '@organisms/stores/ProductGrid'
import {
  WineFilters, DeviceTypeEnum, ProductStatusEnum, WineOrderEnum, OrderDirectionEnum,
  WineCategoryStatusEnum, ProductRangeEnum,
} from '@uctypes/api/globalTypes'
import { Mutable } from '@uctypes/global'
import { SearchEngineOptimization } from '@utility/SearchEngineOptimization'

import { WineCard } from '../molecules/stores/WineCard'
import { ResponsivePXValue, ResponsiveProperty, LoadEffect } from '../Theme'
import { DeviceContainer } from '../utility'

const seo = {
  name: 'UCOOK Wines',
  title: 'Buy Wine Online in South Africa | UCOOK Wine',
  meta: [{
    name: 'description',
    content: 'Our wines are sourced from SA\'s best farms, and specially paired with each Meal Kit recipe so that you can enjoy a special dining experience at home.',
  }, {
    name: 'keywords',
    content: 'Buy Wine Online,Wine Online,Order Wine Online',
  }, {
    name: 'robots',
    content: 'index,follow',
  }],
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  ${ResponsivePXValue('padding-top', { mobile: '16px', desktop: '32px' })}
  ${ResponsivePXValue('width', { mobile: '100%', desktop: '1136px' }, { desktop: true })}

  .button-container {
    margin: 0;
  }
`
const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const TopContainer = styled.div`
  display: flex;
  flex-direction: column;

 ${ResponsivePXValue('padding', { mobile: '0 16px', tablet: '0 16px' })}
`

const HeadingContainer = styled.div`
  display: flex;

  ${ResponsivePXValue('height', { mobile: '44px', desktop: '64px' })}
  ${ResponsiveProperty('justify-content', { mobile: 'space-between', tablet: 'space-between', desktop: 'flex-start' })}
`
const Grid = styled.div <{ $isLoading: boolean }>`
  display: flex;
  gap: 16px;

  ${(props): CSS => props.$isLoading ? LoadEffect : undefined};

  ${ResponsiveProperty('flex-direction', { mobile: 'column', desktop: 'row' })}
  ${ResponsivePXValue('gap', { mobile: '16px', desktop: '16px' })}

  .wine-filter {
    ${ResponsivePXValue('padding', { mobile: '8px 0 8px 16px' })}
    ${ResponsivePXValue('width', { desktop: '272px' })}

    .filter-pill-container {
       ${ResponsivePXValue('left', { mobile: '36px' })}
    }
  }
`

const DishContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 16px;
  height: fit-content;
  ${ResponsivePXValue('justify-content', { mobile: 'center' })}

  .wine-product-card {
    margin: 0;
  }
`
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
interface WineStoreState {
  filters: WineFilters
  skip: number
  limit: number
  displayEducationalBanner: boolean
  hasLoggedImpressions: boolean
  aggregations?: ProductAggregationSectionFragment[]
}

const DEFAULT_STATE: WineStoreState = {
  filters: {},
  skip: 0,
  limit: 20,
  displayEducationalBanner: false,
  hasLoggedImpressions: false,
  aggregations: [],
}

export function WineStore(): JSX.Element {

  const { slug } = useParams<{ slug: string }>()
  const [state, setState] = useState<WineStoreState>({ ...DEFAULT_STATE })
  const config = useConfig()
  const navigate = useNavigate()
  const location = useLocation()
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const { data: wineCategoriesData } = useGetAllWineCategoriesQuery({ variables: { filters: { status: WineCategoryStatusEnum.ACTIVE } } })
  const wineCategories = wineCategoriesData?.wineCategories?.list || []
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()

  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE

  const wineAisleActiveCategory = wineCategories.find(wineCategory => wineCategory.slug === slug)?.id

  const params = qs.parse(location.search.replace('?', ''))
  const filters = params?.filters as { [k: string]: string[] } || {}
  const cityId = userDetailsData?.currentUser?.addresses.find((address) => address.isDefault)?.location?.city?.id
  const wineFilters: Mutable<WineFilters> = {
    cities: cityId ? [cityId] : undefined,
    ...filters,
    // wineCategories: wineAisleActiveCategory ? [wineAisleActiveCategory] : undefined,
    wineCategorySlugs: [slug],
    campaignOnly: true,
    status: [ProductStatusEnum.PUBLISHED],
  }

  const variables = {
    filters: wineFilters,
    skip: state.skip,
    limit: state.limit,
    order: [{
      field: WineOrderEnum.DISPLAY_INDEX,
      direction: OrderDirectionEnum.ASC,
    }],
  }

  const wineNavLinks: NavLink[] = wineCategories.filter(wineCategory => wineCategory.status === WineCategoryStatusEnum.ACTIVE).map(wineCategory => {
    return {
      title: wineCategory.title,
      url: `/wine/store/${wineCategory.slug}`,
      isActive: wineAisleActiveCategory === wineCategory.id,
    }
  }) || []

  const navLinks = [{
    title: 'All',
    url: '/wine',
    isActive: false,
  },
  ...wineNavLinks,
  ]

  const _handleFiltersChange = (selected: SelectedFilters): void => {
    setState((prevState) => update(prevState, {
      filters: { $set: selected },
      skip: { $set: 0 },
      hasLoggedImpressions: { $set: false },
    }))
    const newQueryString = qs.stringify({ filters: selected })

    navigate(`${location.pathname}?${newQueryString}`)
  }

  const _handleFiltersClear = (): void => {
    setState((prevState) => update(prevState, {
      filters: { $set: {} },
      skip: { $set: 0 },
      hasLoggedImpressions: { $set: false },
    }))
    navigate(location.pathname)
  }

  const _handleBannerToggle = (): void => {
    setState((prevState) => update(prevState, {
      displayEducationalBanner: { $set: !prevState.displayEducationalBanner },
    }))
  }

  const _handleOnClose = (): void => {
    setState((prevState) => update(prevState, {
      displayEducationalBanner: { $set: false },
    }))
  }
  const _handleNavClicked = (): void => {
    setState((prevState) => update(prevState, {
      hasLoggedImpressions: { $set: false },
    }))
  }

  useEffect(() => {
    if (!Cookies.get('displayEducationalBanner')) {
      setState((prevState) => update(prevState, {
        displayEducationalBanner: { $set: true },
      }))
      Cookies.set('displayEducationalBanner', '0')
    }
  }, [])

  const _handlePageLoaded = (aggregations: ProductAggregationSectionFragment[]): void => {
    setState((prevState) => update(prevState, {
      aggregations: { $set: aggregations },
    }))
  }

  const renderGridContent = () => {

    const productGridQuery = useGetAllWinesQuery
    const productsPerPage = isMobile ? 8 : 18
    const productGridVariables = {
      ...variables,
    }

    return (
      <ProductGrid
        productsPerPage={productsPerPage}
        queryVariables={productGridVariables}
        useProductQuery={productGridQuery}
        productType={ProductRangeEnum.WINE}
        productCard={WineCard as unknown as React.ReactElement}
        onLoaded={_handlePageLoaded}
      />
    )
  }

  const sections: FilterSectionProps[] = state.aggregations.map((aggregation) => ({
    id: aggregation.filterKey,
    title: aggregation.title,
    filterKey: aggregation.filterKey,
    items: aggregation.items.map((item) => ({
      id: item.id,
      title: item.name,
      quantity: 0,
    })),
  }))

  return (
    <Container>
      <SearchEngineOptimization seo={seo} />
      <WineDiscount />
      <StoreStickyButton className='button-container' isMobile={isMobile} />
      <TopContainer>
        <RowContainer>
          <HeadingContainer>
            <Heading variant='h1'>Wine</Heading>
            <Spacer desktop='8px' variant='horizontal' />
            <IconContainer onClick={_handleBannerToggle}>
              <Icon icon={IconEnum.INFORMATION_CIRCLE} size={IconSizeEnum.SMALL} />
            </IconContainer>
          </HeadingContainer>
        </RowContainer>
        <DeviceContainer $desktop>
          <NavLinks onNavClick={_handleNavClicked} outline navLinks={navLinks} />
        </DeviceContainer>
        <If condition={state.displayEducationalBanner}>
          <Spacer universal='16px' />
          <EducationalBanner
            title='Welcome to Wine!'
            description={'Make dinner memorable with a perfectly paired wine, or find new varietals from top farms to stock your cellar with. With over 60 new wines from 12 different farms, find your new favourites at cellar door prices.'}
            onClose={_handleOnClose} />
          <Spacer mobile='16px' />
        </If>
        <Spacer desktop='24px' />
      </TopContainer>
      <Grid $isLoading={false}>
        <Filter
          className='wine-filter'
          filters={filters as unknown as { [k: string]: string[] }}
          sections={sections}
          onChange={_handleFiltersChange}
          onClear={_handleFiltersClear}
          slides={<NavLinks outline navLinks={navLinks} />}
        />
        <Spacer mobile='24px' />
        <DishContainer id='productGrid'>
          {renderGridContent()}
        </DishContainer>
      </Grid>
      <Spacer universal='24px' />
    </Container>
  )
}
