import 'react-datepicker/dist/react-datepicker.css'
import React from 'react'

import { loadableReady } from '@loadable/component'
import { createRoot, hydrateRoot } from 'react-dom/client'
import OneSignal from 'react-onesignal'
import { BrowserRouter } from 'react-router-dom'
import { TagManager, TagInterface } from 'sih-gtm/build/TagManager'
import * as Sentry from '@sentry/react'
import { NavigationPlugin } from '@api/local/NavigationPlugin'
import { Config } from '@lib/Config'

import { App } from './App'
import { configure } from './configure'
import { defaultNavigation } from './Navigation'
import { UserEvents } from '@lib/UserEvents'
import { PostHogProvider} from 'posthog-js/react'

(async (): Promise<void> => {
  const config = new Config()

  const oneSignalArgs = {
    appId: '1be016ae-e363-493f-9b41-d736184fd2ba',
    serviceWorkerParam: { scope: '/workers/one-signal/' },
    serviceWorkerPath: 'workers/one-signal/worker.js',
    safari_web_id: 'web.onesignal.auto.560021c2-877c-448b-9811-f001e7b5ec58',
    promptOptions: {
      slidedown: {
        prompts: [
          {
            type: 'push', 
            autoPrompt: false,
            text: {
              actionMessage: "We'd like to send you notifications. This may include alerts, reminders and important info.",
              acceptButton: 'Allow',
              cancelButton: 'Cancel',
            },
            delay: {
              pageViews: 0,
              timeDelay: 5,
            },
          },
        ],
      },
    },
  }

  const environment = window.env.REACT_APP_ENVIRONMENT_NAME
  const sentryDSN = window.env.REACT_APP_SENTRY_DSN
  if (sentryDSN && environment) {
    const sentryArgs = {
      dsn: sentryDSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      environment:environment,
      tracesSampleRate: 0.5,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 0.1,
  
    }
    Sentry.init(sentryArgs)
  }
  const postHogHost = window.env.REACT_APP_PUBLIC_POSTHOG_HOST
  const postHogKey = window.env.REACT_APP_PUBLIC_POSTHOG_KEY
  const postHogOptions = {
    api_host: postHogHost,
  }

  if (config.isBrowser() && config.isProductionDomain()) {
    OneSignal.init(oneSignalArgs)
  }

  const events = new UserEvents()
  await configure(config, events)
  const client = await config.getClient()

  NavigationPlugin.shared().setNavigation(defaultNavigation)

  if (config.ssr()) {
    loadableReady(() => {
      hydrateRoot(
        document.getElementById('react-app'),
        <BrowserRouter>
          <PostHogProvider 
            apiKey={postHogKey}
            options={postHogOptions}
          >
          <App client={client} config={config} events={events} />
          </PostHogProvider>
        </BrowserRouter>,
      )
    })
  } else {
    const root = createRoot(document.getElementById('react-app'))
    loadableReady(() => {
      root.render(
        <BrowserRouter>
          <PostHogProvider 
            apiKey={postHogKey}
            options={postHogOptions}
          >
          <App client={client} config={config} events={events}/>
          </PostHogProvider>
        </BrowserRouter>,
      )
    })
  }
})()
