import React, { useEffect, Fragment, useState } from 'react'

import { format } from 'date-fns'
import styled from 'styled-components'

import { ContentCard, ContentCardHeaderEnum, DeviceContainer, Heading, ResponsivePXValue, Rule, TableCell, TableRow, TabPane, Tabs, Paragraph, theme } from '@client/components'
import { Link } from '@client/components/atoms'
import { FriendReferenceFragment, GetAllFriendReferencesQuery, UserDetailsQuery } from '@hooks/api'
import { UserReferralStatusEnum } from '@uctypes/api/globalTypes'

enum TabEnum {
  PENDING = 'Pending referrals',
  PROCESSING = 'Processing referrals',
  SUCCESSFUL = 'Successful referrals',
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  ${ResponsivePXValue('width', 'CALC(100% - 48px)')}
  ${ResponsivePXValue('padding', '2px 24px 0')}

  .text {
    margin: 0px
  }
  .mobile-row-item {
    ${ResponsivePXValue('margin', '2px 0')}
  }

  .mobile-tab{
    ${ResponsivePXValue('font-size', '12px')};
    ${ResponsivePXValue('padding', '12px')};
    line-height: 1.7;
    justify-content: center;
  }

`

const ReferralRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;

`

const ReferralItem1 = styled.div`
  display: flex;
  justify-content: flex-start;
  ${ResponsivePXValue('width', 'CALC(10% - 12px)')}
  ${ResponsivePXValue('padding', '16px 0 16px 12px')}

`

const ReferralItem2 = styled.div`
  display: flex;
  justify-content: flex-start;
  ${ResponsivePXValue('width', 'CALC(45% - 12px)')}
  ${ResponsivePXValue('padding', '16px 12px 16px 0')}

`

const ReferralItem3 = styled.div`
  display: flex;
  justify-content: center;
  ${ResponsivePXValue('width', 'CALC(20% - 24px)')}
  ${ResponsivePXValue('padding', '16px 12px')}

`
const ReferralItem4 = styled.div`
  display: flex;
  justify-content: flex-end;
  ${ResponsivePXValue('width', 'CALC(25% - 24px)')}
  ${ResponsivePXValue('padding', '16px 12px')}

  .resend-button {
    ${ResponsivePXValue('margin', '10px 0')}
  }

`

interface UserReferralsState {
  activeTab: string
  userId: string
  pendingReferrals: FriendReferenceFragment[]
  processingReferrals: FriendReferenceFragment[]
  successfulReferrals: FriendReferenceFragment[]
  descriptionText: string
}

const DEFAULT_STATE: UserReferralsState = {
  activeTab: TabEnum.PENDING,
  userId: '',
  pendingReferrals: [],
  processingReferrals: [],
  successfulReferrals: [],
  descriptionText: 'Here are the friends you’ve invited to get cooking. They haven’t ordered their first Meal Kit, so give them a nudge to get them going.',
}

export interface UserReferralsProps {
  userDetailsData: UserDetailsQuery
  allReferrals: GetAllFriendReferencesQuery['friendReferences']['list']
  onResendReferral: (id: string) => void
}

export function UserReferrals({ userDetailsData, allReferrals, onResendReferral }: UserReferralsProps): JSX.Element {

  const [state, setState] = useState<UserReferralsState>({ ...DEFAULT_STATE })

  const _handleResendEmail = async (id: string): Promise<void> => {
    onResendReferral(id)
  }

  const getListDisplayIndex = (index: number): number => {
    return index + 1
  }

  useEffect(() => {
    if (userDetailsData?.currentUser) {
      setState((prevState) => ({ ...prevState, userId: userDetailsData.currentUser.id }))
    }
  }, [userDetailsData])

  useEffect(() => {
    if (allReferrals) {
      const pendingArray: FriendReferenceFragment[] = []
      const processingArray: FriendReferenceFragment[] = []
      const successfulArray: FriendReferenceFragment[] = []

      for (const reference of allReferrals) {
        if (reference.status === UserReferralStatusEnum.EMAILED ||
          reference.status === UserReferralStatusEnum.RESENT_EMAIL ||
          reference.status === UserReferralStatusEnum.CREATED_ACCOUNT ||
          reference.status === UserReferralStatusEnum.USED_REFERENCE_LINK) {
          pendingArray.push(reference)
        } else if (reference.status === UserReferralStatusEnum.ACTIVATED_SUBSCRIPTION) {
          processingArray.push(reference)
        } else if (reference.status === UserReferralStatusEnum.ORDERED ||
          reference.status === UserReferralStatusEnum.COMPLETED) {
          successfulArray.push(reference)
        }
      }

      setState((prevState) => ({
        ...prevState,
        pendingReferrals: pendingArray,
        processingReferrals: processingArray,
        successfulReferrals: successfulArray,
      }))
    }
  }, [allReferrals])

  useEffect(() => {
    if (state.activeTab) {
      if (state.activeTab === TabEnum.PENDING) {
        setState((prevState) => (
          {
            ...prevState,
            descriptionText: 'Here are the friends you’ve invited to get cooking. They haven’t ordered their first Meal Kit, so give them a nudge to get them going.',
          }))
      } else if (state.activeTab === TabEnum.PROCESSING) {
        setState((prevState) => (
          {
            ...prevState,
            descriptionText: 'Your friends are nearly there. Take a look at who has placed their first Meal Kit order, set to be billed on Wednesday if they keep their plan active. Your credit will reflect shortly after.',
          }))
      } else if (state.activeTab === TabEnum.SUCCESSFUL) {
        setState((prevState) => (
          {
            ...prevState,
            descriptionText: 'Nice one. Here are your successful referrals who have helped you save on your Meal Kit orders. Refer more friends (you’ll thank us later).',
          }))
      }
    }
  }, [state.activeTab])

  let referral: FriendReferenceFragment
  let referralIndex: number

  return (
    <Fragment>
      <ContentCard
        headerType={ContentCardHeaderEnum.STANDARD}
        title='YOUR REFERRALS'
        description={state.descriptionText}>
        <MainContainer>
          <DeviceContainer $mobile>
            <Tabs
              activeTab={state.activeTab}
              backgroundColor={theme.colors.whites.pureWhite}
              onTabChange={(activeTab: TabEnum): void => setState(prevState => ({ ...prevState, activeTab }))}>
              <TabPane tab={TabEnum.PENDING} title={TabEnum.PENDING} className='mobile-tab'>
                <Choose>
                  <When condition={state.pendingReferrals.length > 0}>
                    <For each='referral' of={state.pendingReferrals} index='referralIndex'>
                      <TableRow>
                        <TableCell align='left' direction='column' justify='center' padding='0 12px 0 12px'>
                          <Paragraph
                            className='mobile-row-item'
                            variant='p2'>
                            {getListDisplayIndex(referralIndex)}
                          </Paragraph>
                          <Paragraph
                            className='mobile-row-item'
                            variant='p1'>
                            {referral.name}
                          </Paragraph>
                          <Paragraph
                            className='mobile-row-item'
                            variant='p1'>
                            {format(new Date(referral.emailTime), 'dd MMM yyyy')}
                          </Paragraph>
                          <Link
                            className='mobile-row-item'
                            color={theme.colors.slates.ash}
                            onClick={() => _handleResendEmail(referral.id)}>RESEND</Link>
                        </TableCell>
                      </TableRow>
                      <Rule color='slate' />
                    </For>
                  </When>
                  <Otherwise>
                    <Heading
                      variant='h5'
                      align='center'>
                      {'No pending referrals'}
                    </Heading>
                    <Paragraph
                      className='text'
                      variant='p1'
                      align='center'>
                      {'It does not look like you have any processing referrals. Refer a friend to get started.'}
                    </Paragraph>
                  </Otherwise>
                </Choose>
              </TabPane>
              <TabPane tab={TabEnum.PROCESSING} title={TabEnum.PROCESSING} className='mobile-tab'>
                <Choose>
                  <When condition={state.processingReferrals.length > 0}>
                    <For each='referral' of={state.processingReferrals} index='referralIndex'>
                      <TableRow>
                        <TableCell align='left' direction='column' justify='center' padding='0 12px 0 12px'>
                          <Paragraph
                            className='mobile-row-item'
                            variant='p2'>
                            {getListDisplayIndex(referralIndex)}
                          </Paragraph>
                          <Paragraph
                            className='mobile-row-item'
                            variant='p1'>
                            {referral.name}
                          </Paragraph>
                          <Paragraph
                            variant='p1'>
                            {format(new Date(referral.updatedAt), 'dd MMM yyyy')}
                          </Paragraph>
                          <Paragraph
                            variant='p1'
                            color={theme.colors.oranges.coral}>
                            {`${referral?.loyaltyTier?.referrerPoints} points pending`}
                          </Paragraph>
                        </TableCell>
                      </TableRow>
                      <Rule color='slate' />
                    </For>
                  </When>
                  <Otherwise>
                    <Heading
                      variant='h5'
                      align='center'>
                      {'No processing referrals'}
                    </Heading>
                    <Paragraph
                      className='text'
                      variant='p1'
                      align='center'>
                      {'It does not look like you have any processing referrals. Refer a friend to get started.'}
                    </Paragraph>
                  </Otherwise>
                </Choose>
              </TabPane>
              <TabPane tab={TabEnum.SUCCESSFUL} title={TabEnum.SUCCESSFUL} className='mobile-tab'>
                <Choose>
                  <When condition={state.successfulReferrals.length > 0}>
                    <For each='referral' of={state.successfulReferrals} index='referralIndex'>
                      <TableRow>
                        <TableCell align='left' direction='column' justify='center' padding='0 12px 0 12px'>
                          <Paragraph
                            className='mobile-row-item'
                            variant='p2'>
                            {getListDisplayIndex(referralIndex)}
                          </Paragraph>
                          <Paragraph
                            className='mobile-row-item'
                            variant='p1'>
                            {referral.name}
                          </Paragraph>
                          <Paragraph
                            variant='p1'>
                            {format(new Date(referral.updatedAt), 'dd MMM yyyy')}
                          </Paragraph>
                          <Paragraph
                            variant='p1'
                            color={theme.colors.greens.greenSmoke}>
                            {`${referral?.loyaltyTier?.referrerPoints} points received`}
                          </Paragraph>
                        </TableCell>
                      </TableRow>
                      <Rule color='slate' />
                    </For>
                  </When>
                  <Otherwise>
                    <Heading
                      variant='h5'
                      align='center'>
                      {'No successful referrals'}
                    </Heading>
                    <Paragraph
                      className='text'
                      variant='p1'
                      align='center'>
                      {'It does not look like you have any successful referrals. Refer a friend to get started.'}
                    </Paragraph>
                  </Otherwise>
                </Choose>
              </TabPane>
            </Tabs>
          </DeviceContainer>
          <DeviceContainer $tablet $desktop>
            <Tabs
              activeTab={state.activeTab}
              backgroundColor={theme.colors.whites.pureWhite}
              onTabChange={(activeTab: TabEnum): void => setState(prevState => ({ ...prevState, activeTab }))}>
              <TabPane tab={TabEnum.PENDING} title={TabEnum.PENDING}>
                <Choose>
                  <When condition={state.pendingReferrals.length > 0}>
                    <For each='referral' of={state.pendingReferrals} index='referralIndex'>
                      <ReferralRow>
                        <ReferralItem1>
                          <Paragraph variant='p2'>{getListDisplayIndex(referralIndex)}</Paragraph>
                        </ReferralItem1>
                        <ReferralItem2>
                          <Choose>
                            <When condition={referral.name !== ''}>
                              <Paragraph variant='p1'>{referral.name}</Paragraph>
                            </When>
                            <Otherwise>
                              <Paragraph variant='p1'>{referral.emailAddress}</Paragraph>
                            </Otherwise>
                          </Choose>
                        </ReferralItem2>
                        <ReferralItem3>
                          <Paragraph
                            variant='p1'>
                            {format(new Date(referral.emailTime), 'dd MMM yyyy')}
                          </Paragraph>
                        </ReferralItem3>
                        <ReferralItem4>
                          <Link
                            className='resend-button'
                            color={theme.colors.slates.ash}
                            onClick={() => _handleResendEmail(referral.id)}>RESEND</Link>
                        </ReferralItem4>
                      </ReferralRow>
                      <Rule color='slate' />
                    </For>
                  </When>
                  <Otherwise>
                    <Heading
                      variant='h5'
                      align='center'>
                      {'No pending referrals'}
                    </Heading>
                    <Paragraph
                      className='text'
                      variant='p1'
                      align='center'>
                      {'It does not look like you have any processing referrals. Refer a friend to get started.'}
                    </Paragraph>
                  </Otherwise>
                </Choose>
              </TabPane>
              <TabPane tab={TabEnum.PROCESSING} title={TabEnum.PROCESSING}>
                <Choose>
                  <When condition={state.processingReferrals.length > 0}>
                    <For each='referral' of={state.processingReferrals} index='referralIndex'>
                      <ReferralRow>
                        <ReferralItem1>
                          <Paragraph variant='p2'>{getListDisplayIndex(referralIndex)}</Paragraph>
                        </ReferralItem1>
                        <ReferralItem2>
                          <Choose>
                            <When condition={referral.name !== ''}>
                              <Paragraph variant='p1'>{referral.name}</Paragraph>
                            </When>
                            <Otherwise>
                              <Paragraph variant='p1'>{referral.emailAddress}</Paragraph>
                            </Otherwise>
                          </Choose>
                        </ReferralItem2>
                        <ReferralItem3>
                          <Paragraph variant='p1'>{format(new Date(referral.updatedAt), 'dd MMM yyyy')}</Paragraph>
                        </ReferralItem3>
                        <ReferralItem4>
                          <Paragraph
                            variant='p1'
                            color={theme.colors.oranges.coral}>
                            {`${referral?.loyaltyTier?.referrerPoints} points pending`}
                          </Paragraph>
                        </ReferralItem4>
                      </ReferralRow>
                      <Rule color='slate' />
                    </For>
                  </When>
                  <Otherwise>
                    <Heading
                      variant='h5'
                      align='center'>
                      {'No processing referrals'}
                    </Heading>
                    <Paragraph
                      className='text'
                      variant='p1'
                      align='center'>
                      {'It does not look like you have any processing referrals. Refer a friend to get started.'}
                    </Paragraph>
                  </Otherwise>
                </Choose>
              </TabPane>
              <TabPane tab={TabEnum.SUCCESSFUL} title={TabEnum.SUCCESSFUL}>
                <Choose>
                  <When condition={state.successfulReferrals.length > 0}>
                    <For each='referral' of={state.successfulReferrals} index='referralIndex'>
                      <ReferralRow>
                        <ReferralItem1>
                          <Paragraph variant='p2'>{getListDisplayIndex(referralIndex)}</Paragraph>
                        </ReferralItem1>
                        <ReferralItem2>
                          <Choose>
                            <When condition={referral.name !== ''}>
                              <Paragraph variant='p1'>{referral.name}</Paragraph>
                            </When>
                            <Otherwise>
                              <Paragraph variant='p1'>{referral.emailAddress}</Paragraph>
                            </Otherwise>
                          </Choose>
                        </ReferralItem2>
                        <ReferralItem3>
                          <Paragraph variant='p1'>{format(new Date(referral.updatedAt), 'dd MMM yyyy')}</Paragraph>
                        </ReferralItem3>
                        <ReferralItem4>
                          <Paragraph
                            variant='p1'
                            color={theme.colors.greens.greenSmoke}>
                            {`${referral?.loyaltyTier?.referrerPoints} points received`}
                          </Paragraph>
                        </ReferralItem4>
                      </ReferralRow>
                      <Rule color='slate' />
                    </For>
                  </When>
                  <Otherwise>
                    <Heading
                      variant='h5'
                      align='center'>
                      {'No successful referrals'}
                    </Heading>
                    <Paragraph
                      className='text'
                      variant='p1'
                      align='center'>
                      {'It does not look like you have any successful referrals. Refer a friend to get started.'}
                    </Paragraph>
                  </Otherwise>
                </Choose>
              </TabPane>
            </Tabs>
          </DeviceContainer>
        </MainContainer>
      </ContentCard>
    </Fragment>
  )

}
