import React, { useEffect, useState } from 'react'

import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client'

import { ExecutionResult } from 'graphql'
import update from 'react-addons-update'
import { useToasts } from 'react-toast-notifications'

import { CheckoutPlugin } from '@api/local'
import { Paragraph } from '@client/components'
import { ModalLoading } from '@client/components/molecules'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useEvents } from '@contexts/GTMProvider'
import { SaveUserMenuMutation, UserCartDocument, useSaveUserMenuMutation, useUserCartQuery, useUserDetailsQuery } from '@hooks/api'

interface SaveMenuState {
  complete: boolean
}

const DEFAULT_STATE: SaveMenuState = {
  complete: false,
}

export function SaveMenu(): JSX.Element {

  const config = useConfig()
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>
  const { data: userCartData, loading: userCartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const [saveMenu, { loading: saveLoading }] = useSaveUserMenuMutation()
  const [state, setState] = useState<SaveMenuState>({ ...DEFAULT_STATE })
  const isMenuSaved = userCartData?.currentUser?.activeMenu?.menuIsSaved || false
  const event = useEvents()
  const { addToast } = useToasts()
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })

  const saveUserMenu = async (id: string): Promise<void> => {

    try {
      const result: ExecutionResult<SaveUserMenuMutation> = await saveMenu({
        variables: {
          id,
        },
        refetchQueries: [{ query: UserCartDocument }],
        awaitRefetchQueries: true,
      })

      const discounts = userCartData?.currentUser?.activeCart?.reductions
      let discountTitles = 'no discount'
      let discountValue = 0

      for (let i = 0; i < discounts.length; i++) {
        discountTitles = discountTitles + discounts[i].title
        discountValue = discountValue + discounts[i].value
      }
      const deliveryFee = userCartData?.currentUser?.activeCart?.additions?.find((addition) => addition.title === 'Delivery Fee')?.value
      const cartItemPrice = userCartData?.currentUser?.activeCart?.grandTotal
      const totalPrice = deliveryFee + cartItemPrice
      CheckoutPlugin.shared().setCartErrors(client, [...result.data.userMenuSave.errors])
      event.hasSavedCart(userCartData?.currentUser?.activeCart?.cartItems, totalPrice, discountTitles, discountValue, userDetailsData?.currentUser?.id)
    } catch (e) {
      addToast(e.message, {
        appearance: 'warning',
        autoDismiss: true,
      })
    }
  }

  useEffect(() => {
    if (open) {
      setState((prevState) => update(prevState, { complete: { $set: false } }))
    }
  }, [open])

  useEffect(() => {

    if (!isMenuSaved) {
      saveUserMenu(userCartData?.currentUser?.activeMenu?.id)

      const discounts = userCartData?.currentUser?.activeCart?.reductions
      let discountTitles = ''
      let discountValue = 0
      for (let i = 0; i < discounts.length; i++) {
        discountTitles = discountTitles + discounts[i].title
        discountValue = discountValue + discounts[i].value
      }

    }
  }, [isMenuSaved])

  const loading = userCartLoading || saveLoading || state.complete

  return (
    <Choose>
      <When condition={loading}>
        <ModalLoading />
      </When>
      <When condition={isMenuSaved}>
        <Paragraph align='center'>
          Your plan has been saved
        </Paragraph>
      </When>
      <Otherwise>
        <Paragraph align='center'>
          Saving your subscription
        </Paragraph>
      </Otherwise>
    </Choose>
  )

}
