import React, { useEffect } from 'react'

import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { theme } from '@client/components/Theme'
import { scrollToSelector } from '@utility/Scroll'

const Container = styled.div<{$bgColor: string}>`
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  z-index: -99;
`

export interface HashAnchorProps {
  selectorId: string
  className?: string
}

export function HashAnchor({ selectorId, className }: HashAnchorProps): JSX.Element {

  const location = useLocation()

  useEffect(() => {
    if (location?.hash?.length > 0) {
      setTimeout(() => {
        scrollToSelector(location.hash)
      }, 500)
    }
  }, [])

  return (
    <Container className={className} $bgColor={theme.colors.slates.bitter} id={selectorId}></Container>
  )
}
