import { DocumentNode, FieldFunctionOptions, TypePolicies, gql } from '@apollo/client'

import { Config, ConfigPlugin } from '@lib/Config'

const isBrowser = (): boolean => {
  return (typeof window !== 'undefined')
}

export class ProductPlugin implements ConfigPlugin {

  config!: Config

  async configure(config: Config): Promise<void> {
    this.config = config
  }

  typePolicies = (): TypePolicies => ({
    Product: {
      fields: {
        quantityInCart: {
          read(currentValue: number, options: FieldFunctionOptions): number {
            if (isBrowser()) {
              const id = options.readField('id') as string
              const quantitiesString = sessionStorage.getItem('CART_PRODUCT_QUANTITIES')
              const quantities = quantitiesString ? JSON.parse(quantitiesString) : {}
              const quantity = quantities?.[id]?.quantity as number || 0
              return quantity
            }
            return 0
          },
        },
        cartItemId: {
          read(currentValue: number, options: FieldFunctionOptions): number | null {
            if (isBrowser()) {
              const id = options.readField('id') as string
              const quantitiesString = sessionStorage.getItem('CART_PRODUCT_QUANTITIES')
              const quantities = quantitiesString ? JSON.parse(quantitiesString) : {}
              const cartItemId = quantities?.[id]?.cartItemId || null
              return cartItemId
            }
            return null
          },
        },
      },
    },
    ProductGroup: {
      fields: {
        quantityInCart: {
          read(currentValue: number, options: FieldFunctionOptions): number {
            if (isBrowser()) {
              const id = options.readField('id') as string
              const quantitiesString = sessionStorage.getItem('CART_GROUP_QUANTITIES')
              const quantities = quantitiesString ? JSON.parse(quantitiesString) : {}
              const quantity = quantities?.[id]?.quantity as number || 0
              return quantity
            }
            return 0
          },
        },
        cartItemIds: {
          read(currentValue: number, options: FieldFunctionOptions): string[] {
            if (isBrowser()) {
              const id = options.readField('id') as string
              const quantitiesString = sessionStorage.getItem('CART_GROUP_QUANTITIES')
              const quantities = quantitiesString ? JSON.parse(quantitiesString) : {}
              const cartItemId = quantities?.[id]?.cartItemIds || []
              return cartItemId
            }
            return []
          },
        },
      },
    },
  })

  extensions = (): DocumentNode => {
    const products = this.config.possibleTypes.Product?.map((productType: string) => `
      extend type ${productType} {
        quantityInCart: Int!
        cartItemId: String
      }
    `).join('\n')

    const groups = this.config.possibleTypes.ProductGroup?.map((productGroupType: string) => `
      extend type ${productGroupType} {
        quantityInCart: Int!
        cartItemIds: [String!]!
      }
    `).join('\n')

    return gql`
      extend interface Product {
        quantityInCart: Int!
        cartItemId: String
      }
      ${products}

      extend interface ProductGroup {
        quantityInCart: Int!
        cartItemIds: [String!]!
      }
      ${groups}
    `
  }

}
