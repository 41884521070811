import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import { useToasts } from 'react-toast-notifications'
import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { GlobalModalTypeEnum, ModalsPlugin } from '@api/local/ModalsPlugin'
import { MultipleImageContainer, ProductImageInterface } from '@atoms/images/MultipleImageContainer'
import { Heading, Paragraph, Rule, Spacer, Button } from '@atoms/index'
import { useConfig } from '@client/contexts/ConfigProvider'
import { ResponsiveProperty, ResponsivePXValue, SidePagePadding, theme, ZeroSpace } from '@components/Theme'
import { useEvents } from '@contexts/GTMProvider'
import {
  useUserDetailsQuery, useGetAppQuery, useUserCartQuery, useAddItemToCartMutation, useChangeCartItemQuantityMutation,
  useRemoveItemFromCartMutation, FrozenMealDishDetailsFragment, FrozenTagFragment, FrozenMealListFragment,
} from '@hooks/api'
import { ItemInterfaceNew } from '@lib/GTM'
import { Utilities } from '@lib/Utilities'
import { CategoryTag, FloatingUpSell, Form, useForm } from '@molecules/index'
import { FrozenPortionSizeEnum, DeviceTypeEnum, ProductAvailabilityEnum, ProductStockStatusEnum, Product } from '@uctypes/api/globalTypes'
import { useOutOfStockNotifier } from '@utility/Commons'

import { LowStockContainer } from '../misc'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  .input {
    ${ZeroSpace}
  }
`

const DishContainer = styled.div`
  display: flex;
  ${ResponsiveProperty('flex-direction', { mobile: 'column-reverse', tablet: 'column-reverse', desktop: 'row' })}
  ${ResponsiveProperty('align-items', { mobile: 'stretch', tablet: 'stretch', desktop: 'flex-start' })}
  ${ResponsivePXValue('margin-bottom', { mobile: '22px', tablet: '24px', desktop: '32px' })}
  ${ResponsivePXValue('padding', { mobile: '0', tablet: '0', desktop: `0 ${theme.pagePadding.desktop}px` })}
`

const ContentContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  ${ResponsiveProperty('padding', { mobile: '24px 16px 0 16px', tablet: '24px 16px 0 16px', desktop: '0 24px 0 0' })}

  .title-header {
    ${ZeroSpace}
    ${ResponsivePXValue('line-height', '40px')}
  }

  .subtitle {
    ${ZeroSpace}
    ${ResponsivePXValue('line-height', '24px')}
  }

  .text-description {
    ${ZeroSpace}
    ${ResponsivePXValue('line-height', '24px')}
  }

  .price-header {
    font-weight: 700;
    ${ZeroSpace}
    ${ResponsivePXValue('font-size', { mobile: '16px', tablet: '16px', desktop: '20px' })}
    ${ResponsivePXValue('line-height', '24px')}
  }
`

const AllergenContainer = styled.div`
  ${ResponsivePXValue('margin-bottom', { mobile: '16px', tablet: '20px', desktop: '24px' })}
`

const RuleContainer = styled.div`
  ${ResponsivePXValue('margin-bottom', { mobile: '24px', tablet: '28px', desktop: '36px' })}
  ${SidePagePadding}
`

const TagsContainer = styled.div`
  display: flex;
  align-self: stretch;
  overflow: auto;

  ${SidePagePadding}
  ${ResponsiveProperty('justify-content', { mobile: 'space-between', tablet: 'space-between', desktop: 'space-around' })};

  .tag {
    justify-content: flex-start;
    ${ResponsivePXValue('min-width', '116px')}

    img {
      ${ResponsivePXValue('height', '24px')}
      ${ResponsivePXValue('width', '24px')}
      ${ResponsivePXValue('min-width', '24px')}
    }
  }
`

const InputContainer = styled.div`
  display: flex;
  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
`

const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`

export interface CraftDessertMealDishDetailsProps {
  dish: FrozenMealDishDetailsFragment
  allergens: string
  isDessert?: boolean
}

interface CraftDessertMealDishDetailsState {
  loading: boolean
  disabled: boolean
  priceDisplay: string
  price: number
  currentAmountInCart: number
  servingSize: FrozenPortionSizeEnum
  notAvailable: boolean
  buttonTitle: string
}

const DEFAULT_STATE: CraftDessertMealDishDetailsState = {
  loading: false,
  disabled: false,
  priceDisplay: '',
  price: 0,
  currentAmountInCart: null,
  servingSize: FrozenPortionSizeEnum.SERVES_ONE,
  notAvailable: false,
  buttonTitle: 'ADD TO CART',
}

export const CraftDessertMealDishDetails = React.memo(({ dish, allergens, isDessert = false }: CraftDessertMealDishDetailsProps): JSX.Element => {

  const config = useConfig()
  const [state, setState] = useState<CraftDessertMealDishDetailsState>({ ...DEFAULT_STATE })
  const [quantity, setQuantity] = useState<number>(1)
  const [addItemToCart] = useAddItemToCartMutation()
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const [form] = useForm()
  const theme = useTheme()
  const events = useEvents()
  const { addToast } = useToasts()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE

  const product = dish?.products?.find((product) => { return product.frozenPortionSize === state?.servingSize })
  const productImagesArray = dish?.productImages as unknown as ProductImageInterface[]

  const { data: userCartData } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const [changeCartItemQuantity] = useChangeCartItemQuantityMutation()
  const [removeItemFromCart] = useRemoveItemFromCartMutation()
  const cartItems = userCartData?.currentUser?.activeCart?.cartItems
  const isOutOfStockItem = useOutOfStockNotifier()
  const checkoutStatus = userCartData?.currentUser?.checkoutStatus

  const setLoading = (loading: boolean): void => {
    setState(prevState => ({ ...prevState, loading }))
  }

  const _handleAvailabilityCheck = (): boolean => {

    const unavailable = dish?.products?.find((dish) => { return dish.frozenPortionSize === FrozenPortionSizeEnum.SERVES_ONE })?.availability === ProductAvailabilityEnum.UNAVAILABLE_GEOGRAPHICALLY

    if (unavailable) {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.DELIVERY_UNAVAILABLE)
      return false
    } else {
      return true
    }
  }

  const _handleDefaultAddressCheck = (): boolean => {
    if (!userDetailsData?.currentUser?.hasDefaultAddress) {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.ADD_LOCATION)
      return false
    } else {
      return true
    }
  }
  const price = dish?.products?.find((product) => { return product.frozenPortionSize === FrozenPortionSizeEnum.SERVES_ONE })?.price

  const _handleAddToCart = async () => {
    if (isOutOfStockItem(product as unknown as Product, product.quantityInCart, checkoutStatus.subscriptionIsSaved)) {
      return
    }
    if (!_handleDefaultAddressCheck() || !_handleAvailabilityCheck()) return
    setLoading(true)
    try {
      const productItem = dish?.products?.find((dish) => { return dish.frozenPortionSize === FrozenPortionSizeEnum.SERVES_ONE })
      await addItemToCart({
        variables: { productId: productItem?.id, quantity },
      })

      const logData = {
        itemName: dish?.name,
        itemId: productItem?.id,
        itemGroupId: dish?.id,
        price,
        itemBrand: 'UCOOK',
        itemCategory: 'Craft Dessert Meals',
        itemVariant: dish?.frozenCategories?.map((cat) => cat.title)?.join(', '),
        itemListName: 'Craft Dessert Meals',
        quantity,
        itemImage: dish?.coverImage?.location,
        itemStockCount: 1,
        itemChef: 'UCOOK',
        isMealkit: 'no',
        itemServingSize: 1,
      }
      const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew

      events.hasAddedToCart(snakedData)
      setQuantity(1)
      addToast(`Successfully added (${quantity}) item to cart.`, {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setLoading(false)
  }

  const _handleOnRemove = async (): Promise<void> => {

    setLoading(true)
    const product = dish?.products?.find((product) => { return product.frozenPortionSize === state.servingSize })

    try {
      if (state.currentAmountInCart === 1) {
        await removeItemFromCart({
          variables: {
            productId: product.id,
          },
        })
        setState((prevState) => update(prevState, { currentAmountInCart: { $set: 0 } }))

      } else {
        await changeCartItemQuantity({
          variables: {
            productId: product.id,
            quantity: state.currentAmountInCart - 1,
          },
        })
      }

      const logData = {
        itemName: dish?.name,
        itemId: product?.id,
        price: product?.price,
        itemBrand: 'UCOOK',
        itemCategory: dish?.frozenCategories?.map((cat) => cat.id)?.join(', '),
        itemVariant: dish?.frozenCategories?.map((cat) => cat.title)?.join(', '),
        itemListName: 'Craft Meals',
        quantity: 1,
        itemImage: dish?.coverImage?.location,
        itemStockCount: 1,
        itemChef: 'UCOOK',
        isMealkit: 'no',
        itemServingSize: 1,
      }
      const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew

      events.hasRemovedFromCart(snakedData)

      addToast('Item successfully removed from cart', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setLoading(false)

  }

  const _handleOnCenterClick = async (): Promise<void> => {
    if (!(state.currentAmountInCart > 0)) {
      _handleAddToCart()
    }
  }
  useEffect(() => {
    if (dish) {
      setState((prevState) => ({ ...prevState, priceDisplay: `R${price}` }))
      isCardItemInBasket()
      setButtonTitle()
    }
  }, [dish])

  let tag: FrozenTagFragment

  const setButtonTitle = () => {

    let notAvailable = false
    let buttonTitle = 'ADD TO CART'
    let craftDessertProduct: FrozenMealListFragment

    for (let i = 0; i < dish?.products.length; i++) {
      if (dish?.products[i].frozenPortionSize === FrozenPortionSizeEnum.SERVES_ONE) {
        craftDessertProduct = dish?.products[i]
      }
    }

    if (craftDessertProduct?.stockStatus === ProductStockStatusEnum.OUT_OF_STOCK) {
      notAvailable = true
      buttonTitle = 'OUT OF STOCK'
    } else if (craftDessertProduct?.availability === ProductAvailabilityEnum.UNAVAILABLE_FOR_ORDER_TYPE) {
      buttonTitle = 'GET STARTED'
    } else if (craftDessertProduct?.availability !== ProductAvailabilityEnum.AVAILABLE) {
      notAvailable = true
      buttonTitle = 'NOT AVAILABLE'
    }

    setState((prevState) => update(prevState, {
      notAvailable: { $set: notAvailable },
      buttonTitle: { $set: buttonTitle },
    }))
  }

  const isCardItemInBasket = () => {

    const productId = dish?.products?.find((product) => { return product.frozenPortionSize === FrozenPortionSizeEnum.SERVES_ONE })?.id

    const quantity = cartItems?.find((item) => item?.product?.id === productId)?.quantity || 0

    setState((prevState) => update(prevState, {
      currentAmountInCart: { $set: quantity },
    }))

  }

  useEffect(() => {
    isCardItemInBasket()
    setButtonTitle()
  }, [userCartData])

  return (
    <Form
      form={form}
      disabled={state.loading}
      onFinish={_handleAddToCart}>
      <Container>
        <DishContainer>
          <ContentContainer>
            <Heading variant='h3' color={theme.colors.greys.liteCodGrey} className='title-header'>
              {dish?.name}
            </Heading>
            <Spacer universal='8px' />
            <Heading variant='h6' color={theme.colors.slates.bitter} className='subtitle'>
              {isDessert ? dish?.subTitle : dish?.description}
            </Heading>
            <Spacer universal='8px' />
            <Paragraph variant='p1' color={theme.colors.greys.darkCodGrey} className='text-description'>
              {isDessert ? dish?.description : dish?.subTitle}
            </Paragraph>
            <Spacer universal='16px' />
            <If condition={isDessert}>
              <Paragraph variant='p1' color={theme.colors.greys.darkCodGrey} className='text-description'>
                {dish?.note}
              </Paragraph>
            </If>
            <Spacer universal='16px' />
            <LowStockContainer
              displayRule
              product={product}
              flexDirection={isMobile ? 'row' : 'column'}
              alignItems={isMobile ? 'center' : 'flex-start'}>
              <Heading variant='h4' color={theme.colors.greys.darkCodGrey} className='price-header'>
                {state.priceDisplay}
              </Heading>
            </LowStockContainer>
            <Spacer universal='24px' />
            <InputContainer>
              <Button
                className='add-button'
                title={state.buttonTitle}
                color='black'
                fullWidth
                loading={state.loading}
                disabled={state.loading || state.notAvailable}
                amount={state.currentAmountInCart}
                onClick={_handleOnCenterClick}
                onLeftIconClick={_handleOnRemove}
                onRightIconClick={_handleAddToCart} />
            </InputContainer>
            <Spacer universal='16px' />
            <AllergenContainer>
              <Paragraph variant='p2' bold color={theme.colors.slates.bitter}>Allergens: {allergens}</Paragraph>
            </AllergenContainer>
          </ContentContainer>
          <ImageContainer>
            <If condition={dish?.upSellText?.length > 0}>
              <FloatingUpSell text={dish.upSellText} />
            </If>
            <MultipleImageContainer images={productImagesArray} />
          </ImageContainer>
        </DishContainer>
        <RuleContainer>
          <Rule color='slate' />
        </RuleContainer>
        <TagsContainer>
          <For each='tag' of={dish?.frozenTags || []}>
            <CategoryTag
              image={tag.icon.desktop}
              title={tag?.title}
              key={tag.id}
              showBackground={false}
              className='tag' />
          </For>
        </TagsContainer>
      </Container >
    </Form>
  )

})

CraftDessertMealDishDetails.displayName = 'CraftDessertMealDishDetails'
