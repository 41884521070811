import React, { useEffect, useState } from 'react'

import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client'

import update from 'react-addons-update'

import { CheckoutPlugin } from '@api/local'
import { Button, Paragraph, Link, Spacer, theme } from '@client/components'
import { ModalLoading } from '@client/components/molecules'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SiteHelper } from '@client/lib/SiteHelper'
import { UserCartDocument, UserDetailsDocument, usePauseSubscription, useResetUserMenuMutation, useUserCartQuery } from '@hooks/api'

interface InvalidSubscriptionState {
  complete: boolean
}

const DEFAULT_STATE: InvalidSubscriptionState = {
  complete: false,
}

export function InvalidSubscription(): JSX.Element {

  const config = useConfig()
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>
  const { data: userCartData, loading: userCartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const [state, setState] = useState<InvalidSubscriptionState>({ ...DEFAULT_STATE })
  const [resetUserMenu, { loading: resetLoading }] = useResetUserMenuMutation()
  const [pauseSubscription, { loading: pauseLoading }] = usePauseSubscription()

  const _handleReset = async (): Promise<void> => {
    await resetUserMenu({
      variables: {
        id: userCartData?.currentUser?.activeMenu?.id,
      },
      refetchQueries: [{
        query: UserDetailsDocument,
      }, {
        query: UserCartDocument,
      }],
      awaitRefetchQueries: true,
    })
    setState((prevState) => update(prevState, { complete: { $set: true } }))
    await CheckoutPlugin.shared().checkout(client)
  }

  const _handlePause = async (): Promise<void> => {
    await pauseSubscription({
      variables: {
        id: userCartData?.currentUser?.activeMenu?.subscription?.id,
      },
      refetchQueries: SiteHelper.getCheckoutRefetchQueries(),
      awaitRefetchQueries: true,
    })
    setState((prevState) => update(prevState, { complete: { $set: true } }))
    if (userCartData?.currentUser?.checkoutStatus?.hasOnDemandProducts) {
      await CheckoutPlugin.shared().checkout(client)
    } else {
      await CheckoutPlugin.shared().reset()
    }
  }

  useEffect(() => {
    if (open) {
      setState((prevState) => update(prevState, { complete: { $set: false } }))
    }
  }, [open])

  const loading = userCartLoading || pauseLoading || resetLoading
  const dishCount = userCartData?.currentUser?.activeMenu?.dishes?.length

  return (
    <Choose>
      <When condition={loading || state.complete}>
        <ModalLoading />
      </When>
      <Otherwise>
        <>
          <Paragraph align='center'>
            It looks like you have only chosen {dishCount} dish{dishCount > 1 ? 'es' : ''}. To Proceed with this order, you must have at least 2 Meal Kit dishes selected.
          </Paragraph>
          <Spacer universal='24px' />
          <Button className='buttons' title='RESET TO MY DEFAULT PLAN' onClick={_handleReset} />
          <Spacer universal='16px' />
          <Link color={theme.colors.oranges.coral} onClick={_handlePause}>PAUSE MY PLAN </Link>
        </>
      </Otherwise>
    </Choose>
  )

}
