import React from 'react'

import qs from 'qs'
import styled from 'styled-components'

import { Spacer } from '@client/components/atoms'
import { Aisles, CraftMealCard } from '@client/components/molecules'
import { AislesBaseContainer } from '@client/components/Theme'
import { FrozenMealDishListFragment, PageDataSectionFragment } from '@hooks/api'

const AislesContainer = styled.div`
  ${AislesBaseContainer}
`

interface PageDataFilteredFrozenProps {
  pageSection: PageDataSectionFragment
}

export function PageDataFilteredFrozen({ pageSection }: PageDataFilteredFrozenProps): JSX.Element {

  const selectedFilters = { frozenCategories: pageSection?.frozenCategories?.map(frozenCategory => frozenCategory.id), frozenTags: pageSection?.frozenTags?.map(frozenTag => frozenTag.id) }
  const newQueryString = qs.stringify({ filters: selectedFilters })
  const viewAllUrl = pageSection?.viewAllUrl || `/frozen/craft-meal?${newQueryString}`

  let craftMeal: FrozenMealDishListFragment

  return (
    <AislesContainer>
      <Aisles title={pageSection?.title} viewAllUrl={viewAllUrl} displayViewAllButton={pageSection?.displayViewAllButton} description={pageSection?.description} className='aisles-filtered-data'>
        <For each='craftMeal' of={pageSection?.frozenDishes || []}>
          <CraftMealCard
            className='product-card'
            key={craftMeal.id}
            craftMeal={craftMeal}/>
        </For>
      </Aisles>
      <Spacer universal='16px' />
    </AislesContainer>
  )

}
