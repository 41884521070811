import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Paragraph } from '@atoms/index'
import { ResponsiveImage } from '@client/components/atoms'
import { ResponsivePXValue } from '@client/components/Theme'
import { DeviceContainer } from '@client/components/utility'
import { CartItemFragment, useGetApiEnumQuery } from '@hooks/api'

const Container = styled.div`
  display: flex;
  justify-content: center;

  ${ResponsivePXValue('padding', '8px 0')}
`

const ImageContainer = styled.div`
  flex-shrink: 0;
  overflow: hidden;

  ${ResponsivePXValue('width', { mobile: '48px', tablet: '68px', desktop: '88px' })}
  ${ResponsivePXValue('height', { mobile: '48px', tablet: '68px', desktop: '88px' })}
  ${ResponsivePXValue('border-radius', '8px')}
`

const DetailsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  ${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
  ${ResponsivePXValue('margin-left', { mobile: '8px', tablet: '10px', desktop: '12px' })}

  .detail-text {
    padding: 0;
    ${ResponsivePXValue('margin', '4px 0')}
    ${ResponsivePXValue('width', { mobile: '100%', tablet: '100%', desktop: '25%' })}
    ${ResponsivePXValue('text-align', { mobile: 'left', tablet: 'center', desktop: 'center' })}
  }
`

const TitleContainer = styled.div`

  ${ResponsivePXValue('width', { mobile: '100%', tablet: '100%', desktop: '50%' })}

  .text {
    padding: 0;
    ${ResponsivePXValue('margin', '4px 0')}
  }
`

export interface PlanItemProps {
  cartItem: CartItemFragment
}

export function PlanItem({ cartItem }: PlanItemProps): JSX.Element {

  const { data: portionsData } = useGetApiEnumQuery({ variables: { enum: 'NumberOfPortionsEnum' } })
  const theme = useTheme()

  const product = cartItem.product.__typename === 'MealKit' && cartItem.product
  const portionSize = portionsData?.enum?.values?.find((item) => item.value === product.portionSize).title ?? ''
  const name = product.name.replace(/(\(.*?\))/, '')

  return (
    <Container>
      <ImageContainer>
        <ResponsiveImage image={product.coverImage} lazy={false} />
      </ImageContainer>
      <DetailsContainer>
        <TitleContainer>
          <Paragraph variant='p2' className='text'>{name}</Paragraph>
          <DeviceContainer $desktop>
            <Paragraph variant='p3' className='text' color={theme.colors.slates.ash}>Eat within {product.cookWithin} days</Paragraph>
          </DeviceContainer>
        </TitleContainer>
        <Paragraph
          variant='p1'
          className='detail-text'>
          {product.mealKitCategories.map((category) => category.title).join(', ')}
        </Paragraph>
        <Paragraph
          variant='p1'
          className='detail-text'>
          Serves {portionSize}
        </Paragraph>
        <DeviceContainer $mobile>
          <Paragraph
            variant='p3'
            align='left'
            className='detail-text'
            color={theme.colors.slates.ash}>
            Eat within {product.cookWithin} days
          </Paragraph>
        </DeviceContainer>
      </DetailsContainer>
    </Container>
  )

}
