import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'

import qs from 'qs'
import update from 'react-addons-update'
import { useNavigate, useLocation } from 'react-router'
import styled, { CSS } from 'styled-components'

import { Loader } from '@atoms/notifications'
import { Paragraph } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { useConfig } from '@contexts/ConfigProvider'
import { useEvents } from '@contexts/GTMProvider'
import { ProductAggregationSectionFragment, useUserDetailsQuery, useGetAppQuery } from '@hooks/api'
import { useLoadingData } from '@hooks/UseLoadingData'
import { Utilities } from '@lib/Utilities'
import { SectionLoading } from '@molecules/content'
import { GridPagination, MiniProductCard } from '@molecules/index'
import { ProductRangeEnum, DeviceTypeEnum, FrozenPortionSizeEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  ${ResponsivePXValue('margin-bottom', '16px')}
`

const GridContainer = styled.div<{ $isMobile: boolean }>`
  width: 100%;
  transition: opacity 0.3s ease-in-out;

  ${(props): CSS => {
    if (props.$isMobile) {
      return `
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        ${ResponsivePXValue('grid-gap', '8px')}
      `
    }
    return `
      display: flex;
      flex-wrap: wrap;
      ${ResponsivePXValue('gap', '16px')}
    `
  }}

  .grid-page-card {
    margin: 0;
  }
`

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(props): string => props.theme.colors.misc.transparent};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  transition: opacity 0.5s ease-in-out;
`

const NoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('gap', '16px')}
  ${ResponsivePXValue('padding', '24px')}
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};
`

export interface ProductGridProps {
  productsPerPage?: number
  productType: ProductRangeEnum
  useProductQuery: (options: { variables: { skip: number, limit: number, userId: string, input: { productType: ProductRangeEnum } } }) => {
    data?: any
    loading: boolean
    previousData?: any
  }
  queryVariables?: any
  productCard: React.ReactElement
  onLoaded?: (aggregations: ProductAggregationSectionFragment[], count?: number) => void

  isBoughtBefore?: boolean
}

// Default state
const DEFAULT_STATE = {
  totalCount: 0,
  isTransitioning: false,
  hasLoggedImpressions: false,
}

// Mapping for product type keys
const productTypeKeys = {
  [ProductRangeEnum.MARKET_PRODUCT]: 'marketProducts',
  [ProductRangeEnum.FROZEN_MEAL]: 'dishes',
  [ProductRangeEnum.WINE]: 'wines',
  // [ProductRangeEnum.MEAL_KIT]: 'mealKits', // Placeholder for Meal Kit (product group)
}

// Mapping for product prop names passed to ProductCard
const productPropNames = {
  [ProductRangeEnum.MARKET_PRODUCT]: 'marketProduct',
  [ProductRangeEnum.FROZEN_MEAL]: 'craftMeal',
  [ProductRangeEnum.WINE]: 'wine',
  // [ProductRangeEnum.MEAL_KIT]: 'mealKit', // Placeholder for Meal Kit
}

// Mapping for category fields per product type
const categoryFields = {
  [ProductRangeEnum.MARKET_PRODUCT]: 'marketProductCategories',
  [ProductRangeEnum.FROZEN_MEAL]: 'frozenCategories',
  [ProductRangeEnum.WINE]: 'wineCategory',
  // [ProductRangeEnum.MEAL_KIT]: 'mealKitCategories', // Placeholder for Meal Kit
}

export const ProductGrid = React.memo(function ProductGrid({
  productsPerPage = 20,
  productType,
  useProductQuery,
  queryVariables,
  productCard: ProductCard,
  onLoaded,
  isBoughtBefore = false,
}: ProductGridProps) {
  const config = useConfig()
  const navigate = useNavigate()
  const location = useLocation()
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const events = useEvents()
  const [state, setState] = useState(DEFAULT_STATE)
  const { data: appData = { app: { deviceType: DeviceTypeEnum.DESKTOP } } } = useGetAppQuery()
  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE
  const gridRef = useRef(null)
  const hasLoadedRef = useRef(false)

  // Pagination
  const params = qs.parse(location.search.replace('?', ''))
  const currentPage = parseInt(params.page) || 1

  // Fetch product data
  const { data: productData, loading: productLoading, previousData } = useProductQuery({
    variables: {
      ...queryVariables,
      skip: (currentPage - 1) * productsPerPage,
      limit: productsPerPage,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  })

  const currentData = productData || previousData
  const isMyShopList = !!currentData?.myShopItems
  const productKey = isMyShopList ? 'myShopItems' : productTypeKeys[productType]

  const fetchedProducts = useMemo(() => {
    if (!currentData) return { list: [], count: 0 }
    const rawList = currentData[productKey]?.list || []
    let list = rawList
    if (isMyShopList && productType === ProductRangeEnum.FROZEN_MEAL) {
      list = rawList.map((item: any) => item.group || item) // Handle Frozen Meal groups in shop list
    }
    const count = currentData[productKey]?.count || 0
    return { list, count }
  }, [currentData, productKey, productType, isMyShopList])

  const products = useLoadingData({
    data: fetchedProducts.list,
    loading: productLoading && !previousData,
    defaultData: [],
  })

  const debouncedNavigate = useCallback(
    Utilities.debounce((path) => navigate(path), 500),
    [navigate],
  )

  const handlePageChange = (page: number) => {
    setState((prevState) => ({
      ...prevState,
      isTransitioning: true,
      hasLoggedImpressions: false,
      totalCount: null,
    }))
    hasLoadedRef.current = false // Reset when page changes
    const newParams = { ...params, page }
    const queryString = qs.stringify(newParams)
    debouncedNavigate(`${location.pathname}?${queryString}`)
    if (config.isBrowser()) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    if (!productLoading && fetchedProducts.list && !hasLoadedRef.current) {
      const aggregations = currentData[productKey]?.aggregation?.sections || []
      setState((prev) => ({
        ...prev,
        totalCount: fetchedProducts.count,
        isTransitioning: false,
      }))
      onLoaded?.(aggregations, fetchedProducts.count)
      hasLoadedRef.current = true // Mark as loaded
    }
  }, [productLoading, fetchedProducts.list, currentData, productKey, fetchedProducts.count, onLoaded])

  // Reset state when query variables change
  const variablesString = useMemo(() => JSON.stringify(queryVariables), [queryVariables])
  useEffect(() => {
    setState((prev) => update(prev, {
      totalCount: { $set: null },
      hasLoggedImpressions: { $set: false },
    }))
    hasLoadedRef.current = false
  }, [variablesString])

  // Log impressions to Google Analytics
  useEffect(() => {
    if (!productLoading && products.length > 0 && !state.hasLoggedImpressions) {
      events.hasViewedCatalogue(
        products.map((product, displayIndex) => {
          let itemId = product.id
          let price = product.price
          let itemGroupId = product.id
          let itemBrand = product?.brand?.name || 'UCOOK'
          let itemCategory = ''
          let itemVariant = ''
          let itemListName = ''

          // Wine-specific logic
          if (productType === ProductRangeEnum.WINE) {
            itemId = product.id
            price = product.price
            itemGroupId = product.id
            itemBrand = 'UCOOK'
            itemCategory = product.wineCategory?.id || ''
            itemVariant = product.wineCategory?.variety || ''
            itemListName = 'Wine'
          } else if (productType === ProductRangeEnum.FROZEN_MEAL) {
            const servesOneProduct = product.products?.find(
              (dish) => dish.frozenPortionSize === FrozenPortionSizeEnum.SERVES_ONE,
            )
            if (servesOneProduct) {
              itemId = servesOneProduct.price
              price = servesOneProduct.price
            }
            itemGroupId = product.id
            itemBrand = 'UCOOK'
            const categories = product.frozenCategories || []
            itemCategory = categories.map((cat) => cat.id).join(', ') || ''
            itemVariant = categories.map((cat) => cat.title).join(', ') || ''
            itemListName = 'Craft Meals'
          } else if (isBoughtBefore && productType === ProductRangeEnum.FROZEN_MEAL) {
            itemCategory = 'Craft Meals Bought Before'
            itemVariant = 'BoughtBefore'
            itemListName = 'Craft Meals Bought Before'
          } else {
            const categoryField = categoryFields[productType]
            const categories = product[categoryField] || []
            itemCategory = categories.map((cat) => cat.id).join(', ') || ''
            itemVariant = categories[0]?.title || ''
            itemListName = productType
          }

          const logData = {
            itemName: product?.name,
            itemId,
            itemGroupId,
            price,
            itemBrand,
            index: displayIndex,
            itemImage: product?.coverImage?.location,
            itemCategory,
            itemListName,
            itemVariant,
          }

          return Utilities.toSnakeCase(logData)
        }),
        userDetailsData?.currentUser?.id,
      )
      setState((prev) => update(prev, { hasLoggedImpressions: { $set: true } }))
    }
  }, [products, productLoading, state.hasLoggedImpressions, productType, events, userDetailsData, isBoughtBefore])

  return (
    <Container>
      <Choose>
        <When condition={!previousData && productLoading}>
          <SectionLoading height="100vh" />
        </When>
        <When condition={!productLoading && !products.length}>
          <NoResultsContainer>
            <Paragraph variant="p4">No results</Paragraph>
          </NoResultsContainer>
        </When>
        <Otherwise>
          <GridContainer
            $isMobile={isMobile}
            ref={gridRef}
            style={{ opacity: state.isTransitioning || productLoading ? 0.7 : 1 }}>
            <For each="product" of={products}>
              <Choose>
                <When condition={isMobile}>
                  <MiniProductCard key={product.id} meal={product} />
                </When>
                <Otherwise>
                  <ProductCard
                    className="grid-page-card"
                    key={product.id}
                    {...{ [productPropNames[productType]]: product }}
                  />
                </Otherwise>
              </Choose>
            </For>
          </GridContainer>
          <If condition={productLoading && previousData}>
            <LoadingOverlay>
              <Loader noShadow={true} />
            </LoadingOverlay>
          </If>
          <If condition={state.totalCount !== null && state.totalCount > productsPerPage}>
            <GridPagination
              currentPage={currentPage}
              totalCount={state.totalCount || 0}
              itemsPerPage={productsPerPage}
              onPageChange={handlePageChange}
            />
          </If>
        </Otherwise>
      </Choose>
    </Container>
  )
})
