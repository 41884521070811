import React, { useEffect, useState } from 'react'

import Cookies from 'js-cookie'
import qs from 'qs'
import update from 'react-addons-update'
import { useLocation, useNavigate } from 'react-router'
import styled, { CSS } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Heading, Icon, IconSizeEnum, IconEnum, Spacer } from '@atoms/index'
import { useGetAppQuery, useGetAllFrozenMealDishesQuery, ProductAggregationSectionFragment } from '@hooks/api'
import {
  EducationalBanner, Filter, FilterSectionProps, NavLinks,
  SelectedFilters, StoreStickyButton,
} from '@molecules/index'
import { ProductGrid } from '@organisms/stores/ProductGrid'
import { FrozenMealDishFilters, DeviceTypeEnum, FrozenMealDishOrderEnum, OrderDirectionEnum, ProductRangeEnum } from '@uctypes/api/globalTypes'
import { SearchEngineOptimization } from '@utility/SearchEngineOptimization'

import { CraftMealCard } from '../molecules/stores/CraftMealCard'
import { ResponsivePXValue, ResponsiveProperty, LoadEffect } from '../Theme'
import { DeviceContainer } from '../utility'

import { getFrozenNavLinks } from './FrozenAisles'

const seo = {
  name: 'UCOOK Craft Meal',
  title: 'Buy Craft Meal Online in South Africa | UCOOK Craft Meal',
  meta: [{
    name: 'description',
    content: 'Our markets are sourced from SA\'s best farms, and specially paired with each Meal Kit recipe so that you can enjoy a special dining experience at home.',
  }, {
    name: 'keywords',
    content: 'Buy Market Online,Market Online,Order Market Online',
  }, {
    name: 'robots',
    content: 'index,follow',
  }],
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  ${ResponsivePXValue('padding-top', { mobile: '16px', desktop: '32px' })}
  ${ResponsivePXValue('width', { mobile: '100%', desktop: '1136px' }, { desktop: true })}

  .button-container {
    margin: 0;
  }
`
const TopContainer = styled.div`
  display: flex;
  flex-direction: column;

 ${ResponsivePXValue('padding', { mobile: '0 16px', tablet: '0 16px' })}
`

const HeadingContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  ${ResponsiveProperty('justify-content', { mobile: 'space-between', tablet: 'flex-start', desktop: 'flex-start' })}
  ${ResponsivePXValue('height', { mobile: '44px', desktop: '64px' })}
`
const Grid = styled.div <{ $isNativeApplication: boolean, $isLoading: boolean }>`
  display: flex;
  gap: 16px;

  ${(props): CSS => props.$isLoading ? LoadEffect : undefined};

  ${ResponsiveProperty('flex-direction', { mobile: 'column', desktop: 'row' })}

  .craft-meal-filter { 
    ${ResponsivePXValue('padding', { mobile: '8px 0', tablet: '0 16px' })}
    ${ResponsivePXValue('width', { desktop: '272px' })}

    .filter-pill-container {
       ${ResponsivePXValue('left', { mobile: '36px' })}
    }
  }
`

const DishContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 16px;
  height: fit-content;
  ${ResponsivePXValue('justify-content', { mobile: 'center' })}

  .craft-meal-card {
    margin: 0;
  }
`

const IconContainer = styled.div`
  display: block;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
interface CraftMealStoreState {
  filters: FrozenMealDishFilters
  displayEducationalBanner: boolean
  hasLoggedImpression: boolean
  pageLoaded: boolean
  aggregations?: ProductAggregationSectionFragment[]
}

const DEFAULT_STATE: CraftMealStoreState = {
  filters: {},
  displayEducationalBanner: false,
  hasLoggedImpression: false,
  pageLoaded: false,
  aggregations: [],
}

export function CraftMealStore(): JSX.Element {

  const [state, setState] = useState<CraftMealStoreState>({ ...DEFAULT_STATE })
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isNativeApplication = appData.app.isNativeApplication
  const navigate = useNavigate()
  const location = useLocation()

  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE
  const params = qs.parse(location.search.replace('?', ''))
  const filters = params?.filters as { [k: string]: string[] } || {}

  const variables = {
    filters: {
      ...filters,
      excludeCategories: [
        '69e90309-97a2-46b4-8c3e-e447283d9601',
        'cf843967-672c-4bd2-9d46-f593759d5ad3',
        '96ffcec9-e860-4d56-8634-1e70eb9670f3',
      ],
    },
    order: [{
      field: FrozenMealDishOrderEnum.DISPLAY_INDEX,
      direction: OrderDirectionEnum.ASC,
    }],
  }

  const _handleFiltersChange = (selected: SelectedFilters): void => {
    setState((prevState) => update(prevState, {
      filters: { $set: selected },
      skip: { $set: 0 },
      hasLoggedImpressions: { $set: false },

    }))
    const newQueryString = qs.stringify({ filters: selected })
    navigate(`${location.pathname}?${newQueryString}`)
  }

  const _handleFiltersClear = (): void => {
    setState((prevState) => update(prevState, {
      filters: { $set: {} },
      skip: { $set: 0 },
      hasLoggedImpressions: { $set: false },

    }))
    navigate(location.pathname)
  }

  const _handleBannerToggle = (): void => {
    setState((prevState) => update(prevState, {
      displayEducationalBanner: { $set: !prevState.displayEducationalBanner },
    }))
  }

  const _handleOnClose = (): void => {
    setState((prevState) => update(prevState, {
      displayEducationalBanner: { $set: false },
    }))
  }

  useEffect(() => {
    if (!Cookies.get('displayEducationalBanner')) {
      setState((prevState) => update(prevState, {
        displayEducationalBanner: { $set: true },
      }))
      Cookies.set('displayEducationalBanner', '0')
    }
  }, [])

  const sections: FilterSectionProps[] = state.aggregations.filter(aggregation => aggregation.filterKey !== 'tags').map((aggregation) => ({
    id: aggregation.filterKey,
    title: aggregation.title,
    filterKey: aggregation.filterKey,
    items: aggregation.items.map((item) => ({
      id: item.id,
      title: item.name,
      quantity: 0,
    })),
  }))

  let navLinks = getFrozenNavLinks({ craftMealActive: true })
  const hasFrozenBoughtBefore = appData?.app?.globalAppContext?.hasPurchasedFrozenMeals
  if (hasFrozenBoughtBefore) {
    const boughtBeforeLink = [{
      title: 'Bought Before',
      url: '/frozen/bought-before',
      isActive: false,
    }]
    navLinks = getFrozenNavLinks({
      craftMealActive: true,
      additionalLinks: boughtBeforeLink,
    })
  }

  const _handlePageLoaded = (aggregations: ProductAggregationSectionFragment[]): void => {
    setState((prevState) => update(prevState, {
      pageLoaded: { $set: true },
      aggregations: { $set: aggregations },
    }))
  }

  const renderGridContent = () => {

    const productGridQuery = useGetAllFrozenMealDishesQuery
    const productsPerPage = isMobile ? 8 : 18
    const productGridVariables = {
      ...variables,
    }

    return (
      <ProductGrid
        productsPerPage={productsPerPage}
        queryVariables={productGridVariables}
        useProductQuery={productGridQuery}
        productType={ProductRangeEnum.FROZEN_MEAL}
        productCard={CraftMealCard as unknown as React.ReactElement}
        onLoaded={_handlePageLoaded}
      />
    )
  }

  return (
    <Container>
      <SearchEngineOptimization seo={seo} />
      <StoreStickyButton className='button-container' isMobile={isMobile} />
      <TopContainer>
        <HeadingContainer>
          <Heading variant='h1'>Craft Meal</Heading>
          <Spacer desktop='8px' variant='horizontal' />
          <IconContainer onClick={_handleBannerToggle}>
            <Icon icon={IconEnum.INFORMATION_CIRCLE} size={IconSizeEnum.SMALL} />
          </IconContainer>
        </HeadingContainer>
        <DeviceContainer $desktop>
          <NavLinks navLinks={navLinks} outline />
        </DeviceContainer>
        <If condition={state.displayEducationalBanner}>
          <Spacer universal='16px' />
          <EducationalBanner
            title='Welcome to Craft Meal!'
            description={'UCOOK’s chef-created Frozen Craft Meals are made from fresh, all-natural ingredients and flash frozen to seal in nutrients and flavour. The pros have cooked so you don’t have to. Simply heat and eat for a delicious, hassle-free, home-cooked meal.'}
            onClose={_handleOnClose} />
          <Spacer mobile='16px' />
        </If>
        <Spacer desktop='24px' />
      </TopContainer>
      <Grid $isNativeApplication={isNativeApplication} $isLoading={false}>
        <Filter
          className='craft-meal-filter'
          filters={filters as unknown as { [k: string]: string[] }}
          sections={sections}
          onChange={_handleFiltersChange}
          onClear={_handleFiltersClear}
          slides={<NavLinks navLinks={navLinks} outline />} />
        <Spacer mobile='4px' />
        <DishContainer id="productGrid">
          {renderGridContent()}
        </DishContainer>
      </Grid>
      <Spacer universal='24px' />
    </Container>
  )
}
