import React from 'react'

import { Field, FormInstance } from 'rc-field-form'
import { Meta, Rule } from 'rc-field-form/es/interface.d'
import styled, { CSS, useTheme } from 'styled-components'

import { SmallLoader } from '@atoms/index'
import { DisabledEffect, ResponsivePXValue } from '@components/Theme'
import { useGetApiEnumQuery } from '@hooks/api'

import { CheckBoxControl } from '../controls'

import { FormContext, FormContextProperties, FormValue, InputProps } from './Form'
import { InputWrapper } from './InputWrapper'

const Container = styled.div<{ $direction: 'vertical' | 'horizontal' }>`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(props): string => props.$direction === 'vertical' ? 'column' : 'row'};
`

const LoadingContainer = styled.div`
  position: absolute;
  ${ResponsivePXValue('right', '8px')}
  ${ResponsivePXValue('top', '8px')}
  ${ResponsivePXValue('width', '25px')}
  ${ResponsivePXValue('height', '25px')}
`

const ItemContainer = styled.div<{ $loading: boolean, $disabled: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${(props): string => props.$loading ? 'inherit' : 'pointer'};

  ${(props): CSS => props.$disabled ? DisabledEffect : undefined};

  .check-box-control {
     ${(props): CSS => props.$disabled ? DisabledEffect : undefined};
  }
`

const ItemLabel = styled.div<{ $color: string }>`
  position: relative;
  font-family: gordita;
  font-weight: 500;
  line-height: 1;
  color: ${(props): string => props.$color};
  ${ResponsivePXValue('margin', '0 2px')}
  ${ResponsivePXValue('font-size', '10px')}
`

interface Values {
  value?: FormValue[]
  onChange?: (value: FormValue[]) => void
}

interface CheckBoxInnerProps extends CheckBoxProps {
  control: Values
  meta: Meta
  form: FormInstance
}

function CheckBoxInner(props: CheckBoxInnerProps): JSX.Element {

  const {
    label, showLabel = true, showOptional = true, variant = 'vertical', color = 'black', options,
    loading, apiEnum, disabled, rules, meta, control, form, className,
  } = props
  const { data, loading: apiLoading } = useGetApiEnumQuery({ variables: { enum: apiEnum }, skip: !apiEnum })

  const theme = useTheme()

  let availableOptions: CheckBoxOption[] = options ?? []
  if (data?.enum?.values) {
    availableOptions = data.enum.values.map((enumVal) => ({ title: enumVal.title, value: enumVal.value }))
  }

  const required = !!(
    rules &&
    rules.some(rule => {
      if (rule && typeof rule === 'object' && rule.required) {
        return true
      }
      if (typeof rule === 'function') {
        const ruleEntity = rule(form)
        return ruleEntity && ruleEntity.required
      }
      return false
    })
  )

  const error = meta.errors?.[0]
  const { value, onChange } = control

  const _handleChange = (option: CheckBoxOption) => {
    const checkValue = option.value

    if (option.disabled || disabled || loading || apiLoading || checkValue === 0) {
      return
    }
    const newValue: FormValue[] = value as FormValue[] || []
    if (newValue.includes(checkValue)) {
      newValue.splice(newValue.indexOf(checkValue), 1)
    } else {
      newValue.push(checkValue)
    }
    onChange(newValue)
  }

  const labelColor = disabled || loading || apiLoading ? theme.colors.whites.silver : theme.colors.greys.tundora

  let option: CheckBoxOption

  return (
    <InputWrapper
      required={required}
      label={label}
      showLabel={showLabel}
      showOptional={showOptional}
      error={error}
      className={className}>
      <FormContext.Consumer>
        {({ loading: formLoading, disabled: formDisabled }: FormContextProperties) => (
          <Container $direction={variant}>
            <For each='option' of={availableOptions}>
              <ItemContainer
                key={option.value.toString()}
                $loading={loading}
                $disabled={option.disabled || disabled || formDisabled}
                onClick={() => _handleChange(option)}
                className={`item-container ${option?.className}`}>
                <CheckBoxControl
                  color={color}
                  showItemLabel={false}
                  selected={(value as FormValue[] || []).includes(option.value) || option.selected}
                  disabled={option.disabled || disabled || formDisabled}
                  className='check-box-control' />
                <ItemLabel $color={labelColor}>{option.title}</ItemLabel>
              </ItemContainer>
            </For>
            <If condition={loading || apiLoading || formLoading}>
              <LoadingContainer>
                <SmallLoader
                  color={theme.colors.whites.silver} />
              </LoadingContainer>
            </If>
          </Container>
        )}
      </FormContext.Consumer>

    </InputWrapper>
  )

}

export interface CheckBoxOption {
  title: string | JSX.Element
  value: FormValue
  selected?: boolean
  disabled?: boolean
  className?: string
}

export interface CheckBoxProps extends InputProps {
  rules?: Rule[]
  color?: 'orange' | 'black'
  variant?: 'vertical' | 'horizontal'
  flexDirection?: string
  apiEnum?: string
  options?: CheckBoxOption[]
  className?: string
}

export function CheckBox(props: CheckBoxProps): JSX.Element {

  const { name, rules } = props

  return (
    <Field name={name} rules={rules}>
      {(control: Values, meta: Meta, form: FormInstance) => <CheckBoxInner {...props} control={control} meta={meta} form={form} />}
    </Field>
  )

}
